import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { basemapLayersConfig } from "../../../../map/config/layerConfig";
import { fromLonLat } from "ol/proj";
import OlMap from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import { Cluster, Vector as VectorSource, XYZ } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import Draw, { createBox, DrawEvent, Options, SketchCoordType } from "ol/interaction/Draw";
import Snap from "ol/interaction/Snap";
import GeoJSON from "ol/format/GeoJSON";
import { FeatureGeometry } from "../../../../models/area-item.model";
import Style from "ol/style/Style";
import { Fill, Stroke, Text } from "ol/style";
import Feature from "ol/Feature";
import { ImageTile, MapBrowserEvent, Overlay, Tile } from "ol";
import { LineString, Polygon } from "ol/geom";
import SimpleGeometry from "ol/geom/SimpleGeometry";
import { AreaService } from "../../../../services/area.service";
import Icon from "ol/style/Icon";
import { Layer } from "../../../../models/layer.models";
import { environment } from "../../../../../environments/environment";
import TileSource from "ol/source/Tile";
import { HttpClient, HttpHeaders, HttpUrlEncodingCodec } from "@angular/common/http";
import { MapCommunicatorService } from "../../map-communicator.service";
import { defaults, ScaleLine } from "ol/control";
import { LayerService } from "../../../../services/layer.service";
import { AreaCollections } from "../../../../models/report.model";
import { debounceTime } from "rxjs";
import Point from "ol/geom/Point";
import CircleStyle from "ol/style/Circle";
import { boundingExtent, createEmpty, extend } from "ol/extent";
import { getArea, getLength } from "ol/sphere";
import { getRiskColors } from "../../../../utils";


@Component({
  selector: 'frmg-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  @Input() reportData: Array<AreaCollections> = [];
  @Input() skippClickOnNotPoints: boolean;

  @Output() mapDrawEvent = new EventEmitter<FeatureGeometry | null>();
  @Output() elementClick = new EventEmitter<string | null>();

  private mapLayerUrl = environment.mapServerBaseUrl;
  private basemapLayers = basemapLayersConfig;

  readonly defaultMapCenter = [-84.09579004434322, 48.13439611492869];
  readonly MAX_ZOOM = 17;

  public colors: string[] = getRiskColors();
  public colorsHovered: string[] = ['#72706C', '#9D8429', '#934C18', '#802225', '#2E0704'];
  public colorsBorder: string[] = ['#93918D', '#CFB146', '#C57336', '#9F3338', '#430A06'];
  public focused_id: string | null;
  public focusedTimeout;

  private drawSource: VectorSource = new VectorSource({
    wrapX: false
  });
  private drawLayer: VectorLayer<VectorSource> = new VectorLayer({
    source: this.drawSource,
    zIndex: 100,
    style: this.drawStyleFunction.bind(this)
  });

  private drawStyle = new Style({
    image: new Icon({
      src: '../../assets/icons/map-point-active.svg'
    }),
    stroke: new Stroke({
      width: 2,
      color: '#5E84F7',
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.4)'
    })
  });
  private clusterStyle(size: number): Style {
    return new Style({
      image: new CircleStyle({
        radius: 16,
        stroke: new Stroke({
          color: 'rgba(94,132,247,0.3)',
          width: 4

        }),
        fill: new Fill({
          color: '#5E84F7'
        }),
      }),
      // @ts-ignore
      text: new Text({
        text: size.toString(),
        fill: new Fill({
          color: '#fff'
        }),
        textAlign: 'center'
      })
    })
  }
  private selectedStyle = new Style({
    stroke: new Stroke({
      width: 3,
      color: 'rgba(0, 164, 216, 1)'
    }),
    fill: new Fill({
      color: 'rgba(0, 164, 216, 0.2)'
    })
  })
  private selectedPoint = new Style({
    image: new Icon({
      src: '../../assets/icons/map-point-active.svg'
    })
  });
  private iconUrl = '../../assets/icons/map-point-inactive.svg';
  private iconStyle = new Style({
    image: new Icon({
      src: this.iconUrl
    })
  });
  private defaultStyle = new Style({
    stroke: new Stroke({
      width: 2,
      color: '#5E84F7',
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.4)'
    }),
    image: new Icon({
      src: '../../assets/icons/map-point-active.svg'
    })
  })
  private errorStyle = new Style({
    stroke: new Stroke({
      width: 2,
      color: '#EB5752',
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.4)'
    })
  })
  private defaultLineStyle = new Style({
    stroke: new Stroke({
      width: 4,
      color: '#5E84F7',
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.4)'
    })
  })
  // @ts-ignore
  private styleFunction(feature) {
    const isSelected = this.selectedFeatures.indexOf(feature.values_.id || feature.values_.features[0].values_.id) > -1;
    const hovered = (feature.values_.id || feature.values_.features[0].values_.id) === this.focused_id;
    switch (feature.getGeometry()!.getType()) {
      case 'Point':
        return !isSelected ? this.iconStyle : this.selectedPoint;
      case 'MultiLineString':
      case 'LineString':
      case 'Polygon':
      case 'MultiPolygon':
        return (isSelected || hovered) ? this.selectedStyle : this.defaultLineStyle;
      default:
        return this.defaultStyle;
    }
  }
  // @ts-ignore
  private drawStyleFunction() {
    return this.thresholdError ? this.errorStyle : this.defaultStyle;
  }
  public currentFeature;

  private map: OlMap;

  private snap: Snap;
  private selectedFeatures: string[] = [];
  private drawAction: Draw;
  private areaVectorLayer: VectorLayer<VectorSource>;
  private vectorSource: VectorSource;
  private vectorSourcePoints: VectorSource;
  private clusterSource: Cluster;
  private clusters: VectorLayer<VectorSource>
  private initBasemapLayer: TileLayer<XYZ>;
  private activeLayers: { [key: string]: any } = {};
  private drawMode: boolean = false;
  private sketch: Feature;
  private info: HTMLElement;
  private measureTooltipElement: HTMLElement;
  private measureTooltip: Overlay;
  private pointerPosition: any;
  private helpMsg: string;
  private outputMsg: string;
  private lineThreshold = 100 * Math.pow(10,3);
  private polygonThreshold = 60 * Math.pow(10,6);
  private thresholdError = false;
  private continuePolygonMsg = 'Click to continue drawing the polygon';
  private continueLineMsg = 'Click to continue drawing the line';

  constructor(private areaService: AreaService,
              private http: HttpClient,
              private mapCommunicatorService: MapCommunicatorService,
              private layersService: LayerService,
              private cd: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    delete this.reportData;
    this.areaService.clearAreaList();
  }

  ngOnInit(): void {
    this.initMap();
    this.mapCommunicatorService.mapCommunicator.subscribe(action => {
      if (action.includes('draw_')) {
        this.startDraw(action.split('_')[1]);
      } else if (action === 'selection') {
        this.drawMode = false;
        this.removeInteractions();
      } else if (action === 'clear') {
        this.drawSource.clear();
        this.measureTooltipElement.remove();
        // this.removeInteractions();
      } else if (action.includes('focus_')) {
        this.focusOnArea(action.split('_')[1], action.split('_')[2] === 'true')
      } else if (action.includes('hover_')) {
        this.hoverArea(action.split('_')[1])
      } else if (action.includes('basemap_')) {
        this.changeBaseLayer(action.split('basemap_')[1])
      } else if (action.startsWith("goToLocation_")) {
        const split = action.split('_')
        const coordinates = [split[1], split[2]].map(set => set.split(':').map(coord => +coord));
        const addMarker = split[3] === 'true';
        this.goToLocation(coordinates, addMarker);
      } else if (action.startsWith("goToLocationLL_")) {
        const split = action.split('_')
        const coordinates = [split[2], split[1]].map(coord => +coord);
        const addMarker = split[3] === 'true';
        this.goToLocationLL(coordinates, addMarker);
      } else if (action === 'zoom_in') {
        const currentView = this.map.getView();
        currentView.getZoom()! + 1 < currentView.getMaxZoom() && currentView.setZoom(currentView.getZoom()! + 1);
      } else if (action === 'zoom_out') {
        const currentView = this.map.getView();
        currentView.getZoom()! - 1 > currentView.getMinZoom() && currentView.setZoom(currentView.getZoom()! - 1);
      }
    })
    this.mapCommunicatorService.selectedAreas.pipe(debounceTime(0)).subscribe(newSelection => {
      this.selectedFeatures = newSelection;
      this.areaVectorLayer?.getSource().changed();
      this.clusters?.getSource().changed();
    })
    this.mapCommunicatorService.layers.subscribe(layers => {
      this.addFRMGLayer(layers)
    });
    const savedLayers = Object.values(this.layersService.getSavedLayers());
    if (savedLayers.length) {
      this.addFRMGLayer(savedLayers)
    }
    this.loadAreas();

  }


  private initMap(): void {
    const mapCentre: number[] = fromLonLat(this.defaultMapCenter);
    const layer = this.basemapLayers[0];
    this.initBasemapLayer = new TileLayer({
      source: new XYZ({
        attributions: layer.attribution ? layer.attribution : '',
        url: layer.endpointUrl,
      }),
      opacity: layer.opacity,
      visible: layer.visibleState,
      zIndex: 0
    })
    const scaleControl = new ScaleLine({
      units: 'metric',
      className: 'scale-map ol-scale-line'
    });

    this.map = new OlMap({
      layers: [
        this.initBasemapLayer,
        this.drawLayer
      ],
      target: 'map',
      view: new View({
        center: mapCentre,
        zoom: 8, maxZoom: this.MAX_ZOOM,
      }),
      controls: defaults({zoom: false}).extend([scaleControl]),
    });
    this.info = document.getElementById('info');
    this.createMeasureTooltip();
    this.map.on('pointermove', (e) => {
      if (e.dragging) {
        this.info.style.visibility = 'hidden';
        this.currentFeature = undefined;
        return;
      } else if (this.drawMode) {
        this.helpMsg = 'Click to start drawing';
        if (this.sketch) {
          this.pointerPosition = e.coordinate;
          const geom = this.sketch.getGeometry();
          if (geom instanceof Polygon) {
            this.helpMsg = this.continuePolygonMsg;
          } else if (geom instanceof LineString) {
            this.helpMsg = this.continueLineMsg;
          }
        }
      } else this.displayFeatureInfo(e.pixel, e.originalEvent.target);
    })

    //workaround for wrong map target
    setTimeout(() => {
      if (this.map) {
        this.map.setTarget(null);
        this.map.setTarget('map');
        this.map.on('loadstart', () => {
          this.map.getTargetElement().classList.add('spinner');
        });
        this.map.on('loadend', () => {
          this.map.getTargetElement().classList.remove('spinner');
        });
      }
    }, 500)
  }

  public formatLength  (length) {
    let output;
    if (length > 100) {
      output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
      output = Math.round(length * 100) / 100 + ' ' + 'm';
    }
    return output;
  };

  public formatArea (area) {
    let output;
    if (area > 10000) {
      output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
    } else {
      output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
    }

    return output;
  };

  private createMeasureTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.remove();
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure frmg-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
      stopEvent: false,
      insertFirst: false,
    });
    this.map.addOverlay(this.measureTooltip);
  }

  private displayFeatureInfo(pixel, target) {
    const feature = target.closest('.ol-control', 1)
      ? undefined
      : this.map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
      }, {hitTolerance: 0});
    // @ts-ignore
    if (feature && !this.drawMode && feature.get('features')) {
      const size = feature.get('features').length;
      this.info.style.left = pixel[0] + 'px';
      this.info.style.top = pixel[1] + 'px';
      if (feature !== this.currentFeature) {
        this.info.style.visibility = 'visible';
        // @ts-ignore
        this.info.innerText = size === 1 ? (feature.get('features')[0].values_.name || feature.get('features')[0].values_.address.name) : feature.get('features').map(f => f.values_.name).join('\n');
      }
    } else if (feature && !this.drawMode) {
      this.info.style.left = pixel[0] + 'px';
      this.info.style.top = pixel[1] + 'px';
      if (feature !== this.currentFeature) {
        this.info.style.visibility = 'visible';
        // @ts-ignore
        this.info.innerText = feature.values_.name || feature.values_.address.name;
      }
    } else {
      this.info.style.visibility = 'hidden';
    }
    this.cd.detectChanges();
    this.currentFeature = feature;
  };

  private changeBaseLayer = (type: string) => {
    this.map.removeLayer(this.initBasemapLayer);
    const layer = this.basemapLayers.find(baseMap => baseMap.layerName.toLowerCase() === type.toLowerCase())!;
    this.initBasemapLayer = new TileLayer({
      source: new XYZ({
        attributions: layer.attribution ? layer.attribution : '',
        url: layer.endpointUrl,
      }),
      opacity: layer.opacity,
      visible: layer.visibleState,
      zIndex: 0
    })
    this.map.addLayer(this.initBasemapLayer);
  }

  private startDraw(type: string) {
    this.removeInteractions();
    this.createMeasureTooltip();
    this.drawMode = true;
    let drawType;
    let geometryFunction;
    switch (type) {
      case 'circle':
        drawType = 'Circle';
        geometryFunction = this.drawCircle;
        break;
      case 'polygon':
        drawType = 'Polygon'
        break;
      case 'rectangle':
        drawType = 'Circle'
        geometryFunction = createBox()
        break;
      case 'line':
        drawType = 'LineString'
        break;
      case 'point':
      default:
        drawType = 'Point'
        break;
    }
    const options: Options = {
      source: this.drawSource,
      // @ts-ignore
      type: drawType,
    };
    if (geometryFunction) {
      options.geometryFunction = geometryFunction;
    }
    if (drawType === 'Point') {
      options.style = this.selectedPoint;
    }
    this.drawAction = new Draw(options);
    this.map.addInteraction(this.drawAction);
    this.createMeasureTooltip();
    let listener;
    this.drawAction.on('drawstart', (e: DrawEvent) => {
      this.drawSource.clear();
      this.mapDrawEvent.emit(null)
      this.sketch = e.feature;
      // @ts-ignore
      let tooltipCoord = e.coordinate;
      listener = this.sketch.getGeometry().on('change', evt=> {
        const geom = evt.target;

        if (geom instanceof Polygon) {
          const area = getArea(geom);
          const length  = getLength(geom);
          this.outputMsg = this.formatArea(area) + " ("+ this.formatLength(length)+")";
          tooltipCoord = this.pointerPosition;
          if (area > this.polygonThreshold) {
            this.thresholdError = true;
            e.feature.setStyle(this.errorStyle);
            this.outputMsg += "<br> You have exceeded the 60 SQ/KM limit"
          } else {
            this.thresholdError = false
            e.feature.setStyle(undefined);

          }
        } else if (geom instanceof LineString) {
          const area  = getLength(geom);
          this.outputMsg = this.formatLength(area);
          tooltipCoord = geom.getLastCoordinate();
          if (area > this.lineThreshold) {
            this.thresholdError = true;
            this.sketch.setStyle(this.errorStyle);
            this.outputMsg += "<br >You have exceeded the 100 KM limit"
          } else {
            this.thresholdError = false
            this.sketch.setStyle(undefined);
          }
        }
        this.measureTooltipElement.innerHTML = this.helpMsg + "<br>" + this.outputMsg;
        this.measureTooltip.setPosition(tooltipCoord);
      })
    })
    this.drawAction.on('drawend', (e: DrawEvent) => {
      let parser = new GeoJSON();
      // if (e.feature.getGeometry().getType() === "Circle") {

      // }
      let area = parser.writeFeaturesObject([e.feature], {featureProjection: 'EPSG:3857'})
      if (!this.thresholdError) {
        // @ts-ignore
        this.mapDrawEvent.emit(area.features[0].geometry);
      }
      // this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      this.measureTooltipElement.innerHTML = this.outputMsg;
      this.measureTooltip.setOffset([0, -7]);
      // unset sketch
      this.sketch = null;
      // this.createMeasureTooltip();
    })
  }

  private drawCircle(coordinates: SketchCoordType, geometry: SimpleGeometry) {
    const center = coordinates[0];
    const last = coordinates[coordinates.length - 1];
    // @ts-ignore
    const dx = center[0] - last[0];
    // @ts-ignore
    const dy = center[1] - last[1];
    const radius = Math.sqrt(dx * dx + dy * dy);
    const rotation = Math.atan2(dy, dx);
    const newCoordinates = [];
    const numPoints = 12;
    for (let i = 0; i < numPoints; ++i) {
      const angle = rotation + (i * 2 * Math.PI) / numPoints;
      const fraction = i % 2 === 0 ? 1 : 0.5;
      const offsetX = radius * fraction * Math.cos(angle);
      const offsetY = radius * fraction * Math.sin(angle);
      // @ts-ignore
      newCoordinates.push([center[0] + offsetX, center[1] + offsetY]);
    }
    newCoordinates.push(newCoordinates[0].slice());
    if (!geometry) {
      geometry = new Polygon([newCoordinates]);
    } else {
      geometry.setCoordinates([newCoordinates]);
    }
    return geometry;
  };

  private selectAreaFunctionality(): void {
    this.removeInteractions();
    let counter = 0;
    this.map.removeEventListener('singleclick', this.clickListener);
    this.map.on('singleclick', this.clickListener)
  }

  private clickListener = (e: MapBrowserEvent<UIEvent>) => {
    if (this.drawMode) return;
    if (!this.reportData.length) {
      this.areaVectorLayer.getFeatures(e.pixel).then((features: Feature[]) => {
      const f = features[0];
      if (!f) return;
      // @ts-ignore
      const value = f.values_;
      const selectedIndex = this.selectedFeatures.indexOf(value.id);
      if (selectedIndex < 0 && value.id) {
        this.selectedFeatures.push(value.id);
        f.setStyle(this.selectedStyle);
      } else {
        this.selectedFeatures.splice(selectedIndex, 1);
        f.setStyle(undefined);
      }
      f.changed();
      this.mapCommunicatorService.mapCommunicator.next('map_select_' + value.id);
    })
    } else {
      this.areaVectorLayer.getFeatures(e.pixel).then((features: Feature[]) => {
        const f = features[0];
        if (!f || this.skippClickOnNotPoints) {
          this.clusters.getFeatures(e.pixel).then((features: Feature[]) => {
            if (features.length == 0) return;
            try {
              const f = features[0].get('features')[0].values_.id;
              if (!f) return;
              this.elementClick.emit(f)
            }
            catch (e) {
              return;
            }

          })
        } else {
          // @ts-ignore
          this.elementClick.emit(f.values_.id)
        }
      })
    }
    this.clusters.getFeatures(e.pixel).then((features: Feature[]) => {
      if (features.length == 0) return;
      const f = features[0].get('features');
      if (!f) return;
      if (f.length !== 1) {
        const padding = this.reportData.length ? [100, 100, 100, 100] : [100, 550, 100, 150];
        const extend = boundingExtent(f.map(r => r.getGeometry().flatCoordinates))
        this.map.getView().fit(extend, {minResolution: 5, duration: 300, padding: padding});
      } else {
        if (this.reportData && this.reportData.length) return;
        // @ts-ignore
        const value = f[0].values_;
        const selectedIndex = this.selectedFeatures.indexOf(value.id);

        if (selectedIndex < 0 && value.id) {
          this.selectedFeatures.push(value.id);
          f[0].values_.selected = true;
        } else {
          this.selectedFeatures.splice(selectedIndex, 1);
          f[0].values_.selected = false;
        }
        features[0].changed()
        this.mapCommunicatorService.mapCommunicator.next('map_select_' + value.id);
      }

    })
  }

  private removeInteractions = () => {
    this.map.removeInteraction(this.drawAction);
    this.map.removeInteraction(this.snap);
  }

  private loadAreas = () => {
    if (!this.reportData.length) {
      this.areaService.areasList.subscribe(features => {
        const collection = {
          type: "FeatureCollection",
          features
        }
        this.addCollectionToMap(collection, true, true)
      })
    } else {
      const arrayPoints = [];
      this.reportData.forEach(report => {
        // @ts-ignore
        report.area.properties.risk_score = report.area.properties.risk_score || report.area_details[0].risk_score;

        // @ts-ignore
        arrayPoints.push(report.area)

      })
      const collection = {
        type: "FeatureCollection",
        features: arrayPoints
      }
      this.addCollectionToMap(collection, true, true)
    }
  }

  private addCollectionToMap(collection: any, fit: boolean = false, cluster: boolean = false) {
    const collectionPoints = {
      features: collection.features.filter(f => f.geometry.type === 'Point'),
      type: "FeatureCollection"
    }
    const nonPointCollection = {
      features: collection.features.filter(f => f.geometry.type !== 'Point'),
      type: "FeatureCollection"
    }
    const proj: string = 'EPSG:4326';
    this.vectorSourcePoints = new VectorSource({
      format: new GeoJSON(),
      features: new GeoJSON().readFeatures(collectionPoints, {
        featureProjection: this.map.getView().getProjection(),
        dataProjection: proj,
      })
    })
    this.vectorSource = new VectorSource({
      format: new GeoJSON(),
      features: new GeoJSON().readFeatures(nonPointCollection, {
        featureProjection: this.map.getView().getProjection(),
        dataProjection: proj,
      })
    })
    if (this.areaVectorLayer) {
      this.map.removeLayer(this.areaVectorLayer);
    }
    this.areaVectorLayer = new VectorLayer({
      source: this.vectorSource,
      style: this.styleFunction.bind(this),
      zIndex: 100,
    });

    if (this.clusters) {
      this.map.removeLayer(this.clusters);
    }
    if (cluster) {
      this.clusterSource = new Cluster({
        distance: 32,
        minDistance: 32,
        source: this.vectorSourcePoints
      })
      this.clusters = new VectorLayer<VectorSource>({
        source: this.clusterSource,
        style: (feature) => {
          // @ts-ignore
          const hovered = feature?.values_.features[0]?.values_.id === this.focused_id;
          const size = feature.get('features').length;
          if (size > 1) {
            return this.clusterStyle(size);
          } else {
            // @ts-ignore
            const isSelected = this.selectedFeatures.indexOf(feature?.values_.features[0]?.values_.id) > -1;
            switch (feature.getGeometry()!.getType()) {
              case 'Point':
                // @ts-ignore
                const riskScore = feature.values_.features[0].values_.risk_score || 0;

                if (!this.reportData?.length) {
                  return !isSelected ? this.iconStyle : this.selectedPoint;
                } else {}
                // @ts-ignore
                return new Style({
                  image: new CircleStyle({
                    radius: 7,
                    stroke: new Stroke({
                      color: (hovered ? this.colorsHovered : this.colorsBorder)[riskScore]
                    }),
                    fill: new Fill({
                      color: (hovered ? this.colorsBorder : this.colors)[riskScore]
                    }),
                  })
                })
              case 'MultiLineString':
                return (isSelected || hovered) ? this.selectedStyle : this.defaultLineStyle;
              default:
                return this.defaultStyle;
            }
          }
        }
      })
    }

    this.map.addLayer(this.areaVectorLayer);
    this.map.addLayer(this.clusters);
    this.areaVectorLayer.setZIndex(999);
    this.clusters.setZIndex(999);
    this.selectAreaFunctionality();
    if (fit) {
      const extent = createEmpty();
      extend(extent, this.vectorSourcePoints.getExtent())
      extend(extent, this.vectorSource.getExtent())
      if (cluster) extend(extent, this.clusterSource.getExtent())
      if (extent.some(value => value == Infinity || value == -Infinity )) return;
      this.map.getView().fit(extent, {
        padding: [50, 50, 50, 50],
        maxZoom: this.MAX_ZOOM,
        duration: 300
      });
    }
  }

  private focusOnArea(id: string, center?: boolean) {
    const padding = center ? [100, 100, 100, 100] : [100, 550, 100, 150]
    // @ts-ignore
    const targetFeature = this.vectorSourcePoints?.getFeatures().find(f => f.values_.id === id)?.getGeometry() || this.vectorSource?.getFeatures().find(f => f.values_.id === id)?.getGeometry();
    if (!targetFeature) return;
    this.focused_id = id;
    targetFeature.changed();
    if (this.focusedTimeout) {
      clearTimeout(this.focusedTimeout);
    }
    this.focusedTimeout = setTimeout(()=>{
      this.focused_id = null;
      targetFeature.changed();
    }, 2000)
    // @ts-ignore
    if (targetFeature) this.map.getView().fit(targetFeature, {minResolution: 5, padding: padding, duration: 300});
  }
  private hoverArea(id: string) {
    // @ts-ignore
    const targetFeature = this.vectorSourcePoints?.getFeatures().find(f => f.values_.id === id)?.getGeometry() || this.vectorSource?.getFeatures().find(f => f.values_.id === id)?.getGeometry();
    if (!targetFeature) return;
    this.focused_id = id;
    targetFeature.changed();
    if (this.focusedTimeout) {
      clearTimeout(this.focusedTimeout);
    }
    this.focusedTimeout = setTimeout(()=>{
      this.focused_id = null;
      targetFeature.changed();
    }, 2000)
  }

  private goToLocation(coordinates: number[][], addMarker?: boolean) {
    const line = new LineString(coordinates).transform('EPSG:4326', 'EPSG:3857') as SimpleGeometry;
    const pCoordinates = [(coordinates[0][0] + coordinates[1][0]) / 2, (coordinates[0][1] + coordinates[1][1]) / 2]
    const point = new Point(pCoordinates).transform('EPSG:4326', 'EPSG:3857') as SimpleGeometry;
    this.map.getView().fit(line, {minResolution: 5, padding: [100, 100, 100, 150], duration: 300});
    if (addMarker) {
      this.drawSource.addFeature(new Feature({
        geometry: point
      }))
      this.mapDrawEvent.emit({type: 'Point', coordinates: pCoordinates})
    }
  }

  private goToLocationLL(coordinates: number[], addMarker?: boolean) {
    const point = new Point(fromLonLat(coordinates)) as SimpleGeometry;
    this.map.getView().fit(point, {minResolution: 5, padding: [100, 100, 100, 150], duration: 300});
    if (addMarker) {
      this.drawSource.addFeature(new Feature({
        geometry: point
      }))
      this.mapDrawEvent.emit({type: 'Point', coordinates: coordinates})
    }
  }

  private addFRMGLayer = (layers: Array<{ active: boolean, opacity: number, layer: Layer }>) => {
    if (this.reportData && this.reportData.length) return;
    const layersToShow = layers.filter(layer => layer.active);
    Object.keys(this.activeLayers).forEach(key => {
      if (!layersToShow.some(layer => layer.layer.layer_name === key)) {
        this.activeLayers[key].setVisible(false);
      }
    })
    layers.forEach(layer => {
      if (!this.activeLayers[layer.layer.layer_name]) {
        const codec = new HttpUrlEncodingCodec();
        const explicit_color_map = codec.encodeValue(JSON.stringify(layer.layer.explicit_color_map).trim()).replace('#', '');
        let colormap = '';
        let explicit = ''
        if (layer.layer.colormap_type === 'ramp') {
          colormap = layer.layer.layer_name
        } else {
          colormap = 'explicit'
          explicit = '&explicit_color_map=' + explicit_color_map
        }
        const url = this.mapLayerUrl + layer.layer.layer_name + '/{z}/{x}/{y}.png?colormap=' + colormap + explicit;
        this.activeLayers[layer.layer.layer_name] = new TileLayer<TileSource>({
          source: new XYZ({
            url: url,
            projection: 'EPSG:3857',
            tileLoadFunction: this.tileLoader
          }),
          opacity: 1,
          visible: false,
          zIndex: 10
        });
        this.map.addLayer(this.activeLayers[layer.layer.layer_name]);
      }
    })
    layersToShow.forEach(layer => {
      const mapLayer = this.activeLayers[layer.layer.layer_name];
      mapLayer.setOpacity(layer.opacity);
      mapLayer.setVisible(true);
    })
  }

  private tileLoader = (tile: Tile, src: string) => {
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http.get(src, {headers, responseType: "arraybuffer"}).subscribe(response => {
      const arrayBufferView = new Uint8Array(response);
      const blob = new Blob([arrayBufferView], {type: 'image/png'});
      const urlCreator = window.URL || (window as any).webkitURL;
      const imageUrl = urlCreator.createObjectURL(blob);
      ((tile as ImageTile).getImage() as HTMLImageElement).src = imageUrl;
    })
  }
}