import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OlMapComponent } from './map/ol-map.component';
import { PortalBaseComponent } from './pages/portal-base/portal-base.component';
import { LoginBasePageComponent } from './components/login/login-base-page/login-base-page.component';
import { LoginDialogComponent } from './components/login/login-dialog/login-dialog.component';
import { DashboardHomeComponent } from './components/dashboard-home/dashboard-home.component';
import { UsersComponent } from './components/users/users.component';
import { ChartsPageComponent } from './components/charts-page/charts-page.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { DownloadStatusTableComponent } from './pages/download-status-table/download-status-table.component';
import { AuthGuard } from './services/guards/auth.guard';
import { InvitePageComponent } from './components/invite-page/invite-page.component';
import { RiskMapComponent } from "./pages/risk-map/risk-map.component";
import { AccountComponent } from "./pages/settings/account/account.component";
import { ChangePasswordComponent } from "./pages/settings/change-password/change-password.component";
import { TeamComponent } from "./pages/settings/team/team.component";
import { ApiKeysComponent } from "./pages/settings/api-keys/api-keys.component";
import { ReportTableComponent } from "./pages/report-table/report-table.component";
import { ReportComponent } from "./pages/report/report.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { PlanUsageComponent } from "./pages/settings/plan_usage/plan_usage.component";
import {
  ReportAreaDetailsComponent
} from "./pages/report/report-area-details/report-area-details-modal.component";
import { AOISReportComponent } from "./pages/report/AOISReport/AOISReport.component";
import { AreaReportComponent } from "./pages/report/areaReport/areaReport.component";

const routes: Routes = [

  {
    path: '', component: PortalBaseComponent,
    children: [
      {
        path: 'risk-map-v2', component: OlMapComponent, pathMatch: 'full'
      },
      {
        path: 'downloads', component: DownloadStatusTableComponent, canActivate: [AuthGuard]
      },
      {
        path: 'report-table', component: ReportTableComponent, canActivate: [AuthGuard]
      },
      {
        path: 'report/:id', component: ReportComponent, canActivate: [AuthGuard],

        children: [
          {
            path: '', component: AOISReportComponent
          },
          {
            path: 'collection/:collectionId', component: AreaReportComponent
          },
          {
            path: 'collection/:collectionId/details/:detailsId', component: ReportAreaDetailsComponent
          }
        ]
      },
      // {
      //   path: 'report/:reportId/collection/:collectionId/details/:id', component: ReportAreaDetailsComponent, canActivate: [AuthGuard]
      // },
      {
        path: 'risk-map', component: RiskMapComponent, canActivate: [AuthGuard]
      },
      {
        path: '', redirectTo: 'risk-map', pathMatch: 'full'
      },
      {
        path: 'dashboard', component: DashboardHomeComponent,
        children: [
          {
            path: '', component: ChartsPageComponent
          },
          {
            path: 'charts', component: ChartsPageComponent
          },
          {
            path: 'users', component: UsersComponent
          },
        ]
      },
      {
        path: 'settings', component: SettingsComponent,
        children: [
          {
            path: '', redirectTo: 'account',
          },
          {
            path: 'account', component: AccountComponent
          },
          {
            path: 'usage', component: PlanUsageComponent
          },
          {
            path: 'changePassword', component: ChangePasswordComponent
          },
          {
            path: 'team', component: TeamComponent
          },
          {
            path: 'apiKeys', component: ApiKeysComponent
          },
        ]
      },
    ]
  },


  {
    path: 'login', component: LoginBasePageComponent,
    children: [
      {
        path: '', component: LoginDialogComponent
      }
    ]
  },

  {
    path: 'invites', component: LoginBasePageComponent,
    children: [
      {
        path: 'accept/:invite-hash', component: InvitePageComponent
      }
    ]
  },

  {
    path: 'password/reset', component: LoginBasePageComponent,
    children: [
      {
        path: 'confirm', component: ResetPasswordComponent
      }
    ]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {bindToComponentInputs: true, paramsInheritanceStrategy: "always"})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
