<div class="account-settings" [formGroup]="accountFg">
    <div class="account-header">
        <h3 class="account-title">Account</h3>
        <p class="account-description">Manage your account settings.</p>
    </div>
    <div class="account-content">
        <div class="form-section">
            <div class="form-group">
                <span class="input-label">Email</span>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="email" placeholder="Email" required>
                </mat-form-field>
            </div>
            <div class="form-row">
                <div class="form-group">
                    <span class="input-label">First Name</span>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="first_name" placeholder="First Name" required>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <span class="input-label">Last Name</span>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="last_name" placeholder="Last Name" required>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="account-actions">
        <button mat-button class="frmg-primary-button"
                (click)="saveChanges()" [disabled]="accountFg.invalid || !accountFg.dirty">Save Changes</button>
    </div>
</div>