<div class="mat-typography" [formGroup]="saveAreaGroup">
    <div class="dialog-header">
        {{ !isEdit ? "Save new area" : "Edit area" }}
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        <ng-container>
            <p class="input-label">
                Area Name
            </p>
            <mat-form-field appearance="outline">
                <input matInput formControlName="name" placeholder="Name" required>
                <mat-error *ngIf="saveAreaGroup.get('name')?.hasError('required')">
                    Name is required
                </mat-error>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="!isEdit">
            <p class="input-label">
                Select Folder
            </p>
            <mat-form-field appearance="outline">
                <mat-select formControlName="folder" style="text-transform: capitalize" [required]="true"
                            placeholder="Select Folder"
                            panelClass="folder-tree" #selectPanel>
                    <mat-select-trigger>
                        {{saveAreaGroup.get('folder').value?.name || ""}}
                    </mat-select-trigger>
                    <mat-option >---</mat-option>
                    <mat-option *ngIf="selectedFolder || dataSource.data[0]" [value]="selectedFolder || dataSource.data[0]" #folderOption
                                style="text-transform: capitalize">
                        {{ selectedFolder?.name || dataSource.data[0].name}}
                    </mat-option>
                    <mat-progress-bar *ngIf="!dataSource.data || this.dataSource.data.length == 0"
                            mode="indeterminate"></mat-progress-bar>
                    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl"
                              *ngIf="dataSource.data && this.dataSource.data.length !== 0">
                        <!-- This is the tree node template for leaf nodes -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="10"
                                       (click)="onTreeNodeSelect(node)"
                                       [ngClass]="{'selected' : selectedFolder && node.id === selectedFolder.id}">
                            <!-- use a disabled button to provide padding for tree leaf -->
                            <button mat-icon-button [disabled]="true" [style.visibility]="'hidden'"></button>
                            <span class="icon-project" *ngIf="treeControl.getLevel(node) === 0"></span>
                           {{ node.name }}
                        </mat-tree-node>
                        <!-- This is the tree node template for expandable nodes -->
                        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
                                       matTreeNodePaddingIndent="10" (click)="onTreeNodeSelect(node)"
                                       [ngClass]="{'selected' : selectedFolder && node.id === selectedFolder.id}">
                            <button mat-icon-button matTreeNodeToggle
                                    [attr.aria-label]="'Toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                                </mat-icon>
                            </button>
                            <span class="icon-project" *ngIf="treeControl.getLevel(node) === 0"></span>
                            {{ node.name }}
                        </mat-tree-node>
                    </mat-tree>
                </mat-select>
            </mat-form-field>
        </ng-container>
        <p class="input-label">
            Add Tags (up to 10)
        </p>
        <mat-form-field appearance="outline" class="tags-field">
            <mat-chip-grid #tagsChipList>
                <mat-chip-row
                        *ngFor="let tag of saveAreaGroup.get('tags')?.value"
                        (removed)="removeTag(tag)"
                >
                    #{{ tag.name }}
                    <mat-icon matChipRemove>close</mat-icon>
                </mat-chip-row>
                <input matInput placeholder="Add tags"
                       formControlName="tagInput"
                       #tagInput
                       matAutocompleteOrigin
                       #origin="matAutocompleteOrigin"
                       (keyup.enter)="addTag({name: saveAreaGroup.get('tagInput').value})"
                       (keyup.,)="addTag({name: saveAreaGroup.get('tagInput').value})"
                       [matAutocompleteConnectedTo]="origin"
                       [matAutocomplete]="tagAutocomplete"
                       [matChipInputFor]="tagsChipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
            </mat-chip-grid>
            <mat-autocomplete #tagAutocomplete='matAutocomplete' (optionSelected)="addTag($event.option.value)">
                <mat-option *ngFor="let tag of tagsList | async" [value]="tag">
                    #{{ tag.name }}
                </mat-option>
                <mat-option *ngIf="saveAreaGroup.get('tagInput')?.value && !duplicated"
                            [value]="{name:saveAreaGroup.get('tagInput').value}">
                    #{{ saveAreaGroup.get('tagInput')?.value }}
                </mat-option>
            </mat-autocomplete>
            <mat-hint>Separate your tags with commas</mat-hint>
        </mat-form-field>
    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button cdkFocusInitial class="frmg-primary-button" [disabled]="saveAreaGroup.invalid || isLoading"
            (click)="save()">Save
    </button>
</div>
