import {
    Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';
  
  @Directive({
    selector: '[appDragDrop]'
  })
  export class DragDropDirective {
    @HostBinding('class.fileover') fileOver!: boolean;
    @Output() fileDropped = new EventEmitter<any>();
  
    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = true;
    }
  
    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
    }
  
    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(e: DragEvent) {
      e.preventDefault();
      e.stopPropagation();
      this.fileOver = false;
      // if not null
      if (e.dataTransfer) {
        let files = e.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
      }
    }
  }