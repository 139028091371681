<div class="notification-list-header">
    <div class="header-container">
        <span class="icon-Pagination-Arrow-1" (click)="hideNotifications()"></span>
        <span class="header-title">Notifications</span>
        <div class="header-actions">
            <span class="read-all-action" *ngIf="haveUnreadNotifications()" (click)="readAllUnread()">Mark all as read</span>
<!--            <span class="icon-Settings"></span>-->
        </div>
    </div>
    <div class="horizontal-separator"></div>
</div>
<div class="notification-list">
    <div *ngFor="let  notificationGroup of groupedNotification()" class="group">
        <div class="group-title">{{convertGroupDate(notificationGroup.date)}}</div>
        <div class="notification-row" *ngFor="let notification of notificationGroup.notifications">
            <div class="notification-body">
                <div class="notification-icon">
                    <div class="notification-type" [innerHTML]="getCategoryType(notification)"></div>
                    <div class="notification-sub-category">
                        <span [ngClass]="getSubCategoryType(notification)"></span>
                    </div>
                </div>
                <div class="notification-info">
                    <span [innerHTML]="notificationMsgTransform(notification.message)"></span>
                    <span>{{convertDate(notification.created_at)}}</span>
                </div>
            </div>
            <div class="read-indicator">
                <div class="dot" *ngIf="!notification.read"></div>
                <div class="icon-double_check mark-as-read" (click)="readNotification(notification)"
                     *ngIf="!notification.read"></div>
            </div>
        </div>
    </div>
</div>