import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { LocationService } from "../../../../services/location.service";
import { debounceTime, map, mergeMap, Observable, of } from "rxjs";
import { Location } from "../../../../models/location.model";
import { catchError, filter } from "rxjs/operators";
import { MapCommunicatorService } from "../../map-communicator.service";
import { FeatureGeometry } from "../../../../models/area-item.model";

@Component({
  selector: 'frmg-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class AddressSearchComponent {

  public locationSearchCtrl: FormControl;
  public locations$: Observable<Array<Location>>;
  private forced: boolean = false;

  @Output() mapDrawEvent = new EventEmitter<FeatureGeometry | null>();

  constructor(private fb: FormBuilder,
              private mapCommunicatorService: MapCommunicatorService,
              private locationService: LocationService) {
    this.locationSearchCtrl = this.fb.control('');
    this.locations$ = this.locationSearchCtrl.valueChanges.pipe(
      debounceTime(500),
      filter(resp => resp || this.forced),
      mergeMap(query => {
          if (typeof query !== 'string') {
            query = query.formatted_address
          }
          if (this.forced) {
            this.forced = false;
            return of([]);
          }
          const convertable = this.convertToLatLon(query);
          if (convertable) {
            return of([{
              latitude: convertable.latitude,
              longitude: convertable.longitude,
              formatted_address: query
            }])
          } else {
            return this.locationService.getLocations(query).pipe(
              map(result => {
                return result.map(location => {
                  if (!location.formatted_address) {
                    location.formatted_address = query
                  }
                  return location;
                })
              }),
              catchError(err => of([])),
            )
          }
        }
      ),
    )
  }

  public goToLocation($event: MatAutocompleteSelectedEvent) {
    let value = $event.option.value as Location;
    this.mapCommunicatorService.mapCommunicator.next('clear');
    if (value.geometry) {
      const set1 = value.geometry.viewport.northeast.lng + ':' + value.geometry.viewport.northeast.lat;
      const set2 = value.geometry.viewport.southwest.lng + ':' + value.geometry.viewport.southwest.lat;
      this.mapCommunicatorService.mapCommunicator.next(`goToLocation_${set1}_${set2}_true`)
    } else {
      // @ts-ignore
      this.mapCommunicatorService.mapCommunicator.next(`goToLocationLL_${value.latitude}_${value.longitude}_true`)
    }
  }

  public clearSearch() {
    this.forced = true;
    this.locationSearchCtrl.setValue('');
    this.mapCommunicatorService.mapCommunicator.next('clear');
    this.mapDrawEvent.emit(null)
  }


  public displayAddress(location: Location) {
    return location.formatted_address || '';
  }

  private convertToLatLon(input: string) {
    const formats = [
      {
        regex: /^(-?\d+(?:\.\d+)?)[°˚]\s*(\d+(?:\.\d+)?)?[′']?\s*(\d+(?:\.\d+)?)?(?:″|''|")?\s*([NSEW])?,\s*(-?\d+(?:\.\d+)?)[°˚]\s*(\d+(?:\.\d+)?)?[′']?\s*(\d+(?:\.\d+)?)?(?:″|''|")?\s*([NSEW])?/i,
        parse: (match) => ({
          latitude: convertDMSToDecimal(match[1], match[2], match[3], match[4]),
          longitude: convertDMSToDecimal(match[5], match[6], match[7], match[8])
        })
      },
      {
        regex: /^(-?\d+(?:\.\d+)?)\s*,\s*(-?\d+(?:\.\d+)?)/,
        parse: (match) => ({
          latitude: parseFloat(match[1]),
          longitude: parseFloat(match[2])
        })
      }
    ];

    function convertDMSToDecimal(degrees: string, minutes: string, seconds: string, direction: string) {
      let decimal = parseFloat(degrees);
      if (minutes) decimal += parseFloat(minutes) / 60;
      if (seconds) decimal += parseFloat(seconds) / 3600;
      if (direction === 'S' || direction === 'W' || direction === '-') {
        decimal *= -1;
      }
      return decimal;
    }

    for (const format of formats) {
      if (input.trim) {
        const match = input.trim().match(format.regex);
        if (match) {
          return format.parse(match);
        }
      }
    }

    return null;
  }
}
