import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ToastNotificationService } from "../../../services/toast-notification.service";
import { UserService } from "../../../services/user.service";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { GenericRole, Permission } from "../../../models/team.model";
import { isArray } from "@angular/compiler-cli/src/ngtsc/annotations/common";

@Component({
  selector: 'frmg-role-modal',
  templateUrl: './role-modal.component.html',
  styleUrls: ['./role-modal.component.scss']
})
export class RoleModalComponent implements OnInit {
  public newRoleGroup: FormGroup;
  public roles: Array<GenericRole> = [GenericRole.Admin, GenericRole.Member, GenericRole.Owner];
  public userPermissions: Array<Permission>;
  public areaPermissions: Array<Permission>;
  public tagPermissions: Array<Permission>;
  public dashboardPermissions: Array<Permission>;
  public downloadsPermissions: Array<Permission>;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    private dialogRef: MatDialogRef<RoleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toastNotificationService: ToastNotificationService,
    private userService: UserService,
  ) {
    this.newRoleGroup = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', []],
      permissions_ids_user: ['', []],
      permissions_ids_area: ['', []],
      permissions_ids_tag: ['', []],
      permissions_ids_dashboard: ['', []],
      permissions_ids_downloads: ['', []],
    })
    this.newRoleGroup.setValidators(this.permissionValidator())
    this.userService.getPermissions().subscribe(permissions=>{
      this.userPermissions = permissions.results.filter(perm=>perm.name.includes('team_members'))
      this.areaPermissions = permissions.results.filter(perm=>perm.name.includes('areas'))
      this.tagPermissions = permissions.results.filter(perm=>perm.name.includes('area_groups'))
      this.dashboardPermissions = permissions.results.filter(perm=>perm.name.includes('dashboard'))
      this.downloadsPermissions = permissions.results.filter(perm=>perm.name.includes('tasks') || perm.name.includes('rasters'))
    })
  }

  ngOnInit(): void {

  }

  permissionValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const groupValue = group.value;
      if (groupValue.permissions_ids_user.length || groupValue.permissions_ids_area.length
        || groupValue.permissions_ids_tag.length || groupValue.permissions_ids_dashboard.length
        || groupValue.permissions_ids_downloads.length) {
        return null;
      } else {
        return {noSelectedPermissions: true}}
    }
  }

  public closeDialog(result?: any): void {
    this.dialogRef.close(result);
  }

  public addRole(): void {
   const groupValue = this.newRoleGroup.value;
   let role: {
     name: string,
     permissions_ids: number[]
   } = {
     name: groupValue.name,
     permissions_ids: []
   };
   Object.values(groupValue).forEach(value=>{
     if (Array.isArray(value)) {
       value.forEach(perm=>{
         role.permissions_ids.push(perm.id)
       })

     }
   })
      this.userService.createRole(role).subscribe(newRole => {
        this.toastNotificationService.showNotification({type: "success", message: "Role Added successfully"});
        this.closeDialog(newRole);
      }, error => {
        let errorMsg = 'An error occurred while creating the role.'
        this.toastNotificationService.showNotification({type: "error", message: errorMsg})
      })
  }
}
