<div class="invite-container" *ngIf="!passwordCreatedSuccess">
    <div class="invite-form">
        <form class="form-container">
            <div class="password-title">
                Create Password
            </div>
            <div class="password-subtitle">
                Please choose a new password for your account.
            </div>
            <div class="password-field">
                <mat-label>New Password</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="password" placeholder="Password" required
                           [type]="hidePassword ? 'password' : 'text'">
                    <span [ngClass]="!hidePassword ? 'icon-eye' : 'icon-eye-closed'"
                          matSuffix
                          (click)="togglePasswordVisibility()"
                          [attr.aria-label]="'Hide password'"
                          [attr.aria-pressed]="hidePassword"></span>
                </mat-form-field>
            </div>

            <div class="password-field">
                <mat-label>Confirm New Password</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="confirmPassword" placeholder="Password" required
                           [type]="hidePassword ? 'password' : 'text'">
                    <span [ngClass]="!hidePassword ? 'icon-eye' : 'icon-eye-closed'"
                          matSuffix
                          (click)="togglePasswordVisibility()"
                          [attr.aria-label]="'Hide password'"
                          [attr.aria-pressed]="hidePassword"></span>
                </mat-form-field>
            </div>

            <div class="submit-button">
                <button mat-raised-button color="primary" (click)="createPassword(password)"
                        class="frmg-primary-button" [disabled]="password && password !== confirmPassword">
                    Reset Password
                </button>
                <button mat-raised-button  (click)="backToLogin()"
                        class="frmg-secondary-button">
                    Back to Login
                </button>
            </div>
        </form>
    </div>
</div>
<frmg-password-created *ngIf="passwordCreatedSuccess" [reset]="true">

</frmg-password-created>
