import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { findFirstNonEmptyString } from "../../utils";
import { ToastNotificationService } from "../../services/toast-notification.service";

@Component({
  selector: 'frmg-invite-page',
  templateUrl: './invite-page.component.html',
  styleUrls: ['./invite-page.component.scss']
})
export class InvitePageComponent implements OnInit {

  inviteHash: string = '';
  password: string = '';
  confirmPassword: string = '';
  hidePassword: boolean = true;
  passwordCreatedSuccess = false;
  error: string;

  constructor(
    private route: ActivatedRoute,
    private toastNotificationService: ToastNotificationService,
    private settingsService: SettingsService
  ) {

  }

  ngOnInit(): void {
    this.inviteHash = this.route.snapshot.paramMap.get('invite-hash') || '';

  }

  createPassword(): void {
    // pick up the userid per the hash
    this.settingsService.retrieveUserId(this.inviteHash)
      .subscribe(invite => {
        this.settingsService.acceptInvitation(invite.email, this.inviteHash, this.password)
          .subscribe(
            {
              next: () => {
                  this.passwordCreatedSuccess = true;
              },
              error: (error) => {
                this.error =  findFirstNonEmptyString(error.error)
              }
            }
            
          )
      }, (error) => {
        this.error =  findFirstNonEmptyString(error.error)
      })
  }

  hideError() {
    this.error = null;
  }

  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }
}
