import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SfState } from "../../../../store/sf.state";
import { ToggleDownloadsPageAction } from "../../../../store/sf.actions";
import { Store } from "@ngxs/store";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { LayerService } from "../../../../services/layer.service";
import { Layer } from "../../../../models/layer.models";
import { ToastNotificationService } from "../../../../services/toast-notification.service";

@Component({
  selector: 'frmg-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss']
})
export class DownloadDialogComponent {

  public areaList: any[];
  public lineList: any[];
  public pointList: any[];
  public defaultTile: string = 'assets/images/thumbs/layer-tile.jpg';

  public selectedLayers: number[] = [];
  public isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      areaList: any[],
      layerList: Array<Layer> & { selected: boolean }
    }, //todo create a pair of objects to pass or create dialogData structure as mandatory
    private store: Store,
    private layerService: LayerService,
    private toastNotificationService: ToastNotificationService
  ) {
    this.areaList = this.data.areaList.filter(area => area.geometry.type === 'Polygon' || area.geometry.type === 'MultiPolygon');
    this.lineList = this.data.areaList.filter(area => area.geometry.type === 'LineString' || area.geometry.type === 'MultiLineString');
    this.pointList = this.data.areaList.filter(area => area.geometry.type === 'Point' || area.geometry.type === 'MultiPoint');
  }

  public totalSize(areaArray: any[]) {
    return areaArray.reduce((sum, area) => sum + area.properties.area_in_sqm, 0);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public removeArea(areaArray: any[], index: number): void {
    areaArray.splice(index, 1);
    if (!this.areaList.length && !this.lineList.length && !this.pointList.length) this.closeDialog()
  }

  public layerSelected($event: MatCheckboxChange, layer: Layer) {
    if ($event.checked) {
      this.selectedLayers.push(layer.id)
    } else {
      const index = this.selectedLayers.indexOf(layer.id)
      this.selectedLayers.splice(index, 1)
    }
  }

  public goToDownloads() {
    this.isLoading = true;
    const output_types = "tiff,tiff_colored,shapefile,sld";
    const area_ids = [...this.areaList.map(area => area.properties.id),
      ...this.lineList.map(area => area.properties.id),
      ...this.pointList.map(area => area.properties.id)].join(',');


    this.layerService.generateLayers(area_ids, this.selectedLayers.join(','), output_types).subscribe(resp => {
      const currentState = this.store.selectSnapshot(SfState.getDownloadsPageMenuState);

      let toggleDownloadsPageAction: ToggleDownloadsPageAction

      if (currentState === 'closed') {
        toggleDownloadsPageAction = new ToggleDownloadsPageAction('open');
      } else {
        toggleDownloadsPageAction = new ToggleDownloadsPageAction('closed');
      }
      this.isLoading = false;
      this.toastNotificationService.showNotification({message: "Request to download was successfully submitted", type: "success"})
      this.dialogRef.close();
      this.store.dispatch(toggleDownloadsPageAction)
    }, error => {
      this.isLoading = false;
      let message= 'Oops, something went wrong';
      if (error.error.invalid_areas && error.error.invalid_areas.length) {
        message = ''
        error.error.invalid_areas.forEach(((err, index)=>{
          if (index !== 0 ) message += '<br> '
          message += err.error
        }))
      }
      this.toastNotificationService.showNotification({message: message , type: "error"})

    })
  }
}
