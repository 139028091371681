<div class="download-header">
    <p>
        Exposure Reports
    </p>
</div>

<div class="download-table">
    <div class="table-header">
        <span>My reports</span>
        <mat-form-field appearance="outline">
            <div class="icon-search-icon" matPrefix></div>
            <input matInput [formControl]="reportSearchCtrl" placeholder="Search by reports">
        </mat-form-field>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="reportData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)"
               matSortActive="created_at" matSortDirection="desc" matSortDisableClear [trackBy]="reportTrackBy">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Report Name</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ truncate(element.name, 50) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Date and time</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.created_at | date: "dd/M/yyyy, HH:mm" }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="area_count">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Total AOIs
                    <span class="icon-info" matTooltip="Total AOIs processed"
                          matTooltipPosition="left"></span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span>{{ element.area_count }} </span>
                    <img src="assets/icons/aoi.svg"
                         alt="" width="12px"
                         height="12px">
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="count">
                <mat-header-cell *matHeaderCellDef mat-sort-header>AOI's in threshold
                    <span class="icon-info" matTooltip="AOI's within threshold"
                          matTooltipPosition="left"></span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span>{{ element.count }} </span>
                    <img src="assets/icons/aoi.svg"
                         alt="" width="12px"
                         height="12px">
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="status-block">
                        <span [ngClass]="statusClass(element)"></span>
                        <span>{{ element.status !== 'failed' ? element.status : "No match" }}</span>
                        <span *ngIf="element.status == 'processing'" style="font-weight: 700"> {{ element.progress }}
                            %</span>
                    </div>
                    <span class="icon-info" [matTooltip]="errorText"
                          matTooltipPosition="left" *ngIf="element.status == 'failed'"></span>

                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" class="report-actions" [ngClass]="{'menu-open': menuState}">
                    <ng-container *ngIf="element.status == 'completed'">
                        <button mat-raised-button class="frmg-primary-button menu-button-action"
                                [matMenuTriggerFor]="downloadMenu"
                                #menuOpen='matMenuTrigger'
                                (click)="toggleMenu(true, $event)">
                            <span>Download Report </span>
                            <div>
                                <div class="separator"></div>
                                <span class="icon-Arrow"></span>
                            </div>
                        </button>
                        <mat-menu #downloadMenu="matMenu" xPosition="before" class="download-menu-panel"
                                  (closed)="toggleMenu(false)">
                            <!--        <span> Download in CSV</span>-->
                            <!--        <span> Download in XLSX</span>-->
                            <span (click)="downloadPdf(element.id, element.name+'_report', $event)"> Download in PDF</span>
                            <span (click)="downloadXLSX(element.id, element.name+'_report', $event)"> Download in XLSX</span>
                        </mat-menu>
                        <button mat-raised-button class="frmg-secondary-button"
                                (click)="share(element, $event)">
                            <span class="icon-share"></span></button>

                    </ng-container>
                    <ng-container *ngIf="element.status == 'completed' || element.status == 'failed'">
                        <button mat-raised-button class="frmg-secondary-button"
                                (click)="deleteReport(element, $event)">
                            <span class="icon-remove-icon"></span></button>
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="shared">
                <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="shared-users">
                        <ng-container *ngFor="let user of element.shared_with; let $index = index;">
                            <frmg-user-logo [user]="user"
                                            *ngIf="$index < 2"></frmg-user-logo>
                            <frmg-user-logo [user]="moreUsers(element)"
                                            *ngIf="$index === 2"></frmg-user-logo>
                        </ng-container>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns; let $index = index;" (click)="goToReport(row)"
                     (mouseenter)="setHoverId($index)" (mouseleave)="setHoverId(null)"
                     [ngClass]="{'show_actions': $index == hoverId, 'hide_click_mouse': row.status !== 'completed'}"></mat-row>
        </table>
    </div>
    <mat-divider></mat-divider>
    <mat-paginator
            [length]="totalElements"
            [pageIndex]="currentPage-1"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 20, 30]"
            [showFirstLastButtons]="true"
            (page)="handlePageEvent($event)"
    ></mat-paginator>

</div>

