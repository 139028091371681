    <div class="layer-sidebar">
      <div class="wrapper">
<!--          <div class="layers-header">-->
<!--              <div class="layers-title">-->
<!--                  Layers-->
<!--              </div>-->
<!--          </div>-->
        <nav class="tab-bar">
          <div class="tab" [class.tab-selected]="activeTab === 'basemap'" (click)="activeTab = 'basemap'">Basemaps</div>
          <div class="tab" [class.tab-selected]="activeTab === 'layers'" (click)="activeTab = 'layers'">Layers</div>
        </nav>
        <div class="section-divider"></div>
        <div class="content">
          <section class="info">
            <div *ngIf="activeTab === 'basemap'" class="basemap-container" (wheel)="onWheel($event)">
              <div class="basemap-options">
                <label class="basemap-option" [ngClass]="{'selected': selectedBasemap === 'topographic'}">
                  <input type="radio" name="basemap" value="topographic" [(ngModel)]="selectedBasemap" (change)="onBasemapChange()">
                  <div class="basemap-icon" *ngIf="selectedBasemap === 'topographic'">
                  </div>
                  <span>Topographic</span>
                </label>
                <label class="basemap-option" [ngClass]="{'selected': selectedBasemap === 'satellite'}">
                  <input type="radio" name="basemap" value="satellite" [(ngModel)]="selectedBasemap" (change)="onBasemapChange()">
                  <div class="basemap-icon" *ngIf="selectedBasemap === 'satellite'">
                  </div>
                  <span>Satellite</span>
                </label>
              </div>
            </div>
            <div *ngIf="activeTab === 'layers'" class="layers-container" (wheel)="onWheel($event)" [formGroup]="layersGroup">
              <div *ngFor="let layer of layers" class="layer-item" [formGroupName]="layer.layer_name">
                <div class="layer-header">
                  <h2 class="info-title">{{ layer.display_name }}</h2>
                  <mat-checkbox formControlName="active"></mat-checkbox>
                </div>
                <div *ngIf="layer.colormap_type === 'ramp'" class="visual-progress" [style.background]="getGradientBackground(layer.explicit_color_map)"></div>
                <div *ngIf="layer.colormap_type === 'discrete'" class="visual-progress discrete">
                  <span *ngFor="let color of getDiscreteColors(layer.explicit_color_map)" [style.background-color]="color"></span>
                </div>
                <div class="legends-container">
                  <div class="legends" [class.numeric]="isNumeric(layer.legends[0])">
                    <ng-container *ngIf="isNumeric(layer.legends[0]); else textLegends">
                      <span *ngFor="let legend of getSpacedNumericLegends(layer.legends); let i = index" class="legend" [style.left.%]="i * (100 / (layer.legends.length - 1))" [title]="legend">{{ formatLegend(legend) }}</span>
                    </ng-container>
                    <ng-template #textLegends>
                      <span *ngFor="let legend of layer.legends" class="legend" [title]="legend">{{ formatLegend(legend) }}</span>
                    </ng-template>
                  </div>
                </div>
                <input type="range" min="0" max="1" step="0.1" formControlName="opacity">
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>