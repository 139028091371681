import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TaskService } from "../../services/task.service";
import { AreaTaskGroup, TaskGroup, TaskStatistic, TaskStatus, taskStatusTranslations } from "../../models/tasks.model";
import { truncate } from "../../utils";
import { PageEvent } from "@angular/material/paginator";
import { RiskReport } from "../../models/report.model";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Router } from "@angular/router";
import { ConfirmationDialogComponent } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: 'frmg-download-area-table',
  templateUrl: './download-area-table.component.html',
  styleUrls: ['./download-area-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0', paddingTop: '0', paddingBottom: '0'})),
      state('expanded', style({height: '*', paddingTop: '16px', paddingBottom: '16px'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])]
})
export class DownloadAreaTableComponent implements OnInit {

  @Input() id!: string;

  public displayedColumns = ["area", "status", "action"]
  public columnsToDisplayWithExpand = ['expand', ...this.displayedColumns];
  public taskData: Array<TaskGroup> = [];
  public taskGroup: TaskGroup;
  public expandedElement: AreaTaskGroup;
  public truncate = truncate;
  public currentPage = 1;
  public pageSize = 100;
  public totalElements = 0;
  public statistic: Partial<TaskStatistic> = {
    completed: 0,
    started: 0,
    cancelled: 0,
    failed: 0
  };
  public taskStatusTranslations = taskStatusTranslations;

  constructor(private taskService: TaskService,
              private router: Router,
              private dialog: MatDialog,
              private cd: ChangeDetectorRef,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.getTask()
  }

  public getTask(): void {
    this.taskService.getTasksGroupById(this.id).subscribe(result => {
      this.taskGroup = result;
    })
    this.taskService.getTasksGroupByIdTasks(this.currentPage, this.pageSize, this.id).subscribe(result => {
      this.totalElements = result.count;
      this.taskData = result.results;
    })
  }

  public download(element: TaskGroup, event: MouseEvent): void {
    event.stopPropagation();
    if (element.url) {
      window.open(element.url, "_blank");
    } else {
      this.taskService.prepareDownloadAreaTasksGroupById(element.id, this.id).subscribe(task => {
        this.downloadTask(element, task)
      })
    }
  }

  private downloadTask(element: TaskGroup, task) {
    // this.taskService.prepareDownloadTasksGroupById(element.id).subscribe(task => {
    this.notificationService.updateDownload({
      fileName: element.name,
      percentage: 0,
      total: 0,
      loaded: 0
    })
    this.taskService.downloadAreaTasksGroupById(element.id, this.id, task.task_id, element.name).subscribe(resp => {
      this.notificationService.updateDownload({
        fileName: element.name,
        percentage: resp.progress,
        total: 0,
        loaded: 0
      })
    })
    // })
  }

  public deleteDownload(element: TaskGroup, event: MouseEvent) {
    this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, {
      data: {
        header: 'Are you sure you want to delete this file?',
        body: 'Once deleted, it cannot be recovered.',
        confirmText: 'Proceed'
      }
    }).afterClosed().subscribe(del => {
      if (del) {
        this.taskService.deleteAreaTasksGroupById(this.id,element.id).subscribe(resp=>{
          const index = this.taskData.findIndex(el=>el.id == element.id)
          if (index !== -1) {
            this.taskData.splice(index, 1);
            this.taskData = this.taskData.map(item=>item)
            this.cd.detectChanges();
          }
        })
      }})

    event.stopPropagation();
  }

  public handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.currentPage = e.pageIndex + 1;
  }

  public goBack() {
    this.router.navigate([`/downloads`]);
  }

  public statusClass(report: RiskReport) {
    switch (report.status) {
      case 'completed':
        return 'icon-Processed';
      case TaskStatus.PROGRESS:
      case TaskStatus.PENDING:
      case TaskStatus.STARTED:
        return 'icon-Processing';
      default:
        return 'icon-Canceled'
    }
  }

}
