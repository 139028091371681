<div class="mat-typography analysis">
    <div class="analysis-image" style="position: relative">
        <img [src]="this.data.image" alt="analysis-map" class="info-block">
<!--        <div class="gradient-container">-->
<!--            <span class="input-label">Exposure</span>-->
<!--            <div class="gradient" [style.background]="exposureLayerGradient"></div>-->
<!--            <div class="exposure-values">-->
<!--                <span>0</span>-->
<!--                <span>200</span>-->
<!--            </div>-->
<!--        </div>-->
        <div class="info-block">
            <span class="input-label">Feature Exposure Analysis</span>
            <div>
                <ng-template [ngTemplateOutlet]="summaryCard"
                             [ngTemplateOutletContext]="{
                                     value: this.data.input_point_value?.toFixed(0),
                                             title: 'Input Point Value',
                                             icon: exposureIcon
                                     }">
                </ng-template>
                <ng-template [ngTemplateOutlet]="summaryCard"
                             [ngTemplateOutletContext]="{
                                     value: this.data.nearest_value_actual?.toFixed(0),
                                             title: 'Nearest Risk Point Value',
                                             icon: nearestPointIcon
                                     }">
                </ng-template>
            </div>
            <div>
                <ng-template [ngTemplateOutlet]="summaryCard"
                             [ngTemplateOutletContext]="{
                                     value: this.data.highest_value_actual?.toFixed(0),
                                             title: 'Highest Risk Value',
                                             icon: highRiskIcon
                                     }">
                </ng-template>
                <ng-template [ngTemplateOutlet]="summaryCard"
                             [ngTemplateOutletContext]="{
                                     value: this.data.lowest_value_actual?.toFixed(0),
                                             title: 'Lowest Risk Value',
                                             icon: lowRiskIcon
                                     }">
                </ng-template>
            </div>
            <div>
                <ng-template [ngTemplateOutlet]="summaryCard"
                             [ngTemplateOutletContext]="{
                                     value: (this.data.distance?.toFixed(0)||0) +'m',
                                             title: 'Distance to the highest risk',
                                             icon: distanceIcon
                                     }">
                </ng-template>
<!--                <ng-template [ngTemplateOutlet]="summaryCard"-->
<!--                             [ngTemplateOutletContext]="{-->
<!--                                     value: this.data.threshold_percentage?.toFixed(0) +'%',-->
<!--                                             title: 'Threshold percentage'-->
<!--                                     }">-->
<!--                </ng-template>-->
            </div>
        </div>
    </div>
    <div class="analysis-container">
<!--        <div>-->
<!--            <div class="dialog-header">-->
<!--                <span>-->
<!--                    {{this.data.name}} Report-->
<!--                </span>-->
<!--&lt;!&ndash;                <span class="icon-close"(click)="closeDialog()" matTooltip="Close"></span></div>&ndash;&gt;-->
<!--            <div class="horizontal-separator"></div>-->
<!--        </div>-->
        <div class="data-block">
            <div class="info-block">
                <div>
                    <ng-template [ngTemplateOutlet]="summaryCard"
                                 [ngTemplateOutletContext]="{
                                     value: this.data.lowest_threshold?.toFixed(0),
                                             title: 'Lower Threshold'
                                     }">
                    </ng-template>
                    <ng-template [ngTemplateOutlet]="summaryCard"
                                 [ngTemplateOutletContext]="{
                                     value: this.data.highest_threshold?.toFixed(0),
                                             title: 'Upper Threshold'
                                     }">
                    </ng-template>
                    <ng-template [ngTemplateOutlet]="summaryCard"
                                 [ngTemplateOutletContext]="{
                                     value: this.data.threshold_percentage?.toFixed(0) +'%',
                                             title: 'Threshold percentage'
                                     }">
                    </ng-template>
                    <ng-template *ngIf="lineLength != '0'"
                            [ngTemplateOutlet]="summaryCard"
                                 [ngTemplateOutletContext]="{
                                     value: lineLength +'km',
                                             title: 'Segment length'
                                     }">
                    </ng-template>
                </div>
            </div>
            <img [src]="this.data.image_histogram" alt="analysis-map" class="info-block">
            <img [src]="this.data.fire_trends_image" alt="analysis-map" class="info-block">
        </div>
<!--        <div class="button-row ">-->
<!--            <button mat-raised-button class="frmg-primary-button"-->
<!--                    [matMenuTriggerFor]="downloadMenu"-->
<!--                    #menuOpen='matMenuTrigger'-->
<!--                    [ngClass]="{'menu-open': menuOpen.menuOpen}"-->
<!--                    (click)="$event.stopPropagation()">-->
<!--                <span>Download Report </span>-->
<!--                <div>-->
<!--                    <div class="separator"></div>-->
<!--                    <span class="icon-Arrow"></span>-->
<!--                </div>-->
<!--                <mat-menu #downloadMenu="matMenu" xPosition="before" class="download-menu-panel">-->
<!--                    &lt;!&ndash;        <span> Download in CSV</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;        <span> Download in XLSX</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span (click)="downloadArea($event)"> Download in PDF</span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span (click)="downloadAreaXLSX( $event)"> Download in XLSX</span>&ndash;&gt;-->
<!--                </mat-menu>-->
<!--            </button>-->
<!--        </div>-->
    </div>
</div>

<ng-template #summaryCard let-value="value" let-title="title" let-icon="icon">
    <div class="summary-card">
        <div class="card-info">
            <p class="card-reading" [innerHTML]="value"></p>
            <p class="card-title">{{ title | translate }}</p>
        </div>
        <div class="card-icon" *ngIf="icon">
            <img [src]="icon" alt="icon" />
        </div>
    </div>
</ng-template>

