<div class="account-settings">
    <div class="account-header">
        <h3 class="account-title">Plan Details</h3>
        <div class="account-plan" *ngIf="billingInfo">
            <div class="date-block">
                <span>Start Date</span>
                <span>{{billingInfo.plan_start_date | date: "dd/M/yyyy"}}</span>
            </div>
            <div class="vertical-separator"></div>
            <div class="date-block">
                <span>End Date</span>
                <span>{{billingInfo.plan_end_date | date: "dd/M/yyyy"}}</span>
            </div>
        </div>
<!--        <p class="account-description">Manage your account settings.</p>-->
    </div>
    <div class="team-content">
        <table mat-table class="team-table" [dataSource]="billingInfoTable" *ngIf="billingInfo">
            <ng-container matColumnDef="aoi_size_limit_km2">
                <mat-header-cell *matHeaderCellDef> Area Downloads</mat-header-cell>
                <mat-cell *matCellDef="let data"> {{ data.aoi_size_limit_km2  | number}} km<sup>2</sup></mat-cell>
            </ng-container>
            <ng-container matColumnDef="download_request_limit">
                <mat-header-cell *matHeaderCellDef> Download Requests</mat-header-cell>
                <mat-cell *matCellDef="let data"> {{ data.download_request_limit  | number}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="report_size_limit_km2">
                <mat-header-cell *matHeaderCellDef> Report Areas</mat-header-cell>
                <mat-cell *matCellDef="let data"> {{ data.report_size_limit_km2  | number}} km<sup>2</sup></mat-cell>
            </ng-container>
            <ng-container matColumnDef="report_request_limit">
                <mat-header-cell *matHeaderCellDef> Report Requests</mat-header-cell>
                <mat-cell *matCellDef="let data"> {{ data.report_request_limit  | number}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="max_aois_per_report">
                <mat-header-cell *matHeaderCellDef> Areas per report</mat-header-cell>
                <mat-cell *matCellDef="let data"> {{ data.max_aois_per_report  | number}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>
    </div>
</div>

<div class="account-settings">
    <div class="account-header">
        <h3 class="account-title">Team Usage</h3>
    </div>
    <div class="summary-info"  *ngIf="billingInfo">
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: billingInfo.team_usage.total_aoi_size,
        reading: billingInfo.aoi_size_limit_km2,
        units: 'km',
        title: 'Area Downloads',
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: billingInfo.team_usage.total_download_requests,
        reading: billingInfo.download_request_limit,
        title: 'Download Requests',
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: billingInfo.team_usage.total_report_size,
        reading: billingInfo.report_size_limit_km2,
        units: 'km',
        title: 'Report Areas'
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: billingInfo.team_usage.total_report_requests,
        reading: billingInfo.report_request_limit,
        title: 'Report Requests'
        }"></ng-template>
    </div>
    <div class="team-content">
        <table mat-table class="team-table" [dataSource]="usersData" matSort>
            <ng-container matColumnDef="user__email">
                <mat-header-cell *matHeaderCellDef mat-sort-header> User</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.user__email }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="aoi_size">
                <mat-header-cell *matHeaderCellDef> Area Downloads</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.aoi_size.toFixed(0) }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="download_requests">
                <mat-header-cell *matHeaderCellDef> Download Requests</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.download_requests.toFixed(0) }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="report_requests">
                <mat-header-cell *matHeaderCellDef> Report Requests</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.report_requests.toFixed(0) }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="report_size">
                <mat-header-cell *matHeaderCellDef> Report Ares</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.report_size.toFixed(0) }}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsUser; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsUser;"></mat-row>
        </table>
    </div>
</div>

<ng-template #summaryCard let-value="value" let-title="title" let-reading="reading" let-units="units">
    <div class="summary-card">
        <div class="card-info">
            <p class="card-reading"><strong>{{ value.toFixed(0)  | number}}</strong> / {{reading  | number}} {{units}} <sup *ngIf="units">2</sup></p>
            <p class="card-title">{{ title | translate }}</p>
            <div class="progress-line">
                <div class="progress-line-status" [style.width]="(value.toFixed(2)/reading)*100 + '%'"
                [ngClass]="(value.toFixed(0)/reading)*100 > 0.75 ? 'error' : '' "></div>
            </div>
        </div>
    </div>
</ng-template>