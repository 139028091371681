<div class="report-info">

    <div class="report-summary">
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: sizeTotal,
        title: 'Total calculated area',
        icon: 'area'
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: totalElements,
        title: 'Total number of AOIs',
        icon: 'Square'
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: avgScore,
        title: 'Average Risk score',
        icon: 'Fire-risk'
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: threshold,
        title: 'Threshold Range',
        icon: 'threshold'
        }"></ng-template>
    </div>

    <div class="map" [ngClass]="{'spinner' : loadingData() && !report}">
        <frmg-map [reportData]="reportDataFull" *ngIf="reportDataFull.length"/>
    </div>
    <div id="chart" class="report-chart">
        <div class="chart-title">Risk Score</div>
        <div class="chart-container">
            <apx-chart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [labels]="chartOptions.labels"
                    [plotOptions]="chartOptions.options"
                    [legend]="chartOptions.legend"
                    [colors]="chartOptions.colors"
                    [tooltip]="chartOptions.tooltip"
                    [stroke]="chartOptions.stroke"
            ></apx-chart>
            <div class="chart-legend">
                <div *ngFor="let legend of legends; index as i">
                    <div class="color-block" [style.background-color]="colors[i]"
                         [matTooltipPosition]="'above'" [matTooltip]="legend*40+ '-' + (legend+1)*40 "
                         matTooltipClass="exposure-color-tooltip"></div>
                    <div class="legend-block">
                        {{ legend }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="download-table" [ngClass]="{'spinner' : loadingData()}">
    <div class="table-header">
        <span>Fire Risk</span>
        <div class="header-control">
            <mat-form-field appearance="outline">
                <div class="icon-search-icon" matPrefix></div>
                <input matInput [formControl]="areaSearchCtrl" placeholder="Search by areas">
            </mat-form-field>
            <span class="icon-column_selector"
                  (click)="toggleColumnMenu()"
                  [ngClass]="{'menu-open': columnMenu}"></span>
            <div *ngIf="columnMenu" class="column-menu-panel">
                <div class="column-header">
                    <div>
                        <span class="column-header-title">Configure columns</span>
                        <span class="icon-close" (click)="toggleColumnMenu()"></span>
                    </div>
                    <div class="horizontal-separator"></div>
                </div>
                <div class="columns-body" (click)="$event.stopPropagation()" cdkDropListGroup>
                    <div class="columns-shown"
                         cdkDropList
                         [cdkDropListData]="displayedColumnsDrag"
                         (cdkDropListDropped)="drop($event)">
                        <span class="list-title">
                            Shown attributes
                        </span>
                        <ng-container *ngFor="let column of displayedColumnsDrag; let i = index">
                            <div class="list-column" cdkDrag
                                 cdkDragPreviewClass="list-column">
                                <span class="icon-Drag"></span>
                                <span class="cdkDrag">{{ column }} </span>
                                <mat-slide-toggle
                                        [checked]="true"
                                        (change)="toggleColumn(i, column, true)">
                                </mat-slide-toggle>
                            </div>
                        </ng-container>
                    </div>
                    <div class="columns-hidden">
                        <span class="list-title">
                            Hidden attributes
                        </span>
                        <div class="list-column" *ngFor="let column of hiddenColumns; let i = index">
                            <span class="icon-Drag"></span>
                            <span class="cdkDrag">{{ column }} </span>
                            <mat-slide-toggle
                                    [checked]="false"
                                    (change)="toggleColumn(i, column, false)">
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="column-controls">

                </div>
            </div>
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="reportData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)"
               [matSortDisabled]="loadingData()" matSortDisableClear>
            <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column" [sticky]="column === 'name'"
                          [stickyEnd]="column === 'action'">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeader(column) }}</mat-header-cell>
                <ng-container [ngSwitch]="column">
                    <ng-container *ngSwitchCase="'name'">
                        <mat-cell *matCellDef="let element"
                                  [matTooltip]="element.name">
                            <div class="area-name-cell">
                                <span>
                                    <div [ngClass]="getAreaIconType(element.area)"></div>
                                    <div>{{ truncate(element.name, 50) }}</div>
                                </span>
                                <span> {{ element.area_details[0].address?.name || element.area_details[0].address?.display_name }}</span>
                            </div>

                            <span class="icon-focus-in" (click)="focusOn(element, $event)"></span>
                        </mat-cell>
                    </ng-container>
                    <ng-container *ngSwitchCase="'first_risk_score'">
                        <mat-cell *matCellDef="let element">{{ element.area_details[0].risk_score }}</mat-cell>
                    </ng-container>
                    <ng-container *ngSwitchCase="'first_input_point_value'">
                        <mat-cell *matCellDef="let element">{{ element.area_details[0].input_point_value }}</mat-cell>
                    </ng-container>
                    <ng-container *ngSwitchCase="'action'">
                        <mat-cell *matCellDef="let element" class="report-actions">
                            <div class="list-element-actions-menu">
                                <div class="icon-more-horiz"
                                     [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation();">
                                </div>
                                <mat-menu #actionMenu="matMenu">
                                    <button mat-menu-item (click)="downloadArea(element.id, element.name+'_report')"
                                            class="menu-slot">Download Report
                                    </button>
                                </mat-menu>
                            </div>
                            <!--                        <button mat-raised-button class="frmg-primary-button"-->
                            <!--                                [matMenuTriggerFor]="downloadMenu"-->
                            <!--                                #menuOpen='matMenuTrigger'-->
                            <!--                                (click)="$event.stopPropagation()">-->
                            <!--                            <span>Download Report </span>-->
                            <!--                            <div>-->
                            <!--                                <div class="separator"></div>-->
                            <!--                                <span class="icon-Arrow"></span>-->
                            <!--                            </div>-->
                            <!--                        </button>-->
                            <!--                        <mat-menu #downloadMenu="matMenu" xPosition="before" class="download-menu-panel">-->
                            <!--                            <span (click)="downloadArea(element.id, element.name+'_report')"> Download in PDF</span>-->
                            <!--                            <span (click)="downloadAreaXLSX(element.id, element.name+'_report')"> Download in XLSX</span>-->
                            <!--                        </mat-menu>-->
                        </mat-cell>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <mat-cell
                                *matCellDef="let element">{{ element.area.properties?.metadata && element.area.properties?.metadata[column] }}
                        </mat-cell>
                    </ng-container>
                </ng-container>

            </ng-container>
            <ng-container matColumnDef="name" [sticky]="true">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Area Name</mat-header-cell>
                <mat-cell *matCellDef="let element"
                          [matTooltip]="element.name">
                    <div class="area-name-cell">
                        <span>
                            <div [ngClass]="getAreaIconType(element.area)"></div>
                            <div>{{ truncate(element.name, 50) }}</div></span>
                        <span> {{ element.area_details[0].address?.name || element.area_details[0].address?.display_name }}</span>
                    </div>

                    <span class="icon-focus-in" (click)="focusOn(element, $event)"></span>
                </mat-cell>
            </ng-container>
            <!--            <ng-container matColumnDef="address__display_name">-->
            <!--                <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>-->
            <!--                <mat-cell *matCellDef="let element"-->
            <!--                          [matTooltip]="element.area_details[0].address?.display_name ">-->
            <!--                </mat-cell>-->
            <!--            </ng-container>-->
            <!--            <ng-container matColumnDef="first_risk_score">-->
            <!--                <mat-header-cell *matHeaderCellDef mat-sort-header>Risk Score</mat-header-cell>-->
            <!--                <mat-cell *matCellDef="let element">{{ element.area_details[0].risk_score }}</mat-cell>-->
            <!--            </ng-container>-->
            <!--            <ng-container matColumnDef="first_input_point_value">-->
            <!--                <mat-header-cell *matHeaderCellDef mat-sort-header>Avg. Exposure</mat-header-cell>-->
            <!--                <mat-cell *matCellDef="let element">{{ element.area_details[0].input_point_value }}</mat-cell>-->
            <!--            </ng-container>-->
            <!--            <ng-container matColumnDef="action">-->
            <!--                <mat-header-cell *matHeaderCellDef></mat-header-cell>-->
            <!--                <mat-cell *matCellDef="let element" class="report-actions" [ngClass]="{'menu-open': menuOpen.menuOpen}">-->
            <!--                    <button mat-raised-button class="frmg-primary-button"-->
            <!--                            [matMenuTriggerFor]="downloadMenu"-->
            <!--                            #menuOpen='matMenuTrigger'-->
            <!--                            (click)="$event.stopPropagation()">-->
            <!--                        <span>Download Report </span>-->
            <!--                        <div>-->
            <!--                            <div class="separator"></div>-->
            <!--                            <span class="icon-Arrow"></span>-->
            <!--                        </div>-->
            <!--                    </button>-->
            <!--                    <mat-menu #downloadMenu="matMenu" xPosition="before" class="download-menu-panel">-->
            <!--                        <span (click)="downloadArea(element.id, element.name+'_report')"> Download in PDF</span>-->
            <!--                        <span (click)="downloadAreaXLSX(element.id, element.name+'_report')"> Download in XLSX</span>-->
            <!--                    </mat-menu>-->
            <!--                </mat-cell>-->
            <!--            </ng-container>-->
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"
                     [style.border-color]="colors[row.area_details[0].risk_score]"
                     (click)="openAnalysisModal(row)"></mat-row>
        </table>
    </div>
    <!--    <mat-divider></mat-divider>-->
    <!--    <div class="table-footer">Showing {{totalElements}} out of {{totalElements}} </div>-->

</div>

<ng-template #summaryCard let-value="value" let-title="title" let-icon="icon">
    <div class="summary-card">
        <div class="card-icon">
            <span [class]="'icon-'+icon">
            </span>
        </div>
        <div class="card-info">
            <p class="card-reading" [innerHTML]="value"></p>
            <p class="card-title">{{ title | translate }}</p>
        </div>
    </div>
</ng-template>
