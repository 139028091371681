import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'frmg-login-base-page',
  templateUrl: './login-base-page.component.html',
  styleUrls: ['./login-base-page.component.scss']
})
export class LoginBasePageComponent {

  public currentYear: number = new Date().getFullYear();
  public isLogin: boolean = true;

  constructor(private router: Router ) {
    this.isLogin = this.router.url === '/login';
  }

  public goToContact() {
    window.open("https://skyforest.io/contact/", '_blank');
  }
}
