export enum GenericRole {
    Admin = 'admin',
    Owner = 'owner',
    Member = 'member'
}

export interface TeamMember {
    id: number;
    user_id: number;
    first_name: string;
    last_name: string;
    display_name: string;
    email: string;
    role_team: {
        id: number;
        name: string;
        permissions: any[];
    };
    permissions: any[];
}

export interface Team {
    id: number, // this is the resource id
    name: string, // display name of team
    slug: string, // name id of the team
    members: TeamMember[],
    membership: {
        role: string
    }
}

export interface Permission {
    id: number,
    name: string,
    description?: string
}

export interface Role {
    id: number,
    name: string,
    permission: Array<Permission>
}

export interface BillingInfo {
    plan_name: string,
    plan_start_date: string,
    plan_end_date: string,
    aoi_size_limit_km2: number,
    download_request_limit: number,
    report_size_limit_km2: number,
    report_request_limit: number,
    max_aois_per_report: number,
    team_usage: TeamUsage,
    users_usage: Array<UserUsage>
}

export interface UserUsage {
    user__email: string,
    aoi_size: number,
    download_requests: number,
    report_size: number,
    report_requests: number
}

export interface TeamUsage {
    total_aoi_size: number,
    total_download_requests: number,
    total_report_size: number,
    total_report_requests: number
}

