import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TaskService } from "../../services/task.service";
import { TaskGroup, TaskStatistic, TaskStatus, taskStatusTranslations } from "../../models/tasks.model";
import { truncate } from "../../utils";
import { PageEvent } from "@angular/material/paginator";
import { RiskReport } from "../../models/report.model";
import { Router } from "@angular/router";
import { NotificationService } from "../../services/notification.service";
import { ConfirmationDialogComponent } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'frmg-download-table',
  templateUrl: './download-table.component.html',
  styleUrls: ['./download-table.component.scss']
})
export class DownloadTableComponent implements OnInit {

  public displayedColumns = ["area", "total_aois", "created_at", "status", "action"]
  public taskData: Array<TaskGroup> = [];
  public truncate = truncate;
  public currentPage = 1;
  public pageSize = 100;
  public totalElements = 0;
  public statistic: Partial<TaskStatistic> = {
    completed: 0,
    started: 0,
    cancelled: 0,
    failed: 0
  };
  public taskStatusTranslations = taskStatusTranslations;

  constructor(private taskService: TaskService,
              private router: Router,
              private dialog: MatDialog,
              private notificationService: NotificationService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.getTasks()
  }

  public getTasks(): void {
    // this.taskService.getTasksStatus().subscribe(result => {
    //   this.statistic = result;
    // })
    this.taskService.getTasksGroup(this.currentPage, this.pageSize).subscribe(result => {
      this.totalElements = result.count;
      this.taskData = result.results;
      if (result.results.some(task => task.status === TaskStatus.PROGRESS || task.status === TaskStatus.PENDING || task.status === TaskStatus.STARTED)) {
        setTimeout(() => this.getTasks(), 3000)
      }
    })
  }

  public download(element: TaskGroup, event: MouseEvent): void {
    event.stopPropagation();
    if (element.url) {
      window.open(element.url, "_blank");
    } else {
      this.taskService.prepareDownloadTasksGroupById(element.id).subscribe(task => {
        this.downloadTask(element, task)
      })
    }
  }

  private downloadTask(element: TaskGroup, task) {
    // this.taskService.prepareDownloadTasksGroupById(element.id).subscribe(task => {
    this.notificationService.updateDownload({
      fileName: element.name,
      percentage: 0,
      total: 0,
      loaded: 0
    })
    this.taskService.downloadTasksGroupById(element.id, task.task_id, element.name).subscribe(resp => {
      this.notificationService.updateDownload({
        fileName: element.name,
        percentage: resp.progress,
        total: 0,
        loaded: 0
      })
    })
    // })
  }

  public deleteDownload(element: TaskGroup, event: MouseEvent) {
    this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, {
      data: {
        header: 'Are you sure you want to delete this file?',
        body: 'Once deleted, it cannot be recovered.',
        confirmText: 'Proceed'
      }
    }).afterClosed().subscribe(del => {
      if (del) {
        this.taskService.deleteTasksGroupById(element.id).subscribe(resp => {
          const index = this.taskData.findIndex(el => el.id == element.id)
          if (index !== -1) {
            this.taskData.splice(index, 1);
            this.taskData = this.taskData.map(item=>item)
            this.cd.detectChanges();
          }
        })
      }
    })

    event.stopPropagation();
  }

  public handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.currentPage = e.pageIndex + 1;
    this.getTasks();
  }

  public statusClass(report: RiskReport) {
    switch (report.status) {
      case 'completed':
        return 'icon-Processed';
      case TaskStatus.PROGRESS:
      case TaskStatus.PENDING:
      case TaskStatus.STARTED:
        return 'icon-Processing';
      default:
        return 'icon-Canceled'
    }
  }

  public goToTask(task: TaskGroup) {
    this.router.navigate([`/downloads/${task.id}`])
  }
}
