export const environment = {
    production: false,
    basicAuth: false,
    username: 'admin',
    password: '61n_Tes{k4uqI|=;2i',
    //baseUrl: 'http://localhost:8000/a',
    baseUrlLogin: 'https://dev-api.skyforest.io/api',
    baseUrl: 'https://dev-api.skyforest.io/api/v1',
    wsBaseUrl: 'wss://dev-api.skyforest.io/ws/',
    //baseUrl: 'https://dev-app.skyforest.io/api/v1',
    defaultTeam: 'ninjas',
    //mapServerBaseUrl: 'http://localhost:5001/singleband'
    //mapServerBaseUrl: 'http://localhost:8888/singleband'
    //mapServerBaseUrl: 'https://terracotta.skyforest.io/singleband'
    mapServerBaseUrl: 'https://dev-api.skyforest.io/layers/singleband/'
    
}