import { State, Selector, Action, StateContext, Store } from "@ngxs/store";
import { SfStateModel, SidebarStateEnum } from "../models/sfAppState.model";
import {
    ChangeBottomDrawerAction,
    ChangeSidebarLeftAction,
    ChangeSidebarRightAction,
    SetChartsPageMenuAction,
    SetSettingsMenuAction,
    SetUserPageMenuAction,
    ToggleAreaManagerAction,
    ToggleDashboardMenuAction,
    ToggleDownloadsPageAction,
    ToggleLanguageAction,
    ToggleLayerManagerAction,
    ToggleMapMenuAction,
    TogglePolygonToolAction,
    ToggleReportsPageAction,
    ToggleUploadDialogAction
} from "./sf.actions";
import { Injectable } from "@angular/core";


// decorator for state
@State<SfStateModel>({
    name: 'sf',
    defaults: {
        sidebarLeft: {
            state: 'closed',
            riskMap: {
                state: 'closed',
                layers: {
                    state: 'closed'
                },
                downloads: {
                    state: 'closed'
                },
                areas: {
                    state: 'closed'
                }
            },
            dashboard: {
                state: 'closed',
                charts: {
                    state: 'closed'
                },
                users: {
                    state: 'closed'
                }
            },
            downloads: {
                state: 'closed'
            },
            reports: {
                state: 'closed'
            }
        },
        drawTools: {
            menuBar: {
                state: 'closed',
                polygonTool: {
                    state: 'unselected'
                },
                upload: {
                    state: 'idle' //'active' if upload dialog 
                }
            }
        },
        sidebarRight: {
            state: 'closed',
            layerManager: {
                state: 'closed',
            }
        },
        settings: {
            state: 'closed'
        },
        language: {
            state: 'en'
        },
        bottomDrawer: {
            state: 'closed'
        },
        mapGeoSearch: {
            state: true
        }
    }
})

@Injectable()

export class SfState {

    constructor(
        private store: Store
    ) {
        
    }
    // create the necessary selectors
    // define reducers for actions
    @Selector()
    static getSidebarLeftState(state: SfStateModel): string {
        return state.sidebarLeft.state;
    }

    @Selector()
    static getDashboardMenuState(state: SfStateModel): string {
        return state.sidebarLeft.dashboard.state;
    }

    @Selector()
    static getLayerManagerState(state: SfStateModel): string {
        // return state.sidebarLeft.riskMap.layers.state;
        return state.sidebarRight.layerManager.state;
    }

    @Selector()
    static getAreaManagerState(state: SfStateModel): string {
        return state.sidebarLeft.riskMap.areas.state;
    }

    @Selector()
    static setUserManagementPageState(state: SfStateModel): string {
        return state.sidebarLeft.dashboard.users.state;
    }

    @Selector()  //charts is the default page for dashboard
    static getChartsPageState(state: SfStateModel): string {
        return state.sidebarLeft.dashboard.charts.state;
    }

    @Selector()
    static getMapMenuState(state: SfStateModel): string {
        return state.sidebarLeft.riskMap.state;
    }

    @Selector()
    static getSidebarRightState(state: SfStateModel): string {
        return state.sidebarRight.state;
    }

    @Selector()
    static getSettingsMenuState(state: SfStateModel): string {
        return state.settings.state;
    }

    @Selector()
    static getLanguageState(state: SfStateModel): string {
        return state.language.state;
    }

    @Selector()
    static getBottomDrawerState(state: SfStateModel): string {
        return state.bottomDrawer.state;
    }

    /* ------------------- Lower Menu States -------------- */
    @Selector()  //charts is the default page for dashboard
    static setSettingsPageState(state: SfStateModel): string {
        return state.settings.state;
    }

    /* ------------------- Map Draw Tool States -------------- */
    @Selector()  //charts is the default page for dashboard
    static getPolygonToolState(state: SfStateModel): string {
        return state.drawTools.menuBar.polygonTool.state;
    }

    /* ------------------- Map Draw Tool States -------------- */
    @Selector()  //charts is the default page for dashboard
    static getUploadDialogState(state: SfStateModel): string {
        return state.drawTools.menuBar.upload.state;
    }

    /* ------------------- Map Page States -------------- */
    @Selector()  //charts is the default page for dashboard
    static getMapViewState(state: SfStateModel): string {
        return state.sidebarLeft.riskMap.state;
    }

    /* downloads page state, either open or closed  */
    @Selector()
    static getDownloadsPageMenuState(state: SfStateModel): string {
        return state.sidebarLeft.downloads.state;  
    }
    @Selector()
    static getReportsPageMenuState(state: SfStateModel): string {
        return state.sidebarLeft.reports.state;
    }




    /* ------------- Reducers ----------------------------- */
    @Action(ChangeSidebarLeftAction)
    changeSidebarLeft({patchState, getState}: StateContext<SfStateModel>, {newSidebarLeftState}: ChangeSidebarLeftAction): void {
        // getter to get current state from StateContext
        const currentState = getState();

        // new state is currentState with new sidebarLeft
        // sidebarLeft is currentState.sidebarLeft with new "state"
        /* const newState: SfStateModel = {
            ...currentState,
            sidebarLeft: {
                ...currentState.sidebarLeft,
                state: newSidebarLeftState
            }
        } */
        currentState.sidebarLeft.state = newSidebarLeftState;
        patchState(currentState);
    }

    @Action(ToggleDashboardMenuAction)
    toggleDashboardMenu({patchState, getState}: StateContext<SfStateModel>, {newDashboardMenuState}: ToggleDashboardMenuAction): void {
        const currentState = getState();
        // set state of all to closed
        this.closeAll(currentState);
        currentState.sidebarLeft.dashboard.state = newDashboardMenuState;
        patchState(currentState);
    }

    @Action(ToggleDownloadsPageAction)
    toggleDownloadsPage({patchState, getState}: StateContext<SfStateModel>, {newDownloadsPageState}: ToggleDownloadsPageAction): void {
        const currentState = getState();
        // set state of all to closed
        this.closeAll(currentState);
        currentState.sidebarLeft.downloads.state = newDownloadsPageState;
        patchState(currentState);
    }

    @Action(ToggleReportsPageAction)
    toggleReportsPageAction({patchState, getState}: StateContext<SfStateModel>, {newReportsPageState}: ToggleReportsPageAction): void {
        const currentState = getState();
        // set state of all to closed
        this.closeAll(currentState);
        currentState.sidebarLeft.reports.state = newReportsPageState;
        patchState(currentState);
    }

    @Action(TogglePolygonToolAction)
    setPolygonToolState({patchState, getState}: StateContext<SfStateModel>, {newPolygonToolState}: TogglePolygonToolAction): void {
        const currentState = getState();
        //currentState.drawTools.menuBar.polygonTool.state = (currentState.drawTools.menuBar.polygonTool.state === 'selected') ? 'unselected' : 'selected';
        currentState.drawTools.menuBar.polygonTool.state = newPolygonToolState;
        patchState(currentState);
    }

    @Action(ToggleUploadDialogAction)
    setUploadDialogState({patchState, getState}: StateContext<SfStateModel>, {newUploadDialogState}: ToggleUploadDialogAction): void {
        const currentState = getState();
 
       
        currentState.drawTools.menuBar.upload.state = newUploadDialogState;
        patchState(currentState);
    }

    @Action(ToggleLayerManagerAction)
    toggleLayerManager({patchState, getState}: StateContext<SfStateModel>, {newLayerManagementState}: ToggleLayerManagerAction): void {
        const currentState = getState();
        const newState: SfStateModel = {
            ...currentState,
            /* sidebarLeft: {
                ...currentState.sidebarLeft,
                riskMap: {
                    ...currentState.sidebarLeft.riskMap,
                    layers: {
                        state: newLayerManagementState
                    }
                }
            } */
            sidebarRight: {
                ...currentState.sidebarRight,
                layerManager: {
                    state: newLayerManagementState
                }
            }
        }
        patchState(newState);
    }

    @Action(ToggleAreaManagerAction)
    toggleAreaManager({patchState, getState}: StateContext<SfStateModel>, {newAreaManagementState}: ToggleAreaManagerAction): void {
        const currentState = getState();
        currentState.sidebarLeft.riskMap.areas.state = newAreaManagementState;

        patchState(currentState);
    }

    @Action(SetChartsPageMenuAction)
    setChartsPageMenu({patchState, getState}: StateContext<SfStateModel>, {newChartsPageMenuState}: SetChartsPageMenuAction): void {
        const currentState = getState();
        // set state of all to closed
        this.closeAll(currentState);
        currentState.sidebarLeft.dashboard.charts.state = newChartsPageMenuState;
        patchState(currentState);
    }

    @Action(SetUserPageMenuAction)
    setUserPageMenu({patchState, getState}: StateContext<SfStateModel>, {newUserPageMenuState}: SetUserPageMenuAction): void {
        const currentState = getState();
        // set state of all to closed
        this.closeAll(currentState);
        currentState.sidebarLeft.dashboard.users.state = newUserPageMenuState;
        patchState(currentState);
    }

    @Action(ToggleMapMenuAction)
    toggleMapMenu({patchState, getState}: StateContext<SfStateModel>, {newMapMenuState}: ToggleMapMenuAction): void {
        const currentState = getState();

        // set state of all to closed
        this.closeAll(currentState);

        const newState: SfStateModel = {
            ...currentState,
            sidebarLeft: {
                ...currentState.sidebarLeft,
                riskMap: {
                    ...currentState.sidebarLeft.riskMap,
                    state: newMapMenuState
                }
            },
            mapGeoSearch: {
                state: true
            }

        }
        patchState(newState);
    }

    @Action(ChangeSidebarRightAction)
    changeSidebarRight({patchState, getState}: StateContext<SfStateModel>, {newSidebarRightState}: ChangeSidebarRightAction): void {
        // get current state
        const currentState = getState();

        // set new state
        const newState: SfStateModel = {
            ...currentState,
            sidebarRight: {
                state: newSidebarRightState,
                layerManager: {
                    state: currentState.sidebarRight.layerManager.state
                }
            }
        }
        patchState(newState)
    }

    @Action(ChangeBottomDrawerAction)
    changeBottomDrawer({patchState, getState}: StateContext<SfStateModel>, {newBottomDrawerState}: ChangeBottomDrawerAction): void {
        const currentState = getState();
        const newState: SfStateModel = {
            ...currentState,
            bottomDrawer: {
                state: newBottomDrawerState
            }
        }
        patchState(newState)
    }

    @Action(SetSettingsMenuAction)
    setSettingsPage({patchState, getState}: StateContext<SfStateModel>, {newSettingsPageMenuState}: SetSettingsMenuAction): void {
        const currentState = getState();
        this.closeAll(currentState);
        currentState.settings.state = newSettingsPageMenuState;
        patchState(currentState);
    }

    @Action(ToggleLanguageAction)
    setLanguage({patchState, getState}: StateContext<SfStateModel>, {newLanguageState}: ToggleLanguageAction): void {
        const currentState = getState();
        if (currentState.language.state === 'en') {
            currentState.language.state = 'fr'
        } else {
            currentState.language.state = 'en'
        }
        patchState(currentState);
    }

    closeAll(currentState: SfStateModel) {
        
        // unset other menu states
        currentState.sidebarLeft.dashboard.charts.state = 'closed';
        currentState.sidebarLeft.dashboard.users.state = 'closed';
        currentState.sidebarLeft.riskMap.state = 'closed';
        currentState.sidebarLeft.dashboard.state = 'closed';
        currentState.settings.state = 'closed';
        

        const closeRiskMap: ToggleMapMenuAction = new ToggleMapMenuAction('closed')
        //this.store.dispatch(closeRiskMap);
    }

}