import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../../environments/environment";
import { User } from "../../../models/user.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthInterceptorService } from "../../../services/interceptors/auth-interceptor.service";
import { ToastNotificationService } from "../../../services/toast-notification.service";


@Component({
  selector: 'frmg-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  user: User | null = null;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  private baseUrl: string = environment.baseUrl;
  public accountFg: FormGroup;

  constructor(private http: HttpClient, private fb: FormBuilder,
              private authInterceptorService: AuthInterceptorService,
              private toastNotificationService: ToastNotificationService) {
  }

  ngOnInit(): void {
    this.accountFg = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      first_name: ["", [Validators.required]],
      last_name: ["", [Validators.required]]
    })
    this.loadUserData();
  }

  loadUserData(): void {
    const userData = localStorage.getItem('user');

    if (userData) {
      this.user = JSON.parse(userData);
      this.accountFg.setValue({
        first_name: this.user?.first_name || '',
        last_name: this.user?.last_name || '',
        email: this.user?.email || ''
      })
    }
  }


  saveChanges(): void {
    const values= this.accountFg.value;
    this.authInterceptorService.updateCurrentUser(values )
      .subscribe(
        () => {
          // Update the user data in localStorage
          if (this.user) {
            this.user.first_name = values.first_name;
            this.user.last_name = values.last_name;
            this.user.email = values.email;
            localStorage.setItem('user', JSON.stringify(this.user));
          }
          this.toastNotificationService.showNotification({type: "success", message: "User profile updated Successfully"});
          this.accountFg.markAsPristine();
        },
        (error) => {
          this.toastNotificationService.showNotification({type: "error", message: "Error updating profile"});
        }
      );
  }
}