<div class="complete-draw-container">
    <div class="id-label">
        <div class="label-part">
            New area id: 
        </div>
        <div class="id-part">
            {{data.myAreaId}}
        </div>
    </div>

    <div class="name-input">
        <mat-form-field class="save-dialog-size">
            <mat-label>Area name</mat-label>
            <div class="search-input">
                <input matInput [(ngModel)]="areaName" placeholder="Enter area name">
            </div>
        </mat-form-field>

    </div>

    <div class="description-area">
        <mat-form-field class="description" appearance="fill">
            <mat-label>Description</mat-label>
            <textarea matInput [(ngModel)]="description" rows="4" maxlength="150"></textarea>
            <mat-hint align="end">{{ description.length }} / 150 characters</mat-hint>
            <!-- <mat-error *ngIf="description.hasError('required')">Description is required</mat-error>
            <mat-error *ngIf="description.hasError('maxlength')">Description cannot exceed 150 characters</mat-error> -->
        </mat-form-field>
    </div>

    <div class="button-row">
        <div class="save-button">
            <button mat-raised-button color="primary" class="save">Save</button>
        </div>
        <div class="cancel-button">
            <button mat-raised-button class="clear" (click)="closeDialog()">Cancel</button>
        </div>
        
        
    </div>
</div>
