import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from "./user.service";
import { Location } from "../models/location.model";


@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  public getLocations(search: string | Location): Observable<Array<Location>> {
    if (typeof search !== "string") {
      search = search.formatted_address || search
    }
    let endpoint = `${this.baseUrl}/${this.userService.mySlug}/location/search/?place=${search}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<{ locations: Array<Location> }>(endpoint, {headers}).pipe(map(resp => resp.locations));
  }
}
