<frmg-main-vertical-menubar-left
        [showNotification]="showNotification"
        (showNotificationChange)="showNotificationChange($event)"
        [totalUnreadNotifications]="totalUnreadNotifications"
>

</frmg-main-vertical-menubar-left>


<div class="load-file" *ngIf="false">
    <frmg-uploader></frmg-uploader>
</div>
<ng-container *ngIf="showNotification">
    <frmg-notification-list [notificationList]="notificationsArray"
                            (hideNotificationsEmitter)="hideNotification()"></frmg-notification-list>
    <div class="overlay" (click)="hideNotification()"></div>
</ng-container>


<!-- -->
<router-outlet></router-outlet>

<!----------------- bottom data drawer ------------->
<frmg-bottom-drawer
        *ngIf="false"
        [heightOpen]="250"
        [opacityOpen]="1"
        [colorOpen]="'rgba(255, 255, 255, 1)'"
        [bottomOpen]="0"
        [heightClosed]="250"
        [opacityClosed]="1"
        [colorClosed]="'rgba(255, 255, 255, 1)'"
        [bottomClosed]="-250"
        [widthPixels]="400"
        [offsetLeft]="'83px'">

</frmg-bottom-drawer>

<!-- <frmg-menu-bottom-right>

</frmg-menu-bottom-right> -->
<!-- [widthPercent]="85" -->

<ng-container *ngIf="newNotifications().length">
    <div class="notification-container ">
        <div class="dismiss-btn" *ngIf="newNotifications().length > 1" (click)="dismissAll()">
            <span>Dismiss All</span></div>
        <div [ngClass]="{'cascade' : newNotifications().length > 3}">
            <ng-container *ngFor="let popUp of newNotifications(); let $index = index;">
                <div class="notification-element" *ngIf="!popUp.read">
                    <div>
                        <div class="notification-icon">
                            <div class="notification-type" [innerHTML]="getCategoryType(popUp)"></div>
                            <div class="notification-sub-category">
                                <span [ngClass]="getSubCategoryType(popUp)"></span>
                            </div>
                        </div>
                        <div class="notification-body" [innerHTML]="notificationMsgTransform(popUp.message)"></div>
                        <div class="notification-action icon-close" (click)="dismissNotification(popUp)"></div>
                    </div>
                    <div class="notification-more"
                         *ngIf="newNotifications().length > 3 && $index == 0">{{ newNotifications().length - 1 }}
                        more notifications
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="notificationService.DownloadsArray.length">

    <div class="notification-container ">
        <mat-accordion>
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="downloads-title">Downloading files ({{notificationService.DownloadsArray.length}}) </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="horizontal-separator"></div>
                <ng-container *ngFor="let popUp of notificationService.DownloadsArray;">
                    <div class="download-element">
                        <div class="download-title">
                            <div>{{popUp.fileName}}</div>
                            <div>{{popUp.percentage}}%</div>
                        </div>
                        <mat-progress-bar [value]="popUp.percentage"></mat-progress-bar>
                        <div class="download-info">
                            <div>Downloading...</div>
                            <div> <span >{{(popUp.loaded/1000).toFixed(2)}} KB</span> / <span>{{(popUp.total/1000).toFixed(2)}} KB</span></div>
                        </div>
                    </div>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-container>