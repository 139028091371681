<div class="alert-container">
    <div class="lock-logo">
        <img src="assets/icons/password-created.svg" alt="" width="64px" height="64px">
    </div>
    <div class="alert-header">
        {{reset ? "Password reset successfully": "Password created"}}
    </div>
    <div class="alert-description">
        You can now login with your new password.
    </div>
    <div class="alert-button">
        <div class="login-button">
            <button mat-raised-button color="primary" (click)="goToLogin()" class="frmg-primary-button">Login</button>
        </div>
    </div>
</div>
