<mat-form-field appearance="outline">
    <div class="icon-search-icon" matPrefix ></div>
    <input
            type="text"
            class="wider-options"
            placeholder="Search by location"
            matInput
            [formControl]="locationSearchCtrl"
            [matAutocomplete]="auto">
    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="goToLocation($event)"
        [displayWith]="displayAddress"
        panelWidth="250px"
        class="address-search-options">
        <div class="horizontal-separator"></div>
        <mat-option *ngFor="let option of locations$ | async" [value]="option" >
            {{ option.formatted_address }}
        </mat-option>
    </mat-autocomplete>
    <div class="icon-close" matSuffix (click)="clearSearch()" *ngIf="locationSearchCtrl.value"></div>
</mat-form-field>