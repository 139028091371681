import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'frmg-password-created',
  templateUrl: './password-created.component.html',
  styleUrls: ['./password-created.component.scss']
})
export class PasswordCreatedComponent {
  @Input() reset: boolean = false;
  constructor(
    private router: Router
  ) {

  }

  goToLogin(): void {
    this.router.navigate(['login']);
  }
}
