<div class="header">
    <span>Filters</span>
    <span class="icon-close"
          (click)="closeMenu.emit()" matTooltip="Close"></span>
</div>
<div class="horizontal-separator"></div>
<div class="filter-block">
    <span class="filter-title">Sort By</span>
    <mat-radio-group class="radio-group" [(ngModel)]="sort">
        <mat-radio-button value="name">Name</mat-radio-button>
        <mat-radio-button value="created_at">Date Created</mat-radio-button>
        <mat-radio-button value="area_in_sqm">Area Size</mat-radio-button>
    </mat-radio-group>
</div>
<div class="filter-block">
    <span class="filter-title">Filter by tag</span>
    <mat-form-field appearance="outline" >
        <mat-select [formControl]="tagsControl" [multiple]="true" placeholder="Select by">
            <mat-option *ngFor="let tag of tagsList" [value]="tag">#{{tag.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="action_container">
        <button mat-raised-button class="frmg-secondary-button"
                (click)="clear()">Clear</button>
    <button mat-raised-button class="frmg-primary-button"
            (click)="apply()">Apply
    </button>
</div>