import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Team } from '../models/team.model';
import { BaseApiService } from './base-api.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { InviteAccept, InviteResponse } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseApiService {

  // todo get baseurl from env
  //private baseUrl = 'http://localhost:8000/a/ninjas/';
  private teamsBaseUrl = 'http://localhost:8000/teams/api/teams/';
  constructor(
    http: HttpClient,
    router: Router,
    private userService: UserService
  ) {
    super(http, router)
  }

  // todo define model for slug response type
  /*
    return a team object with slugId
  */
  getUsersInSlug(slugId: number): Observable<Team> {
    const id = this.userService.myId;
    const resource = 'teams/api/teams'
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    const endpoint = `${this.baseUrl}/${resource}/${id}/`
    return this.http.get<Team>(endpoint, { headers });
  }

  retrieveUserId(hash: string): Observable<InviteAccept> {
    // make sure no outstanding tokens
    this.logout(false);

    const resource = 'teams/invitations/accept';
    const endpoint = `${this.baseUrl}/${resource}/${hash}`;

    return this.http.get<InviteAccept>(endpoint);
  }

  acceptInvitation(email: string, hash: string, password: string): Observable<HttpResponse<InviteResponse>> {
    const resource = 'teams/invitations/accept';
    const endpoint = `${this.baseUrl}/${resource}/${hash}/`;
    const body = {
      "email": email,
      "password": password
    }

    return this.http.post<InviteResponse>(endpoint, body, { observe: 'response'})
  }

  logout(routeToLogin: boolean = true): void {
    this.userService.clearAuthData();

    if (routeToLogin === true) {
      this.router.navigate(['/login']);
    }
    
  }
}
