import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { Layer } from "../../models/layer.models";

interface Selectedlayer {
  active: boolean,
  opacity: number,
  layer: Layer
}

@Injectable({
  providedIn: 'root'
})
export class MapCommunicatorService  {


  public mapCommunicator = new BehaviorSubject<string>('');
  public selectedAreas = new ReplaySubject<string[]>()
  public layers = new Subject<Array<Selectedlayer>>();
  public baseMap: string = 'topographic';

  constructor() {
  }

}

