<div class="mat-typography">
    <div class="dialog-header">
        Share {{ entityName }}
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        <div class="search_bar">
            <mat-form-field appearance="outline">
                <input
                        type="text"
                        class="wider-options"
                        placeholder="Search by email"
                        matInput
                        [formControl]="userSearchCtrl"
                        [matAutocomplete]="auto"
                >
                <mat-autocomplete
                        [displayWith]="displayName"
                        #auto="matAutocomplete">
                    <mat-option *ngFor="let user of users$ | async" [value]="user">
                        {{ user.email }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <button mat-button class="frmg-primary-button"
            (click)="share()">Share
            </button>
        </div>
        <p class="input-label">People with access</p>
        <div class="shared_users">
            <div class="user_container" *ngFor="let user of sharedUsers; let $index = index;">
                <frmg-user-logo [user]="user"></frmg-user-logo>
                <div class="info">
                    <div class="email">{{user.email}}</div>
                    <div class="name">{{user.first_name}}  {{user.last_name}}</div>
                </div>
                <button *ngIf="$index !== 0" mat-button
                        class="frmg-secondary-button" (click)="revoke($index, user)">Revoke
                </button>
                <div class="owner" *ngIf="$index == 0">Owner</div>
            </div>
        </div>
    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close class="frmg-primary-button">Done
    </button>
</div>
