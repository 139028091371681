<div class="area-list-container" [@slideInOut]="isOpen">
    <frmg-area-list *ngIf="isOpen === 'areas'" (projectSelect)="projectSelect($event)" (onDestroyCallback)="saveListState($event)"
    [initState]="savedState"/>
    <frmg-layer-sidebar *ngIf="isOpen === 'layers'"/>
    <div class="area-actions">
        <div class="open-close-handle" [ngClass]="{'open': isOpen === 'out'}" (click)="toggle('areas')"
             matTooltip="My areas" matTooltipPosition="right">
            <div class="action-button">
                <span class="icon-project"
                      [ngClass]="{'active': isOpen === 'areas'}"> </span>
            </div>
        </div>
        <div class="open-close-handle" [ngClass]="{'open': isOpen === 'out'}" (click)="toggle('layers')"
             matTooltip="My layers" matTooltipPosition="right">
            <div class="action-button">
                <span class="icon-Layers"
                      [ngClass]="{'active': isOpen === 'layers'}"> </span>
            </div>
        </div>
    </div>
</div>
<div class="map-controls">
    <div class="controls-group">
        <div class="draw-tools-handle">
            <div class="action-button" (click)="openDrawTools()" matTooltip="Drawing mode" matTooltipPosition="left">
            <span [class]="'icon-'+currentToolIcon" *ngIf="currentToolIcon !== 'point'"
                  [ngClass]="{'active': action.includes('draw')}"> </span>
                <span [class]="'icon-'+currentToolIcon" *ngIf="currentToolIcon === 'point'"
                      [ngClass]="{'active': action.includes('draw')}">
                        <span class="path1" [ngClass]="{'active': action.includes('draw')}"></span>
                        <span class="path2" [ngClass]="{'active': action.includes('draw')}"></span>
                        <span class="path3" [ngClass]="{'active': action.includes('draw')}"></span>
                    </span>
            </div>
            <div class="draw-tools draw-tools-opened" [ngClass]="{'draw-tools-opened': action === 'drawTool'}">
                <div class="action-button" *ngFor="let tool of drawTools" (click)="setDrawTool(tool)"
                     [matTooltip]="capitalizeFirstLetter(tool)" matTooltipPosition="above">
                <span [class]="'icon-'+tool" *ngIf="tool !== 'point'"
                      [ngClass]="{'active': currentToolIcon === tool && drawing}"> </span>
                    <span [class]="'icon-'+tool" *ngIf="tool === 'point'"
                          [ngClass]="{'active': currentToolIcon === tool && drawing}">
                        <span class="path1" [ngClass]="{'active': currentToolIcon === tool && drawing}"></span>
                        <span class="path2" [ngClass]="{'active': currentToolIcon === tool && drawing}"></span>
                        <span class="path3" [ngClass]="{'active': currentToolIcon === tool && drawing}"></span>
                    </span>
                </div>
            </div>
        </div>
        <div class="horizontal-separator"></div>
        <div class="action-button" (click)="selectionState()" matTooltip="Select Mode" matTooltipPosition="left">
            <span class="icon-Mouse" [ngClass]="{'active': action.includes('selection')}"></span>
        </div>
    </div>

    <div class="control-separator"></div>
    <div class="controls-group">
        <div class="action-button" (click)="saveDrawnAreas()" matTooltip="Save drawn area"
             [ngClass]="{'disabled': !currentDrawn}"
             matTooltipPosition="left" [matTooltipDisabled]="!currentDrawn">
            <span class="icon-Save"></span>
        </div>
        <div class="horizontal-separator"></div>
        <div class="action-button" (click)="clearDrawnAreas()" matTooltip="Clear drawn area"
             [ngClass]="{'disabled': !currentDrawn}" matTooltipPosition="left"
             [matTooltipDisabled]="!currentDrawn">
            <span class="icon-Clear"></span>
        </div>
    </div>


    <div class="control-separator"></div>
    <div class="controls-group">
        <div class="action-button" (click)="zoomMap('in')" matTooltip="Zoom in" matTooltipPosition="left">
            <span class="icon-zoom-in"></span>
        </div>
        <div class="horizontal-separator"></div>
        <div class="action-button" (click)="zoomMap('out')" matTooltip="Zoom out" matTooltipPosition="left">
            <span class="icon-zoom-out"></span>
        </div>
    </div>

</div>

<frmg-address-search (mapDrawEvent)="onDrawEnd($event)"></frmg-address-search>
<frmg-map [ngClass]="{'map-open': isOpen !== 'out'}" (mapDrawEvent)="onDrawEnd($event)"/>