import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { MapService } from 'src/app/services/map.service';
import { SettingsService } from 'src/app/services/settings.service';
import { UserService } from 'src/app/services/user.service';
import { SetSettingsMenuAction, ToggleAreaManagerAction, ToggleLanguageAction } from 'src/app/store/sf.actions';
import { SfState } from 'src/app/store/sf.state';
import { Router } from "@angular/router";

@Component({
  selector: 'frmg-main-vertical-menubar-left',
  templateUrl: './main-vertical-menubar-left.component.html',
  styleUrls: ['./main-vertical-menubar-left.component.scss']
})
export class MainVerticalMenubarLeftComponent implements OnInit, OnDestroy {

  @Output() toggleRequest: EventEmitter<string> = new EventEmitter<string>();
  @Select(SfState.getSidebarLeftState) leftSidebarStateO$!: Observable<string>;
  @Select(SfState.getLanguageState) languageStateO$!: Observable<string>;

  @Input() showNotification!: boolean;
  @Input() totalUnreadNotifications!: number;
  @Output() showNotificationChange = new EventEmitter<boolean>();


  constructor(
    private cd: ChangeDetectorRef,
    public router: Router,
    private settingsService: SettingsService
  ) {
    
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    
  }

  public navigate(url: string) {
    if (this.showNotification) {
      this.hideNotification()
    }
    this.router.navigate([`/${url}`]);
  }


  openNotifications(): void {
    this.showNotification = !this.showNotification;
    this.showNotificationChange.emit(this.showNotification)
    this.cd.detectChanges();
  }

  hideNotification() {
    if (this.showNotification) {
      this.openNotifications();
    }
  }

  logout(): void {
    this.showNotification = false;
    this.showNotificationChange.emit(this.showNotification)
    this.settingsService.logout();
  }

}
