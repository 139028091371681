<div class="download-header">
    <p>
        {{ "downloads.my-downloads" | translate }}
    </p>
</div>

<div class="download-summary">
    <div class="summary-header">
        {{ "downloads.request-stats" | translate }}
    </div>
    <div class="summary-container">
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: statistic.completed,
        title: 'downloads.processed',
        icon: 'new_releases'
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: statistic.started,
        title: 'downloads.processing',
        icon: 'progress_activity'
        }">
        </ng-template>
<!--        <ng-template [ngTemplateOutlet]="summaryCard"-->
<!--                     [ngTemplateOutletContext]="{-->
<!--        value: statistic.cancelled,-->
<!--        title: 'downloads.canceled',-->
<!--        icon: 'hide_source'-->
<!--        }">-->
<!--        </ng-template>-->
<!--        <ng-template [ngTemplateOutlet]="summaryCard"-->
<!--                     [ngTemplateOutletContext]="{-->
<!--        value: statistic.failed,-->
<!--        title: 'downloads.expired',-->
<!--        icon: 'timer_off'-->
<!--        }"></ng-template>-->

    </div>
</div>

<div class="download-table">
    <div class="table-header">
        {{ "downloads.history" | translate }}
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="taskData" class="mat-elevation-z8" matSort>
            <ng-container matColumnDef="area">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{"downloads.table.area" | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element"
                          [matTooltip]="element.area ? element.area.name: element.task_id">
                    {{element.area ? truncate(element.area.name, 50): element.task_id}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{"downloads.table.created_at" | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at | date: "dd/M/yyyy, HH:mm"}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="layer">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{"downloads.table.layer" | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.layer && element.layer.display_name}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{"downloads.table.status" | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <div class="status-block">
                        <span [ngClass]="statusClass(element)"></span>
                        <span translate>{{taskStatusTranslations.get(element.status)}}</span>
                    </div>

                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-raised-button *ngIf="element.url" class="frmg-primary-button"
                            (click)="download(element.url)">
                        <span class="material-symbols-outlined action-icon">download</span> Download</button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>
    </div>
    <mat-divider ></mat-divider>
    <mat-paginator
                   [length]="totalElements"
                   [pageIndex]="currentPage-1"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[50, 100, 15]"
                   [showFirstLastButtons]="true"
                   (page)="handlePageEvent($event)"
    ></mat-paginator>

</div>

<ng-template #summaryCard let-value="value" let-title="title" let-icon="icon">
    <div class="summary-card">
        <div class="card-info">
            <p class="card-reading">{{ value }}</p>
            <p class="card-title">{{ title | translate }}</p>
        </div>
        <div class="card-icon">
            <span class="material-symbols-outlined">
                {{ icon }}
            </span>
        </div>
    </div>
</ng-template>
