import { Component, Input, OnInit } from '@angular/core';
import { User } from "../../models/user.model";

@Component({
  selector: 'frmg-user-logo',
  templateUrl: './user-logo.component.html',
  styleUrls: ['./user-logo.component.scss']
})
export class UserLogoComponent implements OnInit {

  @Input() user: User;

  public initials: string

  constructor() {
  }

  ngOnInit(): void {
    this.initials = this.user.email.charAt(0);
    if (this.initials === '+') this.initials += this.user.email.charAt(1);
  }

}
