<form [formGroup]="loginFormGroup" (ngSubmit)="login()" *ngIf="state === 'login'">
    <div class="form-content">
        <span class="login-header">Login to your account</span>
        <div class="error-block" *ngIf="loginError">
            <span>Invalid credentials</span>
            <span class="icon-close" (click)="hideError()"></span>
        </div>
        <div>
            <span class="input-label">Email</span>
            <mat-form-field appearance="outline">
                <input matInput formControlName="email" placeholder="Email" required>
            </mat-form-field>
        </div>
        <div>
            <span class="input-label">Password</span>
            <mat-form-field appearance="outline">
                <input matInput formControlName="password" placeholder="Password" required
                       [type]="hide ? 'password' : 'text'">
                <span [ngClass]="!hide ? 'icon-eye' : 'icon-eye-closed'"
                      matSuffix
                      (click)="togglePassword($event)"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide"></span>
            </mat-form-field>
        </div>
        <span class="pass-forgot" (click)="forgot()">Forgot password?</span>
    </div>
    <div class="login-button">
        <button mat-raised-button color="primary" type="submit" class="frmg-primary-button" [disabled]="loginFormGroup.dirty && loginFormGroup.invalid">Login</button>
    </div>
</form>

<form *ngIf="state === 'reset'" [formGroup]="resetFormGroup" (ngSubmit)="reset()">
    <div class="form-content">
        <span class="login-header">{{resetSuccess? "Check your Email" : "Reset my password"}}</span>
        <div class="login-subheader">{{resetSuccess? resetSubHeaderSuccess() : "Enter your email so we can send you instructions on how to reset your password."}}
            </div>
        <div class="error-block" *ngIf="loginError">
            <span>Invalid credentials</span>
            <span class="icon-close" (click)="hideError()"></span>
        </div>
        <div *ngIf="!resetSuccess">
            <span class="input-label">Email</span>
            <mat-form-field appearance="outline">
                <input matInput formControlName="email" placeholder="Email" required>
            </mat-form-field>
        </div>
    </div>
    <div class="login-button">
        <button mat-raised-button color="primary" type="submit" class="frmg-primary-button"
                [disabled]="resetFormGroup.invalid" >{{resetSuccess? "Resend Email" : "Reset Password"}}</button>
        <button mat-raised-button class="frmg-secondary-button" (click)="backToLogin()">Back to Login</button>
    </div>
</form>
<div class="slide">
    <span>Welcome to Skyforest!</span>
    <span>Cloud-based technology providing rapid risk analytics for industry and government to assess wildfire exposure</span>
</div>