import { Component } from '@angular/core';

@Component({
  selector: 'frmg-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss']
})
export class DashboardHomeComponent {

}
