import { Component, OnInit } from '@angular/core';
import { AuthInterceptorService } from "../../../services/interceptors/auth-interceptor.service";
import { ToastNotificationService } from "../../../services/toast-notification.service";
import { BillingInfo, UserUsage } from "../../../models/team.model";


@Component({
  selector: 'frmg-plan-usage',
  templateUrl: './plan_usage.component.html',
  styleUrls: ['./plan_usage.component.scss']
})
export class PlanUsageComponent implements OnInit {
  public billingInfo: BillingInfo;
  public billingInfoTable: Array<BillingInfo>;
  public usersData: Array<UserUsage>;
  public displayedColumns= ['aoi_size_limit_km2', 'download_request_limit', 'report_size_limit_km2', 'report_request_limit', 'max_aois_per_report']
  public displayedColumnsUser = ['user__email', 'aoi_size', 'download_requests', 'report_requests' , 'report_size'];
  constructor(private authInterceptorService: AuthInterceptorService,
              private toastNotificationService: ToastNotificationService) {
  }

  ngOnInit(): void {
    this.loadBillingData();
  }

  numberWithSeparator(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  loadBillingData(): void {
    this.authInterceptorService.getBillingInfo().subscribe(resp=>{
      this.billingInfo = resp
      this.billingInfoTable = [resp]
      this.usersData = resp.users_usage;
    })
  }
}