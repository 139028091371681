import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../../environments/environment";
import { ToastNotificationService } from "../../../services/toast-notification.service";
import { ConfirmationDialogComponent } from "../../../components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

interface ApiKeyResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: ApiKey[];
}

interface ApiKey {
  id: string;
  name: string;
  created: string;
  revoked: boolean;
  expiry_date: string;
  has_expired: boolean;
}

@Component({
  selector: 'frmg-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss']
})
export class ApiKeysComponent implements OnInit {
  apiKeys: ApiKey[] = [];
  private baseUrl = environment.baseUrl

  constructor(private http: HttpClient,
              private toastNotificationService: ToastNotificationService,
              private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getApiKeys();
  }

  getApiKeys(): void {
    this.http.get<ApiKeyResponse>(this.baseUrl + '/users/api-keys/list/')
      .subscribe(
        (response) => {
          this.apiKeys = response.results;
        },
        (error) => {
          this.toastNotificationService.showNotification({type: "error", message: 'Error fetching API keys'})
          // alert('An error occurred while fetching API keys.');
        }
      );
  }

  createApiKey(): void {
    this.http.post(this.baseUrl + '/users/api-keys/create/', {})
      .subscribe(
        () => {
          this.getApiKeys();
        },
        (error) => {
          this.toastNotificationService.showNotification({type: "error", message: 'Error creating API keys'})
        }
      );
  }

  revokeApiKey(keyId: string): void {
    this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, {
      data: {
        header: 'Are you sure you want to revoke this API key?',
        confirmText: 'Yes, revoke this API key'
      }
    }).afterClosed().subscribe(del => {
      if (del) {
        const payload = { key_id: keyId };
        this.http.post(this.baseUrl + '/users/api-keys/revoke/', payload)
          .subscribe(
            () => {
              this.toastNotificationService.showNotification({type: "success", message: 'API key revoked successfully.'})
              this.getApiKeys();
            },
            (error) => {
              this.toastNotificationService.showNotification({type: "error", message: 'Error revoking API key'})
            }
          );
      }
    })
  }

  copyToClipboard(apiKeyId: string): void {
    const tempInput = document.createElement('input');
    tempInput.value = apiKeyId;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.toastNotificationService.showNotification({type: "info", message: 'API key copied to clipboard.'})
  }
}