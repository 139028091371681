<div class="charts-container">
    <!-- <div class="header-row">
        <div class="header-label">
            <div class="placeholder" style="width: 100%; height: 100%">
                Dashboard Header
            </div>
        </div>
        <div class="time-range-filter">
            <div class="placeholder" style="height: 100%">
                Time Filter
            </div>
        </div>
    </div> -->
    <router-outlet></router-outlet>
    <div class="charts-row">
  
        <div class="data-left-col">
            <div class="summary-data">
                <div class="placeholder" style="height: 125px">
                    Summary Data
                </div>
            </div>
            <div class="line-chart">
                <div class="placeholder" style="height: 280px">
                    Line Chart
                </div>
            </div>
        </div>
        <div class="data-right-col">
            <div class="placeholder" style="height: 100%">
                Doughnut Chart
            </div>
        </div>

    </div>

    <div class="download-table-row">
        <div class="placeholder" style="height: 600px">
            Download Table
