<div class="header">
    <span>Upload file</span>
    <span class="icon-close"
          (click)="closeMenu.emit()" matTooltip="Close"></span>
</div>
<div class="horizontal-separator"></div>
<div class="upload-container" appDragDrop (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target)"
           accept=".json, .kml, geojson, .xlsx, .csv"/>
    <img src="assets/icons/upload-file-icon.svg" alt="file-icon" class="upload-file-icon">
    <div class="panel-header">Drag and drop or
        <br/><a for="fileDropRef">Browse</a> your file
    </div>
    <div class="panel-sub-header">
        Accepted file types: KML, GeoJSON, XLSX, CSV
    </div>

</div>
<div class="files-list" *ngIf="files.length">
    <span >
        Uploading.
    </span>
    <div class="single-file" *ngFor="let file of files; let i = index">
        <img src="assets/icons/file-icon.svg" alt="file-icon" class="file-icon">
        <div class="info">
            <div class="name">
                {{ file?.name }}
            </div>
            <p class="status">
                {{ file.status }}
            </p>
        </div>
        <span class="icon-Undo" matTooltip="Processing"
              *ngIf="file.status?.toLowerCase().includes('processing')"></span>
        <span class="{{file.uploaded ? 'icon-Remove': 'icon-close'}}" (click)="deleteFile(i)" matTooltip="Remove"
              *ngIf="!file.status?.toLowerCase().includes('processing')"></span>
    </div>
</div>
<ng-container *ngIf="filesToSend(files)">
    <div class="horizontal-separator"></div>
    <div class="action_container">
        <!--    <button mat-raised-button class="frmg-secondary-button"-->
        <!--            (click)="clearAll()">Clear All</button>-->
        <button mat-raised-button class="frmg-primary-button"
                (click)="upload()">Upload files
        </button>
    </div>
</ng-container>
