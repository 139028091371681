import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationMsg } from "../../../../models/general.models";
import { NotificationService } from "../../../../services/notification.service";
import moment from "moment";

@Component({
  selector: 'frmg-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent {
  @Input() notificationList: Array<NotificationMsg>;
  @Output() hideNotificationsEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private notificationService: NotificationService) {
  }


  public groupedNotification(): Array<{ date: string, notifications: Array<NotificationMsg> }> {
    const groups = this.notificationList.reduce((groups, game) => {
      let date = game.created_at.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(game);
      return groups;
    }, {});
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        notifications: groups[date].sort((a: NotificationMsg, b: NotificationMsg) => -moment(a.created_at).diff(moment(b.created_at)))
      };
    }).sort((a, b) => moment(a.date).diff(moment(b.date)));
    return groupArrays.reverse();
  }

  public convertGroupDate(date: string) {
    const momentDate = moment(date);
    date = momentDate.format("MMMM DD")
    const isTodayDate = moment(new Date()).format("MMMM DD") == date;
    if (isTodayDate) {
      date = 'Today';
    } else {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const isYesterdayDate = moment(yesterday).format("MMMM DD") == date;
      if (isYesterdayDate) {
        date = 'Yesterday'
      }
    }

    return date;
  }


  public convertDate(date: string): string {
    const momentDate = moment(date);
    date = momentDate.format("HH:mm")
    return date
  }

  public notificationMsgTransform(str: string) {
    const splitBy = str.split(' by ');
    if (splitBy.length > 1) {
      str = str.replace(splitBy[1], `<span class="bold-name">${splitBy[1]}</span>`)
    }
    const regex = /'([^']+)'/g;
    return str.replace(regex, (match, p1) => `<span class="bold-name">${p1}</span>`);
  }

  public readNotification(notification: NotificationMsg) {
    notification.read = true;
    this.notificationService.readNotification(notification).subscribe(_ => {
      this.notificationService.updateNotificationById(notification)
    })
  }

  public readAllUnread() {
    this.notificationList.forEach(notification => {
      if (!notification.read) {
        this.readNotification(notification)
      }
    })
  }

  public haveUnreadNotifications() {
    return this.notificationList.some(notification => !notification.read)
  }

  public hideNotifications() {
    this.hideNotificationsEmitter.emit(true)
  }

  public getSubCategoryType = this.notificationService.getSubCategoryType;
  public getCategoryType = this.notificationService.getCategoryType;
}
