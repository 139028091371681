import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AreaService } from "../../../../services/area.service";

@Component({
  selector: 'frmg-delete-area-dialog',
  templateUrl: './delete-area-dialog.component.html',
  styleUrls: ['./delete-area-dialog.component.scss']
})
export class DeleteAreaDialogComponent  {
  public isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteAreaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private areaService: AreaService) {
  }


  public closeDialog(result: any): void {
    this.dialogRef.close(result);
  }

  public delete(): void {
    this.isLoading = true;
    this.areaService.deleteArea(this.data.areas).subscribe(resp=>{
      this.closeDialog('deleted');
    })
  }
}
