<div class="invite-container" *ngIf="!passwordCreatedSuccess">
    <div class="invite-title">
        <div class="invite-header">
            You've been invited to use Skyforest
        </div>
        <div class="invite-description">
            Please create your password to start using the platform.
        </div>
    </div>
    <div class="invite-form">
        <form class="form-container">
            <div class="error-block" *ngIf="error">
                <span>Invalid credentials</span>
                <span class="icon-close" (click)="hideError()"></span>
            </div>
            <div class="password-title">
                Create Password
            </div>
            <div class="password-field">
                <mat-label>Password</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="password" placeholder="Password" required
                           [type]="hidePassword ? 'password' : 'text'">
                    <span [ngClass]="!hidePassword ? 'icon-eye' : 'icon-eye-closed'"
                          matSuffix
                          (click)="togglePasswordVisibility()"
                          [attr.aria-label]="'Hide password'"
                          [attr.aria-pressed]="hidePassword"></span>
                </mat-form-field>
            </div>

            <div class="password-field">
                <mat-label>Confirm Password</mat-label>
                <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="confirmPassword" placeholder="Password" required
                           [type]="hidePassword ? 'password' : 'text'">
                    <span [ngClass]="!hidePassword ? 'icon-eye' : 'icon-eye-closed'"
                          matSuffix
                          (click)="togglePasswordVisibility()"
                          [attr.aria-label]="'Hide password'"
                          [attr.aria-pressed]="hidePassword"></span>
                </mat-form-field>
            </div>

            <div class="submit-button">
                <button mat-raised-button color="primary" (click)="createPassword()"
                        class="frmg-primary-button" [disabled]="password && password !== confirmPassword" >
                    Create Password
                </button>
            </div>
        </form>
    </div>
</div>
<frmg-password-created *ngIf="passwordCreatedSuccess">

</frmg-password-created>
