import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PagedData } from "../models/general.models";
import { Task, TaskStatistic } from "../models/tasks.model";
import { UserService } from "./user.service";


@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  public getTasks(pageNumber: number, pageSize: number): Observable<PagedData<Task>> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/tasks/?page=${pageNumber}&limit=${pageSize}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<PagedData<Task>>(endpoint, {headers})
  }

  public getTasksStatus(): Observable<TaskStatistic> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/tasks/status-counts/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<TaskStatistic>(endpoint, {headers})
  }

  public getTaskStatus(id: string): Observable<Task> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/tasks/status/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<Task>(endpoint, {headers})
  }
}
