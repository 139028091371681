import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthInterceptorService, TokenResponse } from '../../../services/interceptors/auth-interceptor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'frmg-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent {
  public loginFormGroup: FormGroup;
  public resetFormGroup: FormGroup;
  public hide: boolean = true;
  public loginError: boolean = false;
  public state: string = 'login';
  public resetSuccess: boolean = false;

  constructor(private authInterceptorService: AuthInterceptorService,
              private router: Router,
              private fb: FormBuilder) {
    this.loginFormGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  togglePassword(event: MouseEvent) {
    this.hide = !this.hide;
    event.stopPropagation();
  }

  login() {
    if (this.loginFormGroup.valid ) {
      const value = this.loginFormGroup.value;
      this.authInterceptorService.getTokens(value.email, value.password).subscribe( () => {
         this.router.navigate(['/risk-map']);
        },error => {
        this.loginError = true;
      });
    }
  }

  hideError() {
    this.loginError = false
  }

  forgot() {
    this.resetFormGroup =  this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.state = 'reset'
  }

  reset() {
    this.authInterceptorService.resetPassword(this.resetFormGroup.value.email).subscribe((re)=>{
     this.resetSuccess = true;
    },error => {
      this.loginError = true;
    })
  }

  backToLogin() {
    this.state = 'login';
    this.resetSuccess = false;
  }

  resetSubHeaderSuccess() {
    let email = this.resetFormGroup.value.email;
    email = email.split('');
    let finalArr=[];
    let len = email.indexOf('@');
    email.forEach((item,pos)=>{
      (pos>=1 && pos<=len-2) ? finalArr.push('*') : finalArr.push(email[pos]);
    })
    return `We have sent an email with password reset information to ${finalArr.join('')}.`;
  }
}