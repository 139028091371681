import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, share } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from "./user.service";
import { Layer } from "../models/layer.models";


@Injectable({
  providedIn: 'root'
})
export class LayerService {

  private baseUrl: string = environment.baseUrl;

  private layerObservable: ReplaySubject<Array<Layer>>;

  private layersState: {[key: string]: { active: boolean, opacity: number, layer: Layer } } = {};

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  public getLayers(): Observable<Array<Layer>> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/layers/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    if (!this.layerObservable) {
      this.layerObservable = new ReplaySubject<Array<Layer>>();
        this.http.get<{ results: Array<Layer> }>(endpoint, {headers}).pipe(
        map(response => response.results)).subscribe(resp=>{
          this.layerObservable.next(resp)
      });
    }
    return this.layerObservable;
  }

  public setSavedLayers(layers: {[key: string]: { active: boolean, opacity: number, layer: Layer } }): void {
    this.layersState = layers;
  }

  public getSavedLayers():{[key: string]: { active: boolean, opacity: number, layer: Layer } } {
    return this.layersState;
  }
  public generateLayers (area_ids: string, layer_ids: string, output_types : string): Observable<any> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/layers/generate/`;
    const team_slug = this.userService.mySlug;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.post<any>(endpoint,{area_ids, layer_ids , output_types , team_slug}, {headers})
  }
}
