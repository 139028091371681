import { User } from "./user.model";
import { Team } from "./team.model";

export interface PagedData<T> {
  count: number,
  next?: string,
  previous?: string,
  results: Array<T>
}


export interface Area {
  geometry: Geometry,
  properties: AreaProperties
  type?: string,
}

export type GeometryTypes =
  'Point'
  | 'LineString'
  | 'LinearRing'
  | 'Polygon'
  | 'MultiPoint'
  | 'MultiLineString'
  | 'MultiPolygon'
  | 'GeometryCollection'
  | 'Circle';

export interface Geometry {
  type: GeometryTypes,
  coordinates: number[] | number[][] | number[][][];
}

export interface AreaProperties {
  name: string,
  id: string,
  user?: User,
  team?: Partial<Team>,
  area_in_sqm?: number,
  metadata?: JSON,
  groups?: Group[],
  group_ids?: number[]
  address?: any;
  from_distance?: number;
  to_distance?: number;
}

export interface Group {
  id: string,
  name: string,
  user: Partial<User>,
  team: Partial<Team>,
  description: string,
  metadata?: JSON,
  feature_ids: number[]
}

export interface FeatureCollection<T> {
  type: string;
  features: Array<T>;
}

export interface FilterBody {
  sort: string;
  tags: Group[];
}

export interface ConfirmationDialogData {
  header: string;
  confirmText: string;
  body?: string;
}

export interface NotificationMsg {
  created_at: string,
  id: string,
  message: string,
  read: boolean
  team: string,
  user: string,
  dismissed?: boolean
  category?: NotificationCategoryEnum
}

export interface DownloadMsg {
  fileName: string,
  total: number,
  loaded: number,
  percentage: number
}

export enum NotificationCategoryEnum {
  USER_ADDED_TO_TEAM = "user_added_to_team",
  USER_REMOVED_FROM_TEAM = "user_removed_from_team",
  USER_ADDED_TO_PROJECT = "user_added_to_project",
  USER_REMOVED_FROM_PROJECT = "user_removed_from_project",
  USER_ROLE_UPDATED = "user_role_updated",
  PROJECT_ARCHIVED = "project_archived",
  PROJECT_DELETED = "project_deleted",
}

export interface Download {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
  progress: number
  total: number
  loaded: number
  content: Blob | null
}
