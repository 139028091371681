<div class="mat-typography">
    <div class="dialog-header">
        {{this.data.header}}
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container"*ngIf="this.data.body">
        {{this.data.body}}
    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close>{{this.data.cancelText || 'Cancel'}}</button>
    <button mat-button cdkFocusInitial class="frmg-primary-button"
            (click)="closeDialog(true)">{{this.data.confirmText}}</button>
</div>
