import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OlMapComponent } from './map/ol-map.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AuthInterceptorService} from './services/interceptors/auth-interceptor.service'

// angular material stuff - could be refactored to own module
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';


// new components
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MainVerticalMenubarLeftComponent } from './components/main-vertical-menubar-left/main-vertical-menubar-left.component';

// the ngxs store module
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

// the i18n ngx-translate library
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OpenCloseComponent } from './components/open-close/open-close.component';

// login pages and components
import { LoginDialogComponent } from './components/login/login-dialog/login-dialog.component';
import { LoginBasePageComponent } from './components/login/login-base-page/login-base-page.component';
import { PortalBaseComponent } from './pages/portal-base/portal-base.component';

// forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuItemActiveComponent } from './components/menu-item-active/menu-item-active.component';
import { SfState } from './store/sf.state';
import { BottomDrawerComponent } from './components/bottom-drawer/bottom-drawer.component';

// mat table test
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

// tab controls
import { MatTabsModule } from '@angular/material/tabs';
import { LayerManagerComponent } from './components/layer-manager/layer-manager.component';

// material menu elements
import { MatExpansionModule } from '@angular/material/expansion';
import { UploaderComponent } from './components/uploader/uploader.component';
import { ProgressComponent } from './components/uploader/progress/progress.component';
import { DragDropDirective } from './components/uploader/directives/drag-drop.directive';
import { LayerItemComponent } from './components/layer-item/layer-item.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { DashboardHomeComponent } from './components/dashboard-home/dashboard-home.component';
import { UsersComponent } from './components/users/users.component';
import { ChartsPageComponent } from './components/charts-page/charts-page.component';
import { MapToolToolbarComponent } from './components/map-tool-toolbar/map-tool-toolbar.component';
import { DownloadDialogComponent } from './pages/risk-map/components/download-dialog/download-dialog.component';
import { LayerFilterPipe } from './pipes/layer-filter.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { TaggerComponent } from './components/tagger/tagger.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MapDrawToolsComponent } from './components/map-draw-tools/map-draw-tools.component';
import { SettingsMenuComponent } from './components/settings-menu/settings-menu.component';
import { MatSelectModule } from '@angular/material/select';
import { InviteMemberDialogComponent } from './components/invite-member-dialog/invite-member-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AreaSelectedPipe } from './pipes/area-selected.pipe';

// package for resizing elements with drag
import { ResizableModule } from 'angular-resizable-element';
import { OpenCloseRightComponent } from './components/open-close-right/open-close-right.component';
import { MenuBottomRightComponent } from './components/menu-bottom-right/menu-bottom-right.component';

// interceptors
//import { BasicAuthInterceptor } from './/services/interceptors/http-auth-interceptor';
import { DrawCompleteDialogComponent } from './components/draw-complete-dialog/draw-complete-dialog.component';
import { DownloadStatusTableComponent } from './pages/download-status-table/download-status-table.component';
import { InvitePageComponent } from './components/invite-page/invite-page.component';
import { PasswordCreatedComponent } from './components/password-created/password-created.component';
import {MatDividerModule} from "@angular/material/divider";
import {MatPaginatorModule} from "@angular/material/paginator";
import { SettingsComponent } from './pages/settings/settings.component';
import { AccountComponent } from './pages/settings/account/account.component';
import { ChangePasswordComponent } from './pages/settings/change-password/change-password.component';
import { TeamComponent } from './pages/settings/team/team.component';
import { ApiKeysComponent } from './pages/settings/api-keys/api-keys.component';
import { InviteModalComponent } from "./pages/settings/invite-modal/invite-modal.component";
import { RiskMapComponent } from "./pages/risk-map/risk-map.component";
import { MapComponent } from "./pages/risk-map/components/map/map.component";
import { AreaListComponent } from './pages/risk-map/components/area-list/area-list.component';
import { NgOptimizedImage } from "@angular/common";
import { SaveAreaDialogComponent } from './pages/risk-map/components/save-area-dialog/save-area-dialog.component';
import { MatMenuModule } from "@angular/material/menu";
import { DeleteAreaDialogComponent } from "./pages/risk-map/components/delete-area-dialog/delete-area-dialog.component";
import { LayerSidebarComponent } from './pages/risk-map/components/layer-sidebar/layer-sidebar.component';
import { MetersSquareToKilometersSquare } from "./pipes/m2_to_km2.pipe";
import { AddressSearchComponent } from './pages/risk-map/components/address-search/address-search.component';
import { FrmgSnackBarComponent } from "./pages/portal-base/components/snack-bar/snack-bar.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { RoleModalComponent } from "./pages/settings/role-modal/role-modal.component";
import { DeleteModalComponent } from "./pages/settings/delete-modal/delete-modal.component";
import { RiskReportModalComponent } from "./pages/risk-map/components/risk-report-modal/risk-report-modal.component";
import { InfoBlockComponent } from './components/info-block/info-block.component';
import { ReportTableComponent } from "./pages/report-table/report-table.component";
import { ReportComponent } from "./pages/report/report.component";
import { NgApexchartsModule } from "ng-apexcharts";
import {
  CreateProjectModalComponent
} from "./pages/risk-map/components/area-list/create-project-modal/create-project-modal.component";
import { FilterComponent } from './pages/risk-map/components/area-list/filter/filter.component';
import { MatRadioModule } from "@angular/material/radio";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { ShareModalComponent } from "./components/share-modal/share-modal.component";
import { UserLogoComponent } from './components/user-logo/user-logo.component';
import {
  ReportAreaDetailsComponent
} from "./pages/report/report-area-details/report-area-details-modal.component";
import { NotificationListComponent } from './pages/portal-base/components/notification-list/notification-list.component';
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { PlanUsageComponent } from "./pages/settings/plan_usage/plan_usage.component";
import { RoundPipe } from "./pipes/round.pipe";
import { getSaver, SAVER } from "./services/providers/saver.provider";
import { CdkDrag, CdkDropList, CdkDropListGroup } from "@angular/cdk/drag-drop";
import { AOISReportComponent } from "./pages/report/AOISReport/AOISReport.component";
import { AreaReportComponent } from "./pages/report/areaReport/areaReport.component";
import {
  DownloadReportDialogComponent
} from "./pages/report/AOISReport/download-report-dialog/download-report-dialog.component";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    OlMapComponent,
    SidebarComponent,
    MainVerticalMenubarLeftComponent,
    OpenCloseComponent,
    LoginDialogComponent,
    LoginBasePageComponent,
    PortalBaseComponent,
    MenuItemActiveComponent,
    BottomDrawerComponent,
    LayerManagerComponent,
    UploaderComponent,
    ProgressComponent,
    DragDropDirective,
    LayerItemComponent,
    DashboardHomeComponent,
    UsersComponent,
    ChartsPageComponent,
    MapToolToolbarComponent,
    DownloadDialogComponent,
    InviteModalComponent,
    RoleModalComponent,
    DeleteModalComponent,
    LayerFilterPipe,
    TaggerComponent,
    MapDrawToolsComponent,
    SettingsMenuComponent,
    InviteMemberDialogComponent,
    AreaSelectedPipe,
    OpenCloseRightComponent,
    MenuBottomRightComponent,
    DrawCompleteDialogComponent,
    DownloadStatusTableComponent,
    InvitePageComponent,
    PasswordCreatedComponent,
    ResetPasswordComponent,
    SettingsComponent,
    AccountComponent,
    PlanUsageComponent,
    ChangePasswordComponent,
    TeamComponent,
    ApiKeysComponent,
    RiskMapComponent,
    MapComponent,
    AreaListComponent,
    SaveAreaDialogComponent,
    DeleteAreaDialogComponent,
    DownloadReportDialogComponent,
    LayerSidebarComponent,
    MetersSquareToKilometersSquare,
    AddressSearchComponent,
    FrmgSnackBarComponent,
    RiskReportModalComponent,
    InfoBlockComponent,
    ReportTableComponent,
    ReportComponent,
    AOISReportComponent,
    CreateProjectModalComponent,
    FilterComponent,
    ConfirmationDialogComponent,
    ShareModalComponent,
    UserLogoComponent,
    ReportAreaDetailsComponent,
    AreaReportComponent,
    NotificationListComponent,
    RoundPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    FormsModule,
    MatCheckboxModule,
    MatSliderModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSelectModule,
    MatSlideToggleModule,
    ResizableModule,
    FormsModule,
    MatExpansionModule,
    NgxsModule.forRoot([SfState],
      {}),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatDividerModule,
    MatPaginatorModule,
    NgOptimizedImage,
    MatMenuModule,
    MatSnackBarModule,
    NgApexchartsModule,
    MatRadioModule,
    MatTreeModule,
    MatProgressBarModule,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {provide: SAVER, useFactory: getSaver}
  ],
  /*   {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true
    }
  ], */
  bootstrap: [AppComponent]
})
export class AppModule { }
