import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'm2km2'
})
export class MetersSquareToKilometersSquare implements PipeTransform {

  transform(value: any): string {
    return !isNaN(+value)?  (+value / 1000000).toFixed(2) : ( value  || '');
  }

}
