<div class="download-header">
    <p class="name-container">
        <img src="assets/icons/page_back.svg" alt="" width="8px" height="13px"
             (click)="goBack()">
        <ng-container *ngIf="!editMode">
            <span>{{ report?.name }}</span>
            <span class="icon-Edit" (click)="enterEditMode()"></span>
        </ng-container>

        <ng-container *ngIf="editMode">
            <mat-form-field appearance="outline">
                <input matInput [formControl]="reportNameCtrl">
            </mat-form-field>
            <button mat-button class="frmg-primary-button"
                    (click)="updateReportName()">
                <span class="icon-check"></span></button>
            <button mat-button class="frmg-secondary-button"
                    (click)="enterEditMode()">
                <span class="icon-close"></span></button>
        </ng-container>

    </p>
    <div>

        <button mat-raised-button class="frmg-primary-button" [matMenuTriggerFor]="downloadMenu"
                #menuOpen='matMenuTrigger'
                [ngClass]="{'menu-open': menuOpen.menuOpen}">
            <span>Download Report </span>
            <div class="separator"></div>
            <span class="icon-Arrow"></span>
        </button>
        <button mat-raised-button class="frmg-secondary-button"
                (click)="share(report, $event)">
            <span class="icon-share"></span>
        </button>
        <button mat-raised-button class="frmg-secondary-button"
                (click)="deleteReport(report, $event)">
            <span class="icon-remove-icon"></span>
        </button>
    </div>
    <mat-menu #downloadMenu="matMenu" xPosition="before" class="download-menu-panel header-download-menu">
        <!--        <span> Download in CSV</span>-->
        <!--        <span> Download in XLSX</span>-->
        <span style="min-width: 209px" (click)="download(id, report.name+'_report')"> Download in PDF</span>
        <span style="min-width: 209px" (click)="downloadXLSX(id, report.name+'_report')"> Download in XLSX</span>
    </mat-menu>
</div>
<div class="breadcrumbs">
    <span [ngClass]="{'navigate': depthLvl > 1}" (click)="navigateBreadCrumbs(false, depthLvl == 1)">All areas</span>
    <span>/</span>
    <ng-container *ngFor="let crumb of breadCrumbs; let last = last">
        <span [ngClass]="{'navigate': !last}" (click)="navigateBreadCrumbs(true, last)">{{crumb}}</span>
        <span *ngIf="!(last && depthLvl == 3)">/</span>
    </ng-container>
</div>
<router-outlet [id]="id"></router-outlet>
