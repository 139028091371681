import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthInterceptorService } from "../../services/interceptors/auth-interceptor.service";
import { ToastNotificationService } from "../../services/toast-notification.service";
import { findFirstNonEmptyString } from "../../utils";

@Component({
  selector: 'frmg-reset-page',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  token: string = '';
  password: string = '';
  confirmPassword: string = '';
  hidePassword: boolean = true;
  passwordCreatedSuccess = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authInterceptorService: AuthInterceptorService,
    private toastNotificationService: ToastNotificationService
  ) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    })
  }

  createPassword(password: string): void {
    this.authInterceptorService.resetPasswordConfirm(password, this.token).subscribe(resp=>{
      this.passwordCreatedSuccess = true;
    }, error => {

      this.toastNotificationService.showNotification({type: "error", message: findFirstNonEmptyString(error.error) ||  'Error while resetting password'})
    })

  }

  backToLogin() {
    this.router.navigate(['login']);
  }

  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }
}
