import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastNotificationService } from "../../../services/toast-notification.service";
import { UserService } from "../../../services/user.service";
import { findFirstNonEmptyString } from "../../../utils";

@Component({
  selector: 'frmg-invite-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.scss']
})
export class InviteModalComponent implements OnInit {
  public newUserGroup: FormGroup;
  public roles: Array<string> = ['admin', 'member'];
  public isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<InviteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toastNotificationService: ToastNotificationService,
    private userService: UserService,
  ) {
    this.newUserGroup = this.fb.group({
      email: [null, [Validators.required, Validators.pattern('[^\\s@]+@[^\\s@]+\\.[^\\s@]+$')]],
      role: ['member', [Validators.required]],
    })
  }

  ngOnInit(): void {

  }

  public closeDialog(result?: any): void {
    this.dialogRef.close(result);
  }

  public sendInvite(): void {
    this.isLoading = true;
    const userData = localStorage.getItem('user');
    const user = JSON.parse(userData);
    if (user.teams && user.teams.length > 0) {
      const teamId = user.teams[0].id;

      const inviteData = {
        team: teamId,
        email: this.newUserGroup.get('email').value,
        role: this.newUserGroup.get('role').value,
        is_accepted: false
      };

      this.userService.sendInvite(inviteData).subscribe(resp => {
        this.isLoading = false;
        this.toastNotificationService.showNotification({type: "success", message: "Invite sent successfully"});
        this.closeDialog();
      }, error => {
        this.isLoading = false;
        let errorMsg = 'An error occurred while sending the invite.'
        if (error.status === 400) {
          errorMsg = 'Invalid email address.'
        }
        this.toastNotificationService.showNotification({type: "error", message: findFirstNonEmptyString(error.error) || errorMsg})
      })
    }
  }
}
