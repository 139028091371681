import { FeatureCollection, Geometry } from 'geojson';
import { Map } from 'ol';
import Layer from 'ol/layer/Layer';

export enum LayerTypeEnum {
    Base = 'base',
    GeoJSON = 'geoJSON',
    KML = 'kml',
    FRMG = 'frmg',
    Other = 'other'
}

export interface LayerStateChangeModel {
    layerName: string,
    layerType: string,
    property: string,
    value: boolean | number
}

export interface ColorMap {
    color: string,
    position: number,
    label?: string
}

export interface LayerModel {
    layerName: string,
    visibleState: boolean, // true = on map
    deletable: boolean,
    editable: boolean,
    downloadable: boolean,
    type: LayerTypeEnum,
    thumbUrl: string,
    endpointUrl?: string,
    projection: string,
    opacity?: number,
    layerFileData?: File,
    colorMap?: string,
    colorMapArray?: ColorMap[],
    attribution?: string,
    opacityControlDownload?: boolean
}

export interface LayerMapContent {
    layerVO: LayerModel,
    olLayer: Layer
}

export const testLayers: LayerModel[] = [
    {
        layerName: 'Topology (ESRI)',
        visibleState: true, // true = on map
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.Base,
        thumbUrl: 'https://www.arcgis.com/sharing/rest/content/items/588f0e0acc514c11bc7c898fed9fc651/info/thumbnail/topo_thumb_b2wm.jpg',
        // endpointUrl?: string,
        projection: 'EPSG: 3857',
        opacity: 50
    },
    {
        layerName: 'Imagery (ESRI)',
        visibleState: false, // true = on map
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.Base,
        thumbUrl: 'https://www.arcgis.com/sharing/rest/content/items/2ea9c9cf54cb494187b03a5057d1a830/info/thumbnail/Jhbrid_thumb_b2.jpg',
        // endpointUrl?: string,
        projection: 'EPSG: 3857',
        opacity: 75
    },
    {
        layerName: 'Open Street Maps',
        visibleState: false, // true = on map
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.Base,
        thumbUrl: 'https://www.arcgis.com/sharing/rest/content/items/c29cfb7875fc4b97b58ba6987c460862/info/thumbnail/thumbnail1547740877120.jpeg',
        // endpointUrl?: string,
        projection: 'EPSG: 3857',
        opacity: 100
    },
    {
        layerName: 'Fuel Type',
        visibleState: true, // true = on map
        deletable: true,
        editable: true,
        downloadable: true,
        type: LayerTypeEnum.FRMG,
        thumbUrl: 'https://www.arcgis.com/sharing/rest/content/items/2ea9c9cf54cb494187b03a5057d1a830/info/thumbnail/Jhbrid_thumb_b2.jpg',
        // endpointUrl?: string,
        projection: 'EPSG: 3857',
        opacity: 40
    },
    {
        layerName: 'Exposure',
        visibleState: true, // true = on map
        deletable: true,
        editable: true,
        downloadable: true,
        type: LayerTypeEnum.FRMG,
        thumbUrl: 'https://www.arcgis.com/sharing/rest/content/items/c29cfb7875fc4b97b58ba6987c460862/info/thumbnail/thumbnail1547740877120.jpeg',
        // endpointUrl?: string,
        projection: 'EPSG: 3857',
        opacity: 100
    },
]