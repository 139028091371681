import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Layer } from "../../../../models/layer.models";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { debounceTime, Subject, take } from "rxjs";
import { LayerService } from "../../../../services/layer.service";
import { MapCommunicatorService } from "../../map-communicator.service";

@Component({
  selector: 'frmg-layer-sidebar',
  templateUrl: './layer-sidebar.component.html',
  styleUrls: ['./layer-sidebar.component.scss']
})
export class LayerSidebarComponent implements OnInit {
  layers: Array<Layer>;
  activeTab = 'basemap';
  selectedBasemap = 'topographic';
  sidebarVisible = false;
  public layersGroup: FormGroup;
  @Input() mapCommunicator!: Subject<string>;

  constructor(private http: HttpClient,
              private fb: FormBuilder,
              private mapCommunicatorService: MapCommunicatorService,
              private layerService: LayerService) {
    this.layersGroup = this.fb.group({
    })
  }

  ngOnInit() {
    this.fetchLayers();
    this.selectedBasemap = this.mapCommunicatorService.baseMap;
  }

  /**
   * Fetches layers from the API
   *
   * @return {void}
   */
  fetchLayers(): void {
    this.layerService.getLayers().pipe(debounceTime(0), take(1)).subscribe((response: any) => {
      const layers = response.map((layer: any) => ({
        ...layer,
        enabled: false,
        opacity: 1,
        selected: false
      }));
      layers.forEach((layer: Layer)=>{
        this.layersGroup.addControl(layer.layer_name, this.fb.group({
          active: [false],
          opacity: [1, [Validators.min(0), Validators.max(1)]],
          layer: layer
          }))
      });
      this.layers = layers;
      this.layersGroup.valueChanges.pipe(debounceTime(500)).subscribe(layers=>{
        this.layerService.setSavedLayers(layers);
        this.mapCommunicatorService.layers.next(Object.values(layers));
      })
      this.layersGroup.patchValue(this.layerService.getSavedLayers(), {emitEvent: true});
    });
  }

  /**
   * Checks if a given value is numeric.
   *
   * @param {string} value - The value to be checked.
   * @return {boolean} - Returns true if the value is numeric, false otherwise.
   */
  isNumeric(value: string): boolean {
    return !isNaN(parseFloat(value));
  }

  /**
   * Toggles the visibility of the sidebar.
   *
   * @return {void}
   */
  toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
    // this.saveSidebarState();
  }


  /**
   * Generates a CSS linear-gradient background string based on the given color map.
   *
   * @param {any} colorMap - The color map object. Each key represents a color value and each value represents the percentage position of the corresponding color in the gradient.
   * @return {string} - The CSS linear-gradient background string.
   */
  getGradientBackground(colorMap: any): string {
    const colors = Object.entries(colorMap).map(([key, value]) => `${value} ${+key/2}%`).join(', ');
    return `linear-gradient(to right, ${colors})`;
  }

  /**
   * Retrieves an array of discrete colors from a color map.
   *
   * @param {any} colorMap - The color map object.
   * @return {string[]} - An array of discrete colors.
   */
  getDiscreteColors(colorMap: any): string[] {
    return Object.values(colorMap);
  }

  /**
   * Handle the wheel event and scroll the container element.
   *
   * @param {WheelEvent} event - The wheel event object.
   */
  onWheel(event: WheelEvent): void {
    const container = event.currentTarget as HTMLElement;
    container.scrollBy(0, event.deltaY);
    event.preventDefault();
  }

  /**
   * Emits the selected basemap when the basemap is changed.
   *
   * @return {void} - This method does not return any value.
   */
  onBasemapChange(): void {
    this.mapCommunicatorService.mapCommunicator.next('basemap_'+this.selectedBasemap)
    this.mapCommunicatorService.baseMap = this.selectedBasemap;
  }

  /**
   * Formats the legend value according to the specified format rules.
   *
   * @param {string} legend - The legend value to be formatted.
   * @returns {string} The formatted legend value.
   */
  formatLegend(legend: string): string {
    const value: number = parseFloat(legend);
    if (!isNaN(value)) {
      return value >= 1000 ? (value / 1000).toFixed(1) + 'k' : value.toFixed(0);
    }
    return legend;
  }

  /**
   * Spaces the numeric legends evenly.
   *
   * @param {string[]} legends - The array of legend values.
   * @returns {string[]} The array of spaced numeric legends.
   */
  getSpacedNumericLegends(legends: string[]): string[] {
    const numericLegends = legends.map(Number);
    const min = Math.min(...numericLegends);
    const max = Math.max(...numericLegends);
    const step = (max - min) / (numericLegends.length - 1);

    return Array.from({ length: numericLegends.length }, (_, i) => (min + i * step).toFixed(1));
  }

}