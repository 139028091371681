import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, share } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Download, PagedData } from "../models/general.models";
import { UserService } from "./user.service";
import { AreaCollections, ReportCreate, RiskReport, RiskReportAnalysis, RiskScores } from "../models/report.model";
import { download } from "../utils";
import { DownloadService } from "./download.service";


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private baseUrl: string = environment.baseUrl;
  private readonly sharedRequests = {
    reports: {},
    details: {}
  }

  public breadCrumbs: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private downloadService: DownloadService
  ) {
  }

  public createReport(body: ReportCreate): Observable<RiskReport> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    const report =  {
      "basic_config": {
        "name": "string",
        "colormap_threshold": {
          "lowest": 0,
          "highest": 200
        },
        "boundary_radius_meters": 2000,
        "area_ids": [
          "3fa85f64-5717-4562-b3fc-2c963f66afa6"
        ],
        "layer_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "window_size_meters": 4000,
        "search_state": "both",
        "output_dpi": 100
      },
      "advanced_config": {
        "overlay_config": {
          "overlays": [
            "background",
            "overlay",
            "center_marker"
          ]
        },
        "zone_config": {
          "zone_width": 200,
          "zone_type": "behind",
          "zone_color": "#74eb34",
          "zone_alpha": 0.3,
          "target_box_count": 12,
          "point_num_circles": 2
        },
        "risk_threshold": {
          "nearest_risk_threshold_min": 0,
          "nearest_risk_threshold_max": 200
        },
        "plot_config": {
          "step_size": 20,
          "include_histogram": true,
          "include_fire_trends": true
        }
      }
    }

    if (body.enabledLayers[2]){
      report.advanced_config.overlay_config.overlays.push('extreme_points')
    }
    if (body.enabledLayers[0]){
      report.advanced_config.overlay_config.overlays.push('nearest_risk')
    }
    if (body.enabledLayers[1]){
      report.advanced_config.overlay_config.overlays.push('zone')
    }

    report.basic_config.name = body.name;
    report.basic_config.boundary_radius_meters = body.boundary;
    report.advanced_config.risk_threshold = body.risk_threshold;
    report.advanced_config.zone_config = body.zone_config;
    report.basic_config.name = body.name;
    report.basic_config.colormap_threshold.lowest = body.lowest_threshold;
    report.basic_config.colormap_threshold.highest = body.highest_threshold;
    report.basic_config.layer_id = body.layer_id;
    report.basic_config.area_ids = body.area_ids;
    return this.http.post<RiskReport>(endpoint, report, {headers});
  }

  public getReports(config?: { [key: string]: any }): Observable<PagedData<RiskReport>> {
    let endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/`;
    if (config && Object.entries(config).length) {
      Object.entries(config).forEach((entry, index)=>{
        let jointSymbol = '&';
        if (index == 0 ) {
          jointSymbol = '?';
        }
        endpoint += `${jointSymbol}${entry[0]}=${entry[1]}`
      })
    }
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<PagedData<RiskReport>>(endpoint, {headers})
  }

  public getReport(id: string): Observable<RiskReport> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    if (!this.sharedRequests.reports[id]) {
      this.sharedRequests.reports[id] = this.http.get<any>(endpoint,  {headers}).pipe(share());
    }
    return this.sharedRequests.reports[id];
    return this.http.get<RiskReport>(endpoint,  {headers});
  }

  public getReportDetails(reportId: string, collectionId: string, id: string): Observable<any> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/area-collections/${collectionId}/area-details/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    if (!this.sharedRequests.details[id]) {
      this.sharedRequests.details[id] = this.http.get<any>(endpoint,  {headers}).pipe(share());
    }
    return this.sharedRequests.details[id];
  }

  public updateReport(id: string, body: Partial<RiskReport>): Observable<RiskReport> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.patch<RiskReport>(endpoint,body,  {headers});
  }

  public getReportScore(id: string): Observable<RiskScores> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/risk_score_counts`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<RiskScores>(endpoint,  {headers});
  }

  public getReportPdf(id: string, filename: string): Observable<Download> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/pdf/`;
    return this.downloadService.download(endpoint, filename+'.pdf');
  }
  public getReportXLSX(id: string, filename: string): Observable<Download> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/xlsx/`;
    return this.downloadService.download(endpoint, filename+'.xlsx');
  }
  public getAreaReportPdf(id: string, areaId: number | string, filename: string): Observable<Download> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/area-collections/${areaId}/pdf`;
    return this.downloadService.download(endpoint, filename+'.pdf');
  }
  public getAreaReportXLSX(id: string, areaId: number | string, filename: string): Observable<Download> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/area-collections/${areaId}/xlsx`;
    return this.downloadService.download(endpoint, filename+'.xlsx');
  }

  public getAreaMetadataKeys(): Observable<any> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/areas/metadata-keys/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<RiskScores>(endpoint,  {headers})
  }

  public getReportAnalyse(id: string, config?: { [key: string]: any }): Observable<PagedData<AreaCollections>> {
    let endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/area-collections/`;
    if (config && Object.entries(config).length) {
      Object.entries(config).forEach((entry, index)=>{
        let jointSymbol = '&';
        if (index == 0 ) {
          jointSymbol = '?';
        }
        endpoint += `${jointSymbol}${entry[0]}=${entry[1]}`
      })
    }
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<PagedData<AreaCollections>>(endpoint,  {headers});
  }

  public getReportAnalyseById(reportId: string, collectionId: string, config?: { [key: string]: any }): Observable<AreaCollections> {
    let endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${reportId}/area-collections/${collectionId}/`;
    if (config && Object.entries(config).length) {
      Object.entries(config).forEach((entry, index)=>{
        let jointSymbol = '&';
        if (index == 0 ) {
          jointSymbol = '?';
        }
        endpoint += `${jointSymbol}${entry[0]}=${entry[1]}`
      })
    }
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<AreaCollections>(endpoint,  {headers});
  }

  public deleteReport(id: string): Observable<any> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.delete<any>(endpoint,  {headers});
  }

  public shareReport(body: { user_id: string | number }, id: string): Observable<RiskReport> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/share/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.post<RiskReport>(endpoint, body, {headers});
  }

  public removeShareReport(body: { user_id: string | number }, id: string): Observable<RiskReport> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/reports/${id}/remove_share/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.post<RiskReport>(endpoint, body, {headers});
  }
}
