import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ChangeBottomDrawerAction } from 'src/app/store/sf.actions';
import { SfState } from 'src/app/store/sf.state';

@Component({
  selector: 'frmg-bottom-drawer',
  templateUrl: './bottom-drawer.component.html',
  styleUrls: ['./bottom-drawer.component.scss'],
  animations: [
    trigger('openCloseBottomDrawer', [
      state('open', style({
        bottom: '{{bottomOpen}}' + 'px',
        height: '{{heightOpen}}' + 'px',
        opacity: '{{opacityOpen}}',
        backgroundColor: '{{colorOpen}}'
      }),
      
      {params: {
        bottomOpen: 100,
        heightOpen: 0,
        opacityOpen: 1,
        colorOpen: 'red'
      }}),
      
      state('closed', style({
        bottom: '{{bottomClosed}}' + 'px',
        height: '{{heightClosed}}' + 'px',
        opacity: '{{opacityClosed}}',
        backgroundColor: '{{colorClosed}}'
      }),
      
      {params: {
        bottomClosed: 100,
        heightClosed: 0,
        opacityClosed: 1,
        colorClosed: 'red'
      }}),

      transition('open => closed', [animate('150ms 0ms ease-out')]),
      transition('closed => open', [animate('150ms 0ms ease-out')]),
    ])
  ]
})
export class BottomDrawerComponent implements OnInit, AfterViewInit {

  // animated - open state
  @Input() heightOpen!: number;
  @Input() opacityOpen!: number;
  @Input() colorOpen!: string;
  @Input() bottomOpen!: number;

  // animated - closed state
  @Input() heightClosed!: number;
  @Input() opacityClosed!: number;
  @Input() colorClosed!: string;
  @Input() bottomClosed!: number;

  // other style settings
  @Input() widthPercent!: number;
  @Input() widthPixels!: number;
  @Input() offsetLeft!: string;

  isOpen = 'closed';

  @Select(SfState.getBottomDrawerState) bottomDrawerStateO$!: Observable<string>;

  constructor(
    private store: Store
  ){

  }

  ngOnInit(): void {
    this.bottomDrawerStateO$
      .subscribe((value) => {
        this.isOpen = value;
      })
  }

  ngAfterViewInit(): void {
  }

  toggleDrawer(state: string): void {
    //this.toggle();

    if (this.isOpen === 'open') {
      this.store.dispatch(new ChangeBottomDrawerAction('closed'));
    } else {
      this.store.dispatch(new ChangeBottomDrawerAction('open'));
    }
  }

}
