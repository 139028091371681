<div class="areas-container">
    <div class="areas-header">
        <div>
            <div class="areas-title">
                <img *ngIf=" currentProject?.path !== 'root'" src="assets/icons/page_back.svg" alt="" width="6px"
                     height="10px"
                     (click)="goProjectUp($event)">
                <span>{{ currentProject?.path == 'root' ? "My Projects" : currentProject?.name }}</span>
            </div>
            <div class="header-actions">
                <div [ngClass]="{'icon-Plus': currentProject?.path == 'root', 'icon-add-folder': currentProject?.path !== 'root'}"
                     (click)="createProject()" [matTooltip]=" currentProject?.path == 'root' ? 'Create Project' : 'Create Folder'"></div>
                <div class="icon-filter" matTooltip="Filters"
                     *ngIf="currentProject && currentProject.path !== 'root'"
                     [matMenuTriggerFor]="filter" [ngClass]="{'active': filterTrigger.menuOpen}"
                     #filterTrigger="matMenuTrigger">
                    <div class="counter" *ngIf="areaService.filterBody.tags.length">
                        <span>
                            {{areaService.filterBody.tags.length}}
                        </span>
                    </div>
                </div>
                <mat-menu #filter="matMenu" yPosition="above" xPosition="after" class="uploadBox" hasBackdrop="false">
                    <frmg-filter (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
                                 (closeMenu)="filterTrigger.closeMenu()" (onApply)="onApplyFilter($event)"
                                 [filter]="areaService.filterBody"/>
                </mat-menu>
                <div class="icon-search-icon" (click)="toggleSearch()" matTooltip="Search area" *ngIf="!showSearch"></div>
                <div class="icon-search-close" (click)="toggleSearch()" matTooltip="Search area" *ngIf="showSearch"></div>
                <ng-container *ngIf="currentProject && currentProject.path !== 'root'">
                    <div class="icon-Upload-file" [ngClass]="{'active': menuTrigger.menuOpen}"
                         [matMenuTriggerFor]="importModal" #menuTrigger="matMenuTrigger"
                         matTooltip="Import files"></div>
                    <mat-menu #importModal="matMenu" yPosition="above" xPosition="after" class="uploadBox"
                              hasBackdrop="false">
                        <frmg-uploader (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
                                       (closeMenu)="menuTrigger.closeMenu()" [projectId]="currentProject.id"/>
                    </mat-menu>
                </ng-container>

            </div>
        </div>
        <div class="search-field" *ngIf="showSearch"
        [ngClass]="{'selected': this.searchAreaCtrl.value.length}">
            <mat-form-field appearance="outline">
                <div class="icon-search-icon" matPrefix></div>
                <mat-label>Area name</mat-label>
                <div class="search-input">
                    <input matInput #searchInput [formControl]="searchAreaCtrl" placeholder="Enter area name"
                    (focus)="showResults(true)" (blur)="showResults(false)">
                </div>
                <div matSuffix class="search-suffix" (click)="$event.stopPropagation()"
                     *ngIf="searchAreaCtrl.value.length">
                    <div class="icon-close" (click)="toggleSearch()"></div>

                </div>
            </mat-form-field>
            <div class="separator"></div>
            <mat-form-field appearance="outline" class="search-select">
                <mat-select [(ngModel)]="searchType"
                            (selectionChange)="startSearch(searchAreaCtrl.value)"
                            panelClass="search-panel">
                    <mat-option value="all">All folders</mat-option>
                    <mat-option [value]="currentProject.id">This folder</mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="search-results" *ngIf="showSearch && searchResults.length && showSearchResults">
            <ng-container *ngIf="getSearchResultsByType('projects').length && searchType == 'all'">
                <div class="search-category">PROJECTS</div>
                <div class="search-item" *ngFor="let item of getSearchResultsByType('projects')" (click)="goToItem(item)">
                    <ng-template [ngTemplateOutlet]="searchResult"
                                 [ngTemplateOutletContext]="{
                                     item: item
                                     }"></ng-template>
                </div>
            </ng-container>
            <ng-container *ngIf="getSearchResultsByType('folders').length">
                <div class="search-category">FOLDERS</div>
                <div class="search-item" *ngFor="let item of getSearchResultsByType('folders')" (click)="goToItem(item)">
                    <ng-template [ngTemplateOutlet]="searchResult"
                                 [ngTemplateOutletContext]="{
                                     item: item
                                     }"></ng-template>
                </div>
            </ng-container>
            <ng-container *ngIf="getSearchResultsByType('aois').length">
                <div class="search-category">AOIs</div>
                <div class="search-item" *ngFor="let item of getSearchResultsByType('aois')" (click)="goToItem(item)">
                    <ng-template [ngTemplateOutlet]="searchResult"
                                 [ngTemplateOutletContext]="{
                                     item: item
                                     }"></ng-template>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="areas-list">
        <div class="list-selector">
            <mat-checkbox
                    *ngIf="currentProject?.path !== 'root'"
                    [disabled]="!list.length"
                    [indeterminate]="someChecked()"
                    [checked]="allChecked()"
                    (change)="setAll($event.checked)" matTooltip="Select/deselect all">
            </mat-checkbox>
            <span>
                <strong>{{ getCheckedIds().length ? getCheckedIds().length : (currentProject?.path == 'root' ? getProjects().length : list.length) }}</strong>
                {{ getCheckedIds().length ? " selected " : "" }}
                {{ currentProject?.path == 'root' ? ' projects' : ' items' }}</span>
            <span class="archived-selector" *ngIf="currentProject?.path == 'root'">
                <mat-slide-toggle
                        [(ngModel)]="archivedToggle"
                        (change)="loadProjects()"
                        labelPosition="before">
                Only archived projects
            </mat-slide-toggle>
            </span>

            <div class="list-element-actions selector-actions">
                <div class="icon-Download raised" (click)="downloadSelected()"
                     *ngIf="getCheckedIds().length" matTooltip="Request download"></div>
                <div class="icon-Fire-risk raised" (click)="generateReport()"
                     *ngIf="getCheckedIds().length" matTooltip="Generate Exposure Report"></div>
                <div class="list-element-actions-menu" *ngIf="getCheckedIds().length">
                    <div class="icon-more-horiz"
                         [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation();">
                    </div>
                    <mat-menu #actionMenu="matMenu">
                        <button mat-menu-item (click)="deleteAreas(getCheckedIds())" class="menu-slot">Delete Areas
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="horizontal-separator"></div>
        <div class="list-container" #listContainer
             [ngClass]="{'spinner' : loadingData()}">
            <ng-container *ngIf="!loadingData()">
                <!--                     [ngClass]="{'project' : project.path.split('/').length === 2}"-->
                <div class="list-element folder-element project" *ngFor="let project of getProjects()"

                     [id]="project.id"
                     (click)="openProject(project, $event)">
                    <div class="list-element-container">
                        <div class="list-element-info">
                            <!--                            <ng-container *ngIf="editingProject?.id !== project.id">-->
                            <div class="list-element-title">
                                <img [src]="'assets/icons/'+folderType(project)+'.svg'"
                                     alt=""
                                     width="18px" height="18px">
                                <span>
                                        {{ project.name }}
                                    </span>

                                <!--                                    <div *ngIf="!project.is_archived && project.user.email == currentUser.email"-->
                                <!--                                         class="icon-Edit" (click)="changeNameProject(project, $event)"></div>-->
                            </div>
                            <div class="list-element-tags element-desc" [matTooltip]="project.description"
                                 *ngIf="project.description"
                                 matTooltipPosition="above" [matTooltipDisabled]="project.description?.length < 40">
                                <span>{{ project.description }} </span>
                            </div>
                            <!--                            </ng-container>-->
                            <!--                            <ng-container *ngIf="editingProject?.id === project.id">-->
                            <!--                                <mat-form-field appearance="outline">-->
                            <!--                                    <input matInput [(ngModel)]="newName" class="name-input">-->
                            <!--                                </mat-form-field>-->
                            <!--                            </ng-container>-->
                        </div>
                        <div class="shared-users" *ngIf="project.shared_with.length">
                            <ng-container *ngFor="let user of project.shared_with; let $index = index;">
                                <frmg-user-logo [user]="user"
                                                *ngIf="$index < 2"></frmg-user-logo>
                                <frmg-user-logo [user]="moreUsers(project)"
                                                *ngIf="$index === 2"></frmg-user-logo>
                            </ng-container>
                        </div>
                        <div class="list-element-actions">
                            <!--                            <ng-container *ngIf="editingProject?.id === project.id">-->
                            <!--                                <div class="icon-check primary"-->
                            <!--                                     (click)="saveNameProject($event)"></div>-->
                            <!--                                <div class="icon-close"-->
                            <!--                                     (click)="cancelNameProject($event)"></div>-->
                            <!--                            </ng-container>-->
                            <div class="list-element-actions-menu project" *ngIf="editingProject?.id !== project.id">
                                <div class="icon-more-horiz"
                                     [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation();"
                                     *ngIf="project.user.email == currentUser.email">
                                </div>
                                <mat-menu #actionMenu="matMenu">
                                    <!--                                <button mat-menu-item class="menu-slot">-->
                                    <!--                                    Share-->
                                    <!--                                </button>-->

                                    <button mat-menu-item (click)="share(project)" class="menu-slot" *ngIf="!project.is_archived">
                                        Share {{ currentProject.path === 'root' ? 'Project' : 'Folder' }}
                                    </button>
                                    <button mat-menu-item (click)="archiveProject(project, $event)"
                                            *ngIf="currentProject.path == 'root'" class="menu-slot">
                                        {{ project.is_archived ? 'Unarchive' : 'Archive' }}
                                    </button>
                                    <button mat-menu-item (click)="editProject(project)" class="menu-slot" *ngIf="!project.is_archived">
                                        Edit {{ currentProject.path === 'root' ? 'Project' : 'Folder' }}
                                    </button>
                                    <button mat-menu-item (click)="deleteProject(project.id, $event, currentProject.path  === 'root')" class="menu-slot"
                                            *ngIf="!project.is_archived">
                                        Delete Forever
                                    </button>
                                </mat-menu>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="currentProject?.path !== 'root' && !loadingData()">
                <div class="list-element area" *ngFor="let area of list"
                     [ngClass]="{'active': area.selected, 'highlighted': area.highlighted}" [id]="area.properties.id"
                     (click)="areaChecked(area, $event)">
                    <mat-checkbox [checked]="area.selected"
                                  [disabled]="editingArea?.properties?.id == area.properties.id">
                    </mat-checkbox>
                    <div class="list-element-container">
                        <div class="list-element-info">
                            <!--                            <ng-container *ngIf="editingArea?.properties?.id !== area.properties.id">-->
                            <div class="list-element-size">
                                <div [ngClass]="getAreaIconType(area)"></div>
                                <span>
                                 {{ area.properties.area_in_sqm | m2km2 }} km<sup>2</sup>
                             </span>
                            </div>
                            <div class="list-element-title">
                            <span>
                                {{ area.properties.name }}
                            </span>
                                <!--                                    <div class="icon-Edit" (click)="changeName(area, $event)"-->
                                <!--                                         *ngIf="area.properties.user.email == currentUser.email"></div>-->
                            </div>
                            <div class="list-element-tags">
                            <span>
                            {{ createGroupString(area.properties.groups!) }}
                            </span>
                                <!--                                <div class="icon-tags" (click)="changeTags(area, $event)"-->
                                <!--                                     *ngIf="area.properties.user.email == currentUser.email"></div>-->
                            </div>
                            <!--                            </ng-container>-->
                            <!--                            <ng-container *ngIf="editingArea?.properties?.id === area.properties.id">-->
                            <!--                                <mat-form-field appearance="outline">-->
                            <!--                                    <input matInput [(ngModel)]="newName" class="name-input">-->
                            <!--                                </mat-form-field>-->
                            <!--                            </ng-container>-->
                        </div>

                    </div>
                    <div class="list-element-actions">
                        <!--                            <ng-container *ngIf="editingArea?.properties?.id === area.properties.id">-->
                        <!--                                <div class="icon-check primary"-->
                        <!--                                     (click)="saveName($event)"></div>-->
                        <!--                                <div class="icon-close"-->
                        <!--                                     (click)="cancelName($event)"></div>-->
                        <!--                            </ng-container>-->
                        <div class="list-element-actions-menu"
                             *ngIf="editingArea?.properties?.id !== area.properties.id">
                            <div class="icon-more-horiz"
                                 [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation();"
                                 *ngIf="area.properties.user.email == currentUser.email">
                            </div>
                            <mat-menu #actionMenu="matMenu" class="action-menu">
                                <button mat-menu-item (click)="deleteAreas([area.properties.id])" class="menu-slot">
                                    Delete Area
                                </button>
                                <button mat-menu-item (click)="editArea(area, $event)" class="menu-slot">
                                    Edit Area
                                </button>
                            </mat-menu>
                            <div class="icon-focus-in" (click)="focusOn(area, $event)"
                                 matTooltip="Focus on area"></div>
                        </div>

                    </div>
                </div>
            </ng-container>
            <div class="empty-list" *ngIf="!list.length && !getProjects().length && !loadingData()">
                <img src="assets/icons/info-chat.svg" alt="empty list">
                <span class="empty-title">No {{ currentProject?.path !== 'root' ? 'areas/folders' : 'projects' }}
                    found</span>
                <span>Please create new {{ currentProject?.path !== 'root' ? 'area/folder or import one' : 'project' }}</span>
            </div>
        </div>
    </div>
    <div class="horizontal-separator"></div>
    <div class="areas-footer" *ngIf="currentProject && currentProject?.path !== 'root'">
        <span>Showing {{ list.length }} out of {{ list.length }}</span>
    </div>
</div>

<ng-template #searchResult let-item="item">
    <div class="icon-folder" *ngIf="item.name && !item.is_project"></div>
    <div class="icon-project" *ngIf="item.name && item.is_project"></div>
    <div class="icon-Square" *ngIf="!item.name"></div>
    <div class="item-info">
        <div class="item-name">{{ item.name || item.properties?.name }}</div>
        <div class="item-path"
             [innerHTML]="transformSearchPath(item.path || item.properties?.project?.path)"></div>
    </div>
</ng-template>



