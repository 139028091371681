import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { FilterBody, Group } from "../../../../../models/general.models";
import { GroupService } from "../../../../../services/group.service";

@Component({
  selector: 'frmg-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit{
  public tagsControl: FormControl;
  public tagsList: Array<Group> = [];
  public sort: string = 'created_at';

  @Input() filter : FilterBody

  @Output() closeMenu = new EventEmitter<any>();
  @Output() onApply = new EventEmitter<FilterBody>();

  constructor(private fb: FormBuilder,
              private groupService: GroupService) {
    this.tagsControl = this.fb.control('')
  }

  ngOnInit(): void {
    this.groupService.searchGroup(' ').subscribe(resp=>{
      this.tagsList = resp;
    })
    }

  public clear() {
    this.onApply.emit({sort: 'created_at', tags: []})
  }

  public apply() {
    this.onApply.emit({
      sort: this.sort,
      tags: this.tagsControl.value || []
    });
  }
}
