import { Component, OnInit } from '@angular/core';
import { TaskService } from "../../services/task.service";
import { Task, TaskStatistic, TaskStatus, taskStatusTranslations } from "../../models/tasks.model";
import { truncate } from "../../utils";
import { PageEvent } from "@angular/material/paginator";
import { RiskReport } from "../../models/report.model";

@Component({
  selector: 'frmg-download-status-table',
  templateUrl: './download-status-table.component.html',
  styleUrls: ['./download-status-table.component.scss']
})
export class DownloadStatusTableComponent implements OnInit {

  public displayedColumns = ["area", "created_at", "layer", "status", "action"]
  public taskData: Array<Task> = [];
  public truncate = truncate;
  public currentPage = 1;
  public pageSize = 100;
  public totalElements = 0;
  public statistic: Partial<TaskStatistic> = {
    completed: 0,
    started: 0,
    cancelled: 0,
    failed: 0
  };
  public taskStatusTranslations = taskStatusTranslations;

  constructor(private taskService: TaskService) {
  }

  ngOnInit() {
    this.getTasks()
  }

  private getTasks(): void {
    this.taskService.getTasksStatus().subscribe(result => {
      this.statistic = result;
    })
    this.taskService.getTasks(this.currentPage, this.pageSize).subscribe(result => {
      this.totalElements = result.count;
      this.taskData = result.results;
      if (result.results.some(task=>task.status === TaskStatus.PROGRESS || task.status === TaskStatus.PENDING || task.status === TaskStatus.STARTED)) {
        setTimeout(()=>this.getTasks(),3000)
      }
    })
  }

  public download(url: string): void {
    window.open(url, "_blank");
  }

  public handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.currentPage = e.pageIndex + 1;
    this.getTasks();
  }

  public statusClass(report: RiskReport) {
    switch (report.status) {
      case 'completed':
        return 'icon-Processed';
      case TaskStatus.PROGRESS:
      case TaskStatus.PENDING:
      case TaskStatus.STARTED:
        return 'icon-Processing';
      default:
        return 'icon-Canceled'
    }
  }

}
