<div class="mat-typography" >
    <div class="dialog-header">
        Delete current user
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        They will be deleted from the team.
    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button cdkFocusInitial class="frmg-primary-button"
            (click)="closeDialog(true)">DELETE
    </button>
</div>
