import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GroupListVO, GroupVO } from '../models/area-item.model';
import { BaseApiService } from './base-api.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class DataService extends BaseApiService{

  constructor(
    http: HttpClient,
    router: Router,
    private userService: UserService
    //private baseApiService: BaseApiService
  ) {
    super(http, router)
  }
  //private slug = 'ninjas';

  //baseUrl: string = environment.baseUrl;
  // hold the current group list
  private currentGroups: GroupVO[] = [];


  getCurrentGroups(): GroupVO[] {
    return this.currentGroups
  }

  // load groups to populate the group tagger selector
  
  /* Use this to subscribe to initial group list loading  */
  private groupChangeSubject$ = new Subject<GroupVO[]>();

  public getGroupsObservable(): Observable<GroupVO[]> {
    return this.groupChangeSubject$.asObservable();
  }

  updateGroupList(newGroupList: GroupVO[]): void {
    this.groupChangeSubject$.next(newGroupList);
  }



  // todo define model for areas response
  getAreas(): Observable<any> {

    const resource = 'areas/search';

    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/${resource}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<any>(endpoint, { headers });

  }



  /* groups api ------------------------------- */
  getGroups(): void {

    const resource = 'groups/search';
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/${resource}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    this.http.get<GroupVO[]>(endpoint, { headers })
      .subscribe((groups) => {
        this.groupChangeSubject$.next(groups);
        this.currentGroups = groups;
      })
  }

  createGroup(groupName: string, description?: string): Observable<GroupVO> {
    const resource = 'groups';
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/${resource}/`;
    const group = {name: groupName, description: description || ''}
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.currentAccessToken}`
    })
    return this.http.post<GroupVO>(endpoint, group, {headers: headers});
  }

  addAreasToGroupName(newGroup: GroupVO, areas: any[]): Observable<GroupVO> {

    const resource = 'groups';
    const subResource = 'attach-features';
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/${resource}/${newGroup.id}/${subResource}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    const body = { feature_ids: areas }
    return this.http.post<GroupVO>(endpoint, body,  {headers: headers});

  }

  searchAreas(type: string, text?: string, radiusAndPoint?: {distance: number, lat: number, lon: number}): Observable<any> {
    const resource = 'areas/search'
    let paramString: string = '';
    switch(type) {
      case 'all': {
        paramString = '';
      }
      break;

      case 'text': {
        paramString = `?q=${text}`;
      }
      break;

      case 'radius': {
        paramString = '';
        if (radiusAndPoint) {
          paramString = `?distance=${radiusAndPoint.distance}&lat=${radiusAndPoint.lat}&lon=${radiusAndPoint.lon}`;
        }

      }
      break;

      default: {
        paramString = '';
      }
    }

    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/${resource}/${paramString}`;
    return this.http.get(endpoint);
  }

  deleteArea(id: string): Observable<any> {
    const resource = 'areas';
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/${resource}/${id}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.delete(endpoint, { headers: headers });
  }

  deleteAllAreas(features: any[]): void {
    const resource = 'areas';
    for (let i = 0; i < features.length; i++) {
      const id = features[i].getProperties()['id'];
      this.deleteArea(i + 970 + '')
        .subscribe(result => {
        })
    }
  }


}