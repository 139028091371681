import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { ProjectService } from "../../../../../services/project.service";
import { ToastNotificationService } from "../../../../../services/toast-notification.service";
import { Project } from "../../../../../models/project.module";
import { findFirstNonEmptyString } from "../../../../../utils";

@Component({
  selector: 'frmg-create-modal',
  templateUrl: './create-project-modal.component.html',
  styleUrls: ['./create-project-modal.component.scss']
})
export class CreateProjectModalComponent implements OnInit {
  public newProjectGroup: FormGroup;
  public isLoading = false;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    private dialogRef: MatDialogRef<CreateProjectModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { parent: Project, project?: Project },
    private fb: FormBuilder,
    private toastNotificationService: ToastNotificationService,
    private projectService: ProjectService,
  ) {

    this.newProjectGroup = this.fb.group({
      name: [this.data.project?.name || '', [Validators.required]],
      description: [this.data.project?.description || '', []]
    })
  }

  ngOnInit(): void {

  }


  public closeDialog(result?: any): void {
    this.dialogRef.close(result);
  }

  public addProject(): void {
    this.isLoading = true;
    let groupValue = this.newProjectGroup.value;
    groupValue.parent = this.data.parent.id;
    if (this.data.project) {
      this.projectService.changeProject(this.data.project.id, {
        name: groupValue.name,
        description: groupValue.description
      }).subscribe(update => {
        this.isLoading = false;
        this.toastNotificationService.showNotification({type: "success", message: "Project updated Successfully"});
        this.closeDialog(update);
      }, error => {
        this.isLoading = false;
        let errorMsg = 'An error occurred while saving changes to the project.'
        this.toastNotificationService.showNotification({type: "error", message: findFirstNonEmptyString(error.error) || errorMsg})
      })
    } else this.projectService.createProject(groupValue).subscribe(newProject => {
      this.isLoading = false;
      this.toastNotificationService.showNotification({type: "success", message: "Project Created Successfully"});
      this.closeDialog(newProject);
    }, error => {
      this.isLoading = false;
      let errorMsg = 'An error occurred while creating the project.'
      this.toastNotificationService.showNotification({type: "error", message: findFirstNonEmptyString(error.error) || errorMsg})
    })
  }
}
