import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, switchMap, timer } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PagedData } from "../models/general.models";
import { Permission, Role, TeamMember } from "../models/team.model";
import { TokenResponse } from "./interceptors/auth-interceptor.service";


@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseApiService {

  public currentUser:User;
  public mySlug: string;
  public myId!: number;  // teams id


  constructor(http: HttpClient, router: Router) {
    //this.startTokenRefresh();
    super(http, router);
    this.currentAccessToken = localStorage.getItem('access_token');
    this.currentRefreshToken = localStorage.getItem('refresh_token');
    this.currentUser = JSON.parse(localStorage.getItem('user')) as User;
    this.mySlug = this.currentUser?.teams[0].slug;
  }

  public clearAuthData() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    this.currentUser = null;
    this.currentAccessToken = null;
    this.currentRefreshToken = null;
    this.mySlug = null;
  }

  public setUserData (token: TokenResponse) {
    this.setTokenPair(token);
    this.currentUser = token.user;
    this.mySlug = token.user?.teams[0].slug;
    localStorage.setItem('user', JSON.stringify(token.user));

  }
  sendInvite(inviteData: {
    is_accepted: boolean;
    role: string;
    team: any;
    email: string;
  } ): Observable<any> {
    return this.http.post(`${this.baseUrl}/{team_slug}/team/api/invitations/`, inviteData);
  }

  getTeamUsers(): Observable<any> {
    return this.http.get(`${this.baseUrl}/{team_slug}/team/memberships/`);
  }

  searchTeamUsers(query: string): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${this.baseUrl}/{team_slug}/team/members/search/?q=${query}`);
  }

  updateTeamUser(body: any, memberId: number): Observable<TeamMember> {
    return this.http.patch<TeamMember> (`${this.baseUrl}/{team_slug}/team/memberships/${memberId}/`, body);
  }

  removeTeamUser(memberId: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/{team_slug}/team/memberships/${memberId}/`);
  }

  getTeamUser(memberId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/{team_slug}/team/memberships/${memberId}/`);
  }

  getTeamRoles(): Observable<PagedData<Role>> {
    return this.http.get<PagedData<Role>>(`${this.baseUrl}/{team_slug}/team/roles/`);
  }

  createRole(role: {name: string, permissions_ids: number[]}): Observable<any> {
    return this.http.post(`${this.baseUrl}/{team_slug}/team/roles/`, role);
  }

  getPermissions(): Observable<PagedData<Permission>> {
    return this.http.get<PagedData<Permission>> (`${this.baseUrl}/teams/permissions/`);
  }
}