import { distinctUntilChanged, Observable, scan } from "rxjs";
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from "@angular/common/http";
import { Download } from "./models/general.models";

export function isDefinedAndNotNull(value: any): boolean {
  return typeof value !== 'undefined' && value !== null;
}

function getValue(target: any, key: string) {
  return key.split('.').reduce((acc, curr) => {
    return isDefinedAndNotNull(acc) && isDefinedAndNotNull(acc[curr]) ? acc[curr] : undefined;
  }, target);
}

export function truncate(input: string, length = 10) {
  if (input.length > length) {
    return input.substring(0, length) + '...';
  }
  return input;
}

export function saveBlob(data: BlobPart, filename: string) {
  const file = new Blob([data], { type: '' });
  const fileURL = URL.createObjectURL(file);
  const a         = document.createElement('a');
  a.href        = fileURL;
  a.target      = '_blank';
  a.download    = changeExceptLastPeriod(filename);
  document.body.appendChild(a);
  a.click();
}

function changeExceptLastPeriod(str: string) {
  const lastPeriodIndex = str.lastIndexOf('.');
  if (lastPeriodIndex === -1) {
    return str;
  }
  const beforeLastPeriod = str.slice(0, lastPeriodIndex);
  const afterLastPeriod = str.slice(lastPeriodIndex);

  const transformedPart = beforeLastPeriod.replace(/\./g, '_');
  return transformedPart + afterLastPeriod;
}

export function compareArrays (array1: Array<any>, array2: Array<any>) {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index])
}

export function findFirstNonEmptyString(obj: any): string | null {
  function isNonEmptyString(value: any): value is string {
    return typeof value === 'string' && value.trim() !== '';
  }
  function traverse(value: any): string | null {
    if (isNonEmptyString(value)) {
      return value;
    } else if (Array.isArray(value)) {
      for (let item of value) {
        const result = traverse(item);
        if (result) return result;
      }
    } else if (typeof value === 'object' && value !== null) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          const result = traverse(value[key]);
          if (result) return result;
        }
      }
    }
    return null;
  }
  return traverse(obj);
}

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  );
}
export function download(
  saver?: (b: Blob) => void
): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
  return (source: Observable<HttpEvent<Blob>>) =>
    source.pipe(
      scan(
        (download: Download, event): Download => {
          if (isHttpProgressEvent(event)) {
            return {
              total: event.total,
              loaded: event.loaded,
              progress: event.total
                ? Math.round((100 * event.loaded) / event.total)
                : +download.progress,
              state: "IN_PROGRESS",
              content: null
            };
          }
          if (isHttpResponse(event)) {
            if (saver) {
              saver(event.body);
            }
            return {
              total: event.body.size,
              loaded: event.body.size,
              progress: 100,
              state: "DONE",
              content: event.body
            };
          }
          return download;
        },
        { state: "PENDING", progress: 0, content: null, loaded: 0, total: 0 }
      ),
      distinctUntilChanged((a, b) => a.state === b.state
        && a.progress === b.progress
        && a.content === b.content
      )
    );
}