import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { SaveAreaDialogComponent } from "./components/save-area-dialog/save-area-dialog.component";
import { FeatureGeometry } from "../../models/area-item.model";
import { Layer } from "../../models/layer.models";
import { MapCommunicatorService } from "./map-communicator.service";
import { Project } from "../../models/project.module";

@Component({
  selector: 'frmg-risk-map',
  templateUrl: './risk-map.component.html',
  styleUrls: ['./risk-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(-100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class RiskMapComponent implements OnDestroy{
  public isOpen = 'out';
  public action: string = 'selection';
  public drawTools = ['rectangle', 'circle', 'polygon', 'point', 'line'];
  public currentToolIcon = `${this.drawTools[2]}`;
  public currentDrawn: FeatureGeometry | null;
  public currentProject: Project;
  public layers$ = new Subject<Array<{
    active: boolean,
    opacity: number,
    layer: Layer
  }>>();
  public drawing: boolean;
  public savedState: any;

  constructor(private dialog: MatDialog,
              private cd: ChangeDetectorRef,
              private mapCommunicatorService: MapCommunicatorService) {
    this.drawTools.splice(1,1);
    this.mapCommunicatorService.mapCommunicator.subscribe(action => {

      if (action.startsWith('map_select_')) {
        if (this.isOpen !== 'areas') this.isOpen = 'areas';
        this.cd.detectChanges();
      }
    })
  }

  ngOnDestroy(): void {
    this.mapCommunicatorService.selectedAreas.next([]);
    }


  public toggle(state: string) {
    if (this.isOpen === state) {
      this.isOpen = 'out';
    } else this.isOpen = state;
  }

  public setDrawTool(type: string): void {
    const action = `draw_${type}`;
    this.mapCommunicatorService.mapCommunicator.next(action);
    this.action = action;
    this.currentToolIcon = `${type}`;
    this.drawing = true;
  }

  public selectionState(): void {
    this.mapCommunicatorService.mapCommunicator.next('selection');
    this.action = this.action = 'selection' ;
    this.drawing = false;
  }

  public saveDrawnAreas(): void {
    if (!this.currentDrawn) return;
    const layer: FeatureGeometry = this.currentDrawn;
    const saveDialog = this.dialog.open(SaveAreaDialogComponent, {
      data: {layer:  layer,
      project: this.currentProject
      }
    })

    saveDialog.afterClosed().subscribe(resp=>{
      if (resp) {
        this.selectionState();
        this.clearDrawnAreas();
      }
    })
  }

  public clearDrawnAreas(): void {
    if (this.currentProject?.path == 'root') return;
    this.mapCommunicatorService.mapCommunicator.next('clear');
    this.currentDrawn = null;
    this.drawing = false;
    setTimeout(_=>{
      this.cd.detectChanges();
    }, 250)
  }

  public openDrawTools(): void {
    if (this.action !== "drawTool") {
      this.action = 'drawTool';
    } else this.action = '';
  }

  public onDrawEnd(feature: FeatureGeometry | null): void {
    this.currentDrawn = feature;
  }

  public projectSelect(project: Project | null): void {
    this.currentProject = project;
  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public zoomMap(type: string) {
    this.mapCommunicatorService.mapCommunicator.next('zoom_'+type);
  }

  public saveListState(state: any) {
    this.savedState= state
  }
}
