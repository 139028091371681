import { Component, Input, OnInit, signal } from '@angular/core';
import { ReportService } from "../../services/report.service";
import { RiskReport } from "../../models/report.model";
import { NavigationEnd, Router } from "@angular/router";
import { ShareModalComponent } from "../../components/share-modal/share-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { FormControl } from "@angular/forms";
import { ToastNotificationService } from "../../services/toast-notification.service";
import { NotificationService } from "../../services/notification.service";
import { getRiskColors } from "../../utils";
import { User } from "../../models/user.model";


@Component({
  selector: 'frmg-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  @Input() id!: string;

  public displayedColumns = ["name", "address__display_name", "first_risk_score", "first_input_point_value", "distance", "action"]
  public displayedColumnsDrag: string[] = [];
  public report: RiskReport;
  public colors: string[] = getRiskColors();
  public threshold: string = '0 - 200';
  public reportNameCtrl: FormControl = new FormControl<string>('');
  public editMode: boolean = false;
  public loadingData = signal(false);
  public breadCrumbs: string[];
  private allUrl = '';
  private collUrl = '';
  public depthLvl = 1;
  public currentUser: User;

  constructor(private reportService: ReportService,
              private router: Router,
              private dialog: MatDialog,
              private toastNotificationService: ToastNotificationService,
              private notificationService: NotificationService) {
    this.currentUser = JSON.parse(localStorage.getItem('user')) as User;
    this.displayedColumnsDrag = this.displayedColumns.slice(1, -1);
    this.reportService.breadCrumbs.subscribe(resp => {
      this.breadCrumbs = resp;
    })
    this.parseUrl();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.parseUrl();
      }
    })
  }

  private parseUrl() {
    const splits = this.router.url.split('/');
    this.allUrl = splits[2];
    const findCollectionIndex = splits.findIndex(url => url == 'collection');
    if (splits.find(url => url == 'details')) {
      this.depthLvl = 3;
    } else if (findCollectionIndex !== -1) {
      this.collUrl = splits[findCollectionIndex + 1];
      this.depthLvl = 2;
    } else {
      this.depthLvl = 1;
    }
  }

  ngOnInit() {
    this.getReport();
  }

  navigateBreadCrumbs(toCollection: boolean, skip: boolean) {
    if (skip) return;
    if (toCollection) {
      this.router.navigate([`/report/${this.allUrl}/collection/${this.collUrl}`])
    } else {
      this.router.navigate([`/report/${this.allUrl}/`])
    }

  }

  public deleteReport(report: RiskReport, event: Event): void {
    event.stopPropagation();
    this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, {
      data: {
        header: 'Are you sure you want to delete this report?',
        body: 'Once deleted, this report cannot be viewed.',
        confirmText: 'Delete'
      }
    }).afterClosed().subscribe(del => {
      if (del) {
        this.reportService.deleteReport(report.id).subscribe(() => {
          this.goBack();
        })
      }
    })
  }

  public unShareReport(report: RiskReport, event: Event): void {
    event.stopPropagation();
    this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, {
      data: {
        header: 'Are you sure you want to remove this report from the list?',
        body: 'This report will be removed from your list. Users with access will still be able to view it.',
        confirmText: 'Yes, remove folder',
        cancelText: 'No'
      }
    }).afterClosed().subscribe(del => {
      if (del) this.reportService.leaveSharedProject(report.id).subscribe(() => {
        this.goBack();
      })
    })
  }

  private getReport(): void {
    this.loadingData.set(true)
    this.reportService.getReport(this.id).subscribe(report => {
      this.report = report;
      this.reportNameCtrl.setValue(this.report.name);
    })
  }

  public download(id: string, filename: string = 'report',): void {
    this.reportService.getReportPdf(id, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename + '.pdf',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
      // saveBlob(data, filename+'.pdf')
    })

  }

  public downloadXLSX(id: string, filename: string = 'report'): void {
    this.reportService.getReportXLSX(id, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename + '.xlsx',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
    })
  }

  public goBack() {
    this.router.navigate([`/report-table`])
  }

  public share(report: RiskReport, event: Event): void {
    event.stopPropagation();
    this.dialog.open(ShareModalComponent<RiskReport>, {
      data: {
        entity: report
      }
    }).afterClosed().subscribe(() => {
      this.reportService.getReport(this.id).subscribe(report => {
        this.report.shared_with = report.shared_with;
      })
    })
  }


  public enterEditMode() {
    this.editMode = !this.editMode;
  }

  public updateReportName() {
    this.reportService.updateReport(this.report.id, {name: this.reportNameCtrl.value}).subscribe(_ => {
      this.report.name = this.reportNameCtrl.value;
      this.enterEditMode();
    }, error => {
      this.toastNotificationService.showNotification({type: "error", message: 'Could not update report name'})
    })
  }

}
