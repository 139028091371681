<div class="api-keys-settings">
  <div class="api-keys-header">
    <h3 class="api-keys-title">API Keys</h3>
  </div>
  <div class="api-keys-content">
    <table class="api-keys-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Created</th>
<!--          <th>Revoked</th>-->
<!--          <th>Expiry Date</th>-->
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let key of apiKeys">
          <td>
            <div class="api-key-id-wrapper">
              <span class="api-key-id">{{ key.id }}</span>
            </div>
          </td>
          <td>{{ key.created | date: 'MMM d, y' }}</td>
<!--          <td>{{ key.revoked ? 'Yes' : 'No' }}</td>-->
<!--          <td>{{ key.expiry_date | date: 'MMM d, y' }}</td>-->
          <td class="actions-cell">
            <div class="middle-button"><button class="button" (click)="revokeApiKey(key.id)">Revoke</button></div>
            <div class="middle-button">
                <button class="button icon-copy" (click)="copyToClipboard(key.id)">
            </button></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="api-keys-actions">
    <button mat-button class="frmg-primary-button" ><div class="new-api-key-button" (click)="createApiKey()">New API Keys</div></button>
  </div>
</div>