import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {PagedData} from "../models/general.models";
import {UserService} from "./user.service";
import { Group } from "../models/general.models";


@Injectable({
  providedIn: 'root'
})
export class GroupService {

  private baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  public searchGroup(search: string, page: number = 1): Observable<Array<Group>> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/groups/search/?page=${page}&q=${search}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<Array<Group>>(endpoint, {headers});
  }

  public createGroup(body: Group): Observable<Group> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/groups/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.post<Group>(endpoint, body, {headers});
  }
}
