import {User} from "./user.model";
import {Team} from "./team.model";
import { Area, AreaProperties } from "./general.models";
import { Project } from "./project.module";

export interface Task {
  task_id: string,
  url: string,
  status: TaskStatus,
  type: TaskType,
  result: string,
  user: Partial<User>,
  team: Partial<Team>,
  area: Partial<AreaProperties>,
  created_at: string,
  uploaded_at: string
}

export interface TaskGroup {
  id: string,
  name: string,
  user: User,
  team: Team,
  project: Project,
  total_tasks: number,
  completed_tasks: number,
  status: TaskStatus,
  created_at: string,
  url: string
}

export interface DownloadTaskStatus {
  progress: number,
  status: string
}

export interface AreaTaskGroup extends TaskGroup {
  tasks: Array<Task>,
  area: Area
}

export enum TaskStatus {
  PENDING = 'pending',
  STARTED = 'started',
  PROGRESS = 'progress',
  RETRY = 'retry',
  COMPLETED = 'completed',
  COMPLETED_WITH_ERRORS = 'completed_with_errors',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export enum TaskType {
  UPLOAD = 'upload'
}

export const taskStatusTranslations = new Map<TaskStatus, string>(
  [
    [TaskStatus.PENDING, "task.pending"],
    [TaskStatus.STARTED, "task.started"],
    [TaskStatus.PROGRESS, "task.progress"],
    [TaskStatus.RETRY, "task.retry"],
    [TaskStatus.COMPLETED, "task.completed"],
    [TaskStatus.COMPLETED_WITH_ERRORS, "task.completed_with_errors"],
    [TaskStatus.FAILED, "task.failed"],
    [TaskStatus.CANCELLED, "task.cancelled"],
  ]
)

export interface TaskStatistic {
  pending: number,
  started: number,
  progress: number,
  retry: number,
  completed: number,
  completed_with_errors: number,
  failed: number,
  cancelled: number
}


