import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl } from "@angular/forms";
import { Area } from "../../models/general.models";
import { debounceTime, map, mergeMap, Observable, of } from "rxjs";
import { User } from "../../models/user.model";
import { catchError, filter } from "rxjs/operators";
import { UserService } from "../../services/user.service";
import { ProjectService } from "../../services/project.service";
import { Project } from "../../models/project.module";
import { ReportService } from "../../services/report.service";
import { ToastNotificationService } from "../../services/toast-notification.service";

@Component({
  selector: 'frmg-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent<T> implements OnInit {
  public userSearchCtrl: FormControl;
  public entityName: string;
  public users$: Observable<Array<User>>;
  public sharedUsers: User[] = [];
  public currentUser: User;
  public type: string;

  constructor(
    private dialogRef: MatDialogRef<ShareModalComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: { entity: any },
    private fb: FormBuilder,
    private userService: UserService,
    private toastNotificationService: ToastNotificationService,
    private projectService: ProjectService,
    private reportService: ReportService
  ) {
    this.userSearchCtrl = this.fb.control('');
    this.currentUser = JSON.parse(localStorage.getItem('user')) as User;

    // @ts-ignore
    this.entityName =  this.data.entity.name;
    this.type = this.data.entity.path ? 'project' : 'report';
    this.users$ = this.userSearchCtrl.valueChanges.pipe(
      debounceTime(500),
      mergeMap(query => query? this.userService.searchTeamUsers(query).pipe(
          map(users => {
            return users.filter(user => !this.sharedUsers.find(el => el.email === user.email))
          }),
          catchError(err => of([])),
        ) : of([])
      ),
    )

    this.sharedUsers.push(this.data.entity.user)
    // @ts-ignore
    if (this.data.entity.shared_with) { this.data.entity.shared_with.forEach(user=>{
      this.sharedUsers.push(user)
    });

      // this.sharedUsers.push((this.data.entity as Area).properties.user);
    }
  }

  ngOnInit(): void {

  }


  public closeDialog(result?: any): void {
    this.dialogRef.close(result);
  }

  displayName(option: User): string {
    return option ? option.email : '';
  }

  public share(): void {
    const user = this.userSearchCtrl.value;
    let request;
    if (this.type === 'project') {
      request = this.projectService.shareProject({user_id: user.user_id}, (this.data.entity as Project).id)
    } else {
      request = this.reportService.shareReport({user_id: user.user_id}, (this.data.entity as Project).id)
    }
    request.subscribe(()=>{
      this.sharedUsers.push(user as User);
      this.userSearchCtrl.setValue(null, {emitEvent: true});
    },(error) => {
      const errorMsg = error.error.details;
      this.toastNotificationService.showNotification({type: "error", message: errorMsg})
    })
  }


  public revoke(index: number, user: User): void {
    let request;
    if (this.type === 'project') {
      request = this.projectService.removeShareProject({user_id: user.user_id || user.id}, (this.data.entity as Project).id)
    } else {
      request = this.reportService.removeShareReport({user_id: user.user_id || user.id}, (this.data.entity as Project).id)
    }
    request.subscribe(resp => {
      this.sharedUsers.splice(index, 1);
    },(error) => {
      const errorMsg = error.error.details;
      this.toastNotificationService.showNotification({type: "error", message: errorMsg})
    })
  }
}
