import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl } from "@angular/forms";
import { format } from "ol/coordinate";

@Component({
  selector: 'frmg-download-report-dialog',
  templateUrl: './download-report-dialog.component.html',
  styleUrls: ['./download-report-dialog.component.scss']
})
export class DownloadReportDialogComponent  {
  public isLoading = false;
  public format = 'pdf';
  public metadataCtrl: FormControl;

  constructor(
    private dialogRef: MatDialogRef<DownloadReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder) {
    this.metadataCtrl = this.fb.control(this.data.selected);
  }


  public closeDialog(result: any): void {
    this.dialogRef.close(result);
  }

  public delete(): void {
    this.isLoading = true;
    this.dialogRef.close({meta: this.metadataCtrl.value, type: this.format})
  }
}
