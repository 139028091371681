<div class="content">
    <img class="logo" src="assets/images/skyforest.svg" alt="skyforest">
    <router-outlet></router-outlet>
    <div class="content-help"  *ngIf="isLogin">
        <span>Having trouble logging in?</span>
        <span>Contact us <a href="mailto:support@skyforest.io">support@skyforest.io</a></span>
    </div>
</div>
<div class="footer">
    <div class="footer-info">
        <span class="copyright">© {{ currentYear }} Skyforest</span>
        <span (click)="goToContact()">Contact</span>
<!--        <span>Privacy & Terms</span>-->
    </div>

    <div class="footer-socials">
        <a href="https://x.com/SKYFOREST_eo">
            <img src="assets/icons/socials/twitter.svg" alt="twitter">
        </a>
        <a href="https://www.linkedin.com/company/skyforest/?viewAsMember=true">
            <img src="assets/icons/socials/linkedin.svg" alt="linkedin">
        </a>
        <a href="https://www.instagram.com/skyforest_eo/?hl=en">
            <img src="assets/icons/socials/instagram.svg" alt="instagram">
        </a>
        <a href="https://www.youtube.com/channel/UCfkD8UkFPWUmBVwlKuhJ8ww">
            <img src="assets/icons/socials/youtube.svg" alt="youtube">
        </a>
    </div>
</div>

