<!-- <nav>
    <button type="button" (click)="toggle()">Toggle Open/Close</button>
</nav> -->

<div [@openCloseBottomDrawer]="{
    value: isOpen,
    params: {
        heightOpen: heightOpen,
        opacityOpen: opacityOpen,
        colorOpen: colorOpen,
        bottomOpen: bottomOpen,
        
        heightClosed: heightClosed,
        opacityClosed: opacityClosed,
        colorClosed: colorClosed,
        
        bottomClosed: bottomClosed}
    }" 
    class="bottom-drawer-container"

    [style.left]="offsetLeft">
    <!--  [style.width.%]="widthPercent-.1" -->
    <!-- [style.width]="widthPixels + 'px'"  -->
    <!-- the header controls -->
    <div class="drawer-control-header-wrapper">
        <div 
                    class="control-button-icon-div interact-blue"
                    (click)="toggleDrawer('toggle')" 
                    aria-label="'bottom-drawer.open-control-icon.aria-label' | translate"
                    [matTooltip]="'bottom-drawer.open-control-icon.tool-tip' | translate"
                    [matTooltipPosition]="'right'">
                    <!-- <mat-icon>home</mat-icon> -->
                    <div class="material-symbols-outlined">table</div>
                    <!-- <img src="../../../assets/icons/group-3357913.svg" alt="" width="20px" height="20px"> -->
                </div>
    </div>

    <!-- transpile menu contents here -->
    <!-- <ng-content></ng-content> -->
    
    <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">apartment</mat-icon>
            Building Structures
          </ng-template>
        </mat-tab>
      
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">fireplace</mat-icon>
            Fuel Type
          </ng-template>
          
        </mat-tab>
      
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">exposure</mat-icon>
            Exposure
          </ng-template>
      
          
        </mat-tab>
      </mat-tab-group>

    
</div>

