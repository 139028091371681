<div class="download-header">
    <p>
        {{ "downloads.my-downloads" | translate }}
    </p>
<!--    <button mat-raised-button  class="frmg-primary-button"-->
<!--            (click)="getTasks()">-->
<!--        <span class="material-symbols-outlined action-icon">sync</span>Sync Now</button>-->
</div>

<!--<div class="download-summary">-->
<!--    <div class="summary-header">-->
<!--        {{ "downloads.request-stats" | translate }}-->
<!--    </div>-->
<!--    <div class="summary-container">-->
<!--        <ng-template [ngTemplateOutlet]="summaryCard"-->
<!--                     [ngTemplateOutletContext]="{-->
<!--        value: statistic.completed,-->
<!--        title: 'downloads.processed',-->
<!--        icon: 'new_releases'-->
<!--        }"></ng-template>-->
<!--        <ng-template [ngTemplateOutlet]="summaryCard"-->
<!--                     [ngTemplateOutletContext]="{-->
<!--        value: statistic.started,-->
<!--        title: 'downloads.processing',-->
<!--        icon: 'progress_activity'-->
<!--        }">-->
<!--        </ng-template>-->
<!--&lt;!&ndash;        <ng-template [ngTemplateOutlet]="summaryCard"&ndash;&gt;-->
<!--&lt;!&ndash;                     [ngTemplateOutletContext]="{&ndash;&gt;-->
<!--&lt;!&ndash;        value: statistic.cancelled,&ndash;&gt;-->
<!--&lt;!&ndash;        title: 'downloads.canceled',&ndash;&gt;-->
<!--&lt;!&ndash;        icon: 'hide_source'&ndash;&gt;-->
<!--&lt;!&ndash;        }">&ndash;&gt;-->
<!--&lt;!&ndash;        </ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;        <ng-template [ngTemplateOutlet]="summaryCard"&ndash;&gt;-->
<!--&lt;!&ndash;                     [ngTemplateOutletContext]="{&ndash;&gt;-->
<!--&lt;!&ndash;        value: statistic.failed,&ndash;&gt;-->
<!--&lt;!&ndash;        title: 'downloads.expired',&ndash;&gt;-->
<!--&lt;!&ndash;        icon: 'timer_off'&ndash;&gt;-->
<!--&lt;!&ndash;        }"></ng-template>&ndash;&gt;-->

<!--    </div>-->
<!--</div>-->

<div class="download-table">
    <div class="table-header">
        All requests
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="taskData" class="mat-elevation-z8" matSort>
            <ng-container matColumnDef="area">
                <mat-header-cell *matHeaderCellDef > {{"downloads.table.area" | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element"
                          [matTooltip]="element.area ? element.area.name: element.name">
                    {{truncate(element.name, 50)}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="total_aois">
                <mat-header-cell *matHeaderCellDef > Total AIOS </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.total_tasks}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="layer">
                <mat-header-cell *matHeaderCellDef > {{"downloads.table.layer" | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.layer && element.layer.display_name}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef > {{"downloads.table.created_at" | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at | date: "dd/M/yyyy, HH:mm"}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef > {{"downloads.table.status" | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                    <div class="status-block">
                        <span [ngClass]="statusClass(element)"></span>
                        <span>{{element.status === 'pending'? (element.completed_tasks+"/"+element.total_tasks) : ''}} {{taskStatusTranslations.get(element.status) | translate}}</span>
                    </div>

                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef ></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-raised-button class="frmg-primary-button"
                            (click)="download(element, $event)">
                        <span class="material-symbols-outlined action-icon">download</span> Download</button>
                    <button mat-raised-button class="frmg-secondary-button"
                            (click)="deleteDownload(element, $event)">
                        <span class="icon-remove-icon"></span></button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToTask(row)"></mat-row>
        </table>
    </div>
    <mat-divider ></mat-divider>
    <mat-paginator
                   [length]="totalElements"
                   [pageIndex]="currentPage-1"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[50, 100, 15]"
                   [showFirstLastButtons]="true"
                   (page)="handlePageEvent($event)"
    ></mat-paginator>

</div>

<!--<ng-template #summaryCard let-value="value" let-title="title" let-icon="icon">-->
<!--    <div class="summary-card">-->
<!--        <div class="card-info">-->
<!--            <p class="card-reading">{{ value }}</p>-->
<!--            <p class="card-title">{{ title | translate }}</p>-->
<!--        </div>-->
<!--        <div class="card-icon">-->
<!--            <span class="material-symbols-outlined">-->
<!--                {{ icon }}-->
<!--            </span>-->
<!--        </div>-->
<!--    </div>-->
<!--</ng-template>-->
