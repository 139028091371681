import {LayerModel, LayerTypeEnum} from '../../models/map-layer.model'

export const frmgLayersConfig: LayerModel[] = [
    {
        layerName: 'Exposure',
        visibleState: false,
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.FRMG,
        thumbUrl: 'assets/images/thumbs/exposure-sketch.jpeg',
        endpointUrl: 'exposure',
        projection: 'EPSG:3857',
        opacity: .2,
        colorMapArray: [
            { color: '#b5b5b5', position: 0 },
            { color: '#ffe17d', position: 1 },
            { color: '#ff964b', position: 2 },
            { color: '#eb4e5a', position: 3 },
            { color: '#4a0000', position: 4 }
        ],
        opacityControlDownload: false
    },
    {
        layerName: 'Fuel Hazard',
        visibleState: false,
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.FRMG,
        thumbUrl: 'assets/images/thumbs/fuel-hazard-sketch.jpeg',
        endpointUrl: 'fuelhazard',
        projection: 'EPSG:3857',
        opacity: .2,
        colorMap: 'rdylgn',
        colorMapArray: [
            { color: '#b5b5b5', position: 0 },
            { color: '#b5b5b5', position: .25 },
            { color: '#ffe17d', position: .25 },
            { color: '#ffe17d', position: .5 },
            { color: '#ff964b', position: .5 },
            { color: '#ff964b', position: .75 },
            { color: '#eb4e5a', position: .75 },
            { color: '#eb4e5a', position: 1 }
        ],
        opacityControlDownload: false
    },
    {
        layerName: 'Fuel Type',
        visibleState: false,
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.FRMG,
        thumbUrl: 'assets/images/thumbs/fuel-type-sketch.jpeg',
        endpointUrl: 'fueltype',
        projection: 'EPSG:3857',
        opacity: .2,
        colorMap: 'spectral',
        colorMapArray: [
            { color: '#9cafba', position: 0 },
            { color: '#9cafba', position: .2 },
            { color: '#c7c28b', position: .2 },
            { color: '#c7c28b', position: .4 },
            { color: '#609b5b', position: .4 },
            { color: '#609b5b', position: .6 },
            { color: '#efa038', position: .6 },
            { color: '#efa038', position: .8 },
            { color: '#bd2528', position: .8 },
            { color: '#bd2528', position: 1 }
        ],
        opacityControlDownload: false
    },
    {
        layerName: 'Canopy Height',
        visibleState: false,
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.FRMG,
        thumbUrl: 'assets/images/thumbs/fuel-type-sketch.jpeg',
        endpointUrl: 'chm',
        projection: 'EPSG:3857',
        opacity: .2,
        colorMap: 'spectral',
        colorMapArray: [
            { color: '#000000', position: 0 },
            { color: '#4b0082', position: .333 },
            { color: '#ff4500', position: .666 },
            { color: '#ff4500', position: 1 }
        ],
        opacityControlDownload: false
    },

    {
        layerName: 'Hazard Distance',
        visibleState: false,
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.FRMG,
        thumbUrl: 'assets/images/thumbs/fuel-type-sketch.jpeg',
        endpointUrl: 'disttohazard',
        projection: 'EPSG:3857',
        opacity: .2,
        colorMap: 'spectral',
        colorMapArray: [
            { color: '#940000', position: 0 },
            { color: '#a22121', position: 260 },
            { color: '#b04242', position: 520 },
            { color: '#be6363', position: 780 },
            { color: '#cc8585', position: 1040 },
            { color: '#daa6a6', position: 1300 },
            { color: '#e7c7c7', position: 1560 },
            { color: '#f4e6e6', position: 1800 },
            { color: '#ffffff', position: 2000 },
        ],
        opacityControlDownload: false
    },

    {
        layerName: 'Percent Conifer Basal Area',
        visibleState: false,
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.FRMG,
        thumbUrl: 'assets/images/thumbs/fuel-type-sketch.jpeg',
        endpointUrl: 'psba',
        projection: 'EPSG:3857',
        opacity: .2,
        colorMap: 'spectral',
        colorMapArray: [
            { color: '#0a5612', position: 0 },
            { color: '#ded751', position: 50 },
            { color: '#d7191c', position: 100 }
        ],
        opacityControlDownload: false
    },

    /*  basemaps  */

    
];

export const basemapLayersConfig: LayerModel[] = [
    {
        layerName: 'Topographic',
        visibleState: true,
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.Base,
        thumbUrl: 'assets/images/thumbs/topographic-basemap.jpg',
        endpointUrl: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        projection: 'EPSG:3857',
        opacity: 1,
        colorMap: '',
        attribution: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
    },
    {
        layerName: 'Satellite',
        visibleState: true,
        deletable: false,
        editable: false,
        downloadable: false,
        type: LayerTypeEnum.Base,
        thumbUrl: 'assets/images/thumbs/satellite-basemap.jpg',
        endpointUrl: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        projection: 'EPSG:3857',
        opacity: 1,
        colorMap: '',
        attribution: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer">ArcGIS</a>',
    },

    

    
];