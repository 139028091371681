import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { download, saveBlob, truncate } from "../../utils";
import { PageEvent } from "@angular/material/paginator";
import { ReportService } from "../../services/report.service";
import { Router } from "@angular/router";
import { RiskReport } from "../../models/report.model";
import { ShareModalComponent } from "../../components/share-modal/share-modal.component";
import { ConfirmationDialogComponent } from "../../components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { User } from "../../models/user.model";
import { ToastNotificationService } from "../../services/toast-notification.service";
import { Sort } from "@angular/material/sort";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { SAVER, Saver } from "../../services/providers/saver.provider";
import { NotificationService } from "../../services/notification.service";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import { ChildProject } from "../../models/project.module";

@Component({
  selector: 'frmg-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit, OnDestroy {

  public displayedColumns = ["name", "created_at", "area_count", "count", "status", "shared", "action"]
  public reportData: Array<RiskReport> = [];
  public truncate = truncate;
  public currentUser: User;
  public currentPage = 1;
  public pageSize = 20;
  public totalElements = 0;
  private updateTimeout: any = null;
  private sort: Sort = {active: 'created_at', direction: 'desc'};
  public reportSearchCtrl: FormControl = new FormControl<string>('');
  public errorText = 'No data for the selected range';

  public menuOpen: MatMenuTrigger;
  public hoverId: number;
  public menuState: string;

  constructor(private reportService: ReportService,
              private router: Router,
              private dialog: MatDialog,
              private toastNotificationService: ToastNotificationService,
              private notificationService: NotificationService,
              @Inject(SAVER) private save: Saver) {
    this.currentUser = JSON.parse(localStorage.getItem('user')) as User;
  }

  ngOnDestroy(): void {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }
  }

  ngOnInit() {
    this.getReports();
    this.reportSearchCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(resp => {
      this.getReports();
    })
  }

  sortData(sort: Sort) {
    this.sort = sort;
    this.getReports();
  }

  public moreUsers(project: RiskReport): User {
    if (project.shared_with.length == 3) {
      return project.shared_with[2];
    } else {
      return {
        id: 0,
        teams: [],
        email: `+${project.shared_with.length - 2}`
      }
    }

  }

  private getReports(): void {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = null;
    }
    let config: any = {page: this.currentPage, page_size: this.pageSize}
    config.ordering = (this.sort.direction === 'asc' ? '' : '-') + this.sort.active;
    const searchStr = this.reportSearchCtrl.value.toLowerCase();
    if (searchStr) {
      config.name = searchStr;
    }
    this.reportService.getReports(config).subscribe(result => {
      this.totalElements = result.count;
      this.reportData = result.results;

      if (this.reportData.some(report => report.status === 'processing')) {
        this.updateTimeout = setTimeout(() => this.getReports(), 3000);
      }
    })
  }

  public downloadPdf(id: string, filename: string = 'report', event: Event): void {
    this.reportService.getReportPdf(id, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename+'.pdf',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
    })
  }

  public downloadXLSX(id: string, filename: string = 'report', event: Event): void {
    this.reportService.getReportXLSX(id, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename+'.xlsx',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
    })
  }

  public share(report: RiskReport, event: Event): void {
    event.stopPropagation();
    this.dialog.open(ShareModalComponent<RiskReport>, {
      data: {
        entity: report
      }
    }).afterClosed().subscribe(() => {
      this.getReports()
    })
  }

  public deleteReport(report: RiskReport, event: Event): void {
    event.stopPropagation();
    this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, {
      data: {
        header: 'Are you sure you want to delete this report?',
        body: 'Deleting this report will permanently remove it and it cannot be restored. Users with access will no longer be able to view this report.',
        confirmText: 'Delete'
      }
    }).afterClosed().subscribe(del => {
      if (del) this.reportService.deleteReport(report.id).subscribe(() => {
        this.getReports();
      })
    })
  }
  public unShareReport(report: RiskReport, event: Event): void {
    event.stopPropagation();
    this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, {
      data: {
        header: 'Are you sure you want to remove this report from the list?',
        body: 'This report will be removed from your list. Users with access will still be able to view it.',
        confirmText: 'Yes, remove folder',
        cancelText: 'No'
      }
    }).afterClosed().subscribe(del => {
      if (del) this.reportService.leaveSharedProject(report.id).subscribe(() => {
        this.getReports();
      })
    })
  }

  public getUsersWithAccess(report: RiskReport) {
    switch (this.currentUser.email) {
      case report.user.email:
        return report.shared_with;
      default:
        return [report.user, ...report.shared_with];
    }
  }


  public handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.currentPage = e.pageIndex + 1;
    this.getReports();
  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public goToReport(report: RiskReport) {
    if (report.status === 'completed') {
      this.router.navigate([`/report/${report.id}`])
    } else if (report.status === 'failed') {
      this.toastNotificationService.showNotification({
        type: "error",
        message: this.errorText
      })
    } else {
      this.reportService.getReport(report.id).subscribe(updatedReport => {
        if (updatedReport.status === 'completed') {
          this.router.navigate([`/report/${updatedReport.id}`])
        } else if (updatedReport.status === 'failed') {
          this.toastNotificationService.showNotification({
            type: "error",
            message: this.errorText
          })
        } else {
          this.toastNotificationService.showNotification({type: "error", message: 'This report is still processing'})
        }
      })
    }
  }

  public statusClass(report: RiskReport) {
    switch (report.status) {
      case 'completed':
        return 'icon-Processed';
      case 'processing':
        return 'icon-Processing';
      default:
        return 'icon-Canceled'
    }
  }

  public reportTrackBy(index: number, report: RiskReport) {
    return report.id + report.progress
  }

  public setHoverId(index: number | null) {
    if (this.menuState) return;
    this.hoverId = index;
  }

  public toggleMenu(state: RiskReport, event?: MouseEvent) {
    event && event.stopPropagation();
    this.menuState = state?.id;
  }
}
