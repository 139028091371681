import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

// openlayers map specific additions

// alias Map to OlMap remove conflict with js Map
import OlMap from 'ol/Map';
import View from 'ol/View';
import { OSM, TileImage, TileWMS, Vector as VectorSource, XYZ } from 'ol/source';
import TileLayer from 'ol/layer/Tile';

//import { Draw, Modify, Snap } from 'ol/interaction';
import Draw, {createBox} from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import Modify from 'ol/interaction/Modify';
import Translate from 'ol/interaction/Translate';
import Select from 'ol/interaction/Select';
//import { GeoJSON } from 'ol/format.js'
import GeoJSON from 'ol/format/GeoJSON';
import KML from 'ol/format/KML';

//import {defaults as defaultInteractions} from 'ol/interaction.js';


import VectorLayer from 'ol/layer/Vector';
import { Projection, fromLonLat } from 'ol/proj';
import Point from 'ol/geom/Point';

// get some data
import oneGeo from '../../assets/mapData/onegeo.json';
//import oneGeo from '../../assets/mapData/testImport.json';
import states from '../../assets/mapData/states.json';
import { FeatureCollection, Geometry } from 'geojson';
import Feature, { FeatureLike } from 'ol/Feature';
import { Pixel } from 'ol/pixel';
import { FileVO, UploadService } from '../services/upload.service';
import { Observable, Subscription, skip } from 'rxjs';
import { SfState } from '../store/sf.state';
import { Store, Select as SelectX } from '@ngxs/store';
import { MapService } from '../services/map.service';
import { ImageTile, Tile } from 'ol';
import TileState from 'ol/TileState';
import { LayerMapContent, LayerModel, LayerStateChangeModel, LayerTypeEnum } from '../models/map-layer.model';
import { DataService } from '../services/data.service';

/* iniital default FRMG layers */

import { basemapLayersConfig, frmgLayersConfig } from './config/layerConfig';
import TileSource from 'ol/source/Tile';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DrawCompleteDialogComponent } from '../components/draw-complete-dialog/draw-complete-dialog.component';

// generate id hash for drawn elements
import { nanoid, customAlphabet } from 'nanoid'
import { DialogRef } from '@angular/cdk/dialog';
import { ToggleMapMenuAction } from '../store/sf.actions';
import { BaseApiService } from '../services/base-api.service';
import { UserService } from '../services/user.service';
import { style } from '@angular/animations';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';

@Component({
  selector: 'frmg-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss']
})
export class OlMapComponent implements OnInit, AfterViewInit, OnDestroy {

  public olMap!: OlMap;
  stateUrl = 'assets/mapData/states.json';
  oneGeoUrl = 'assets/mapData/onegeo.json';
  //oneGeoUrl = '../../assets/mapData/testImport.json';
  
  
  readonly TEMISKAMING: number[] = [-79.72925952787887, 47.48349019194862];
  readonly KANATA: number[] = [-75.97262121506466, 45.34114520970794];
  readonly SAULT: number[] = [-84.09579004434322, 48.13439611492869];
  draw!: Draw;
  snap!: Snap;
  modify!: Modify;

  drawSource!: VectorSource;
  drawLayer!: VectorLayer<VectorSource>;

  /* imported area file layer vector source  */
  importAreaVectorSource!: VectorSource;
  areaVectorLayer!: VectorLayer<VectorSource>;

  projection!: Projection

  subscriptions: Subscription;
  uploadedFile!: FileVO;
  uploadedFileData!: File;

  inDrawMode: boolean = false;

  highlightArray: Feature[] = [];

  // layer stuff todo move to configuration descriptors
  areaHiLiteOverlay!: VectorLayer<VectorSource>;
  
  /* config file holding static frmg layers */
  frmgLayers: LayerModel[] = frmgLayersConfig;
  basemapLayers: LayerModel[] = basemapLayersConfig;

  /* basic auth header for frmg layers on terracotta */
  frmgAuthHeader: string = 'Basic ' + btoa(environment.username + ':' + environment.password);

  /* layerMap to hold all active layers  */
  private layerMap: Map<string, LayerMapContent> = new Map<string, LayerMapContent>();

  /* subscribe to the polygon button */
  @SelectX(SfState.getPolygonToolState) polygonToolStateO$!: Observable<string>;

  // instance dialogRef for the draw complete, save dialog
  nameAreaDialogRef!: DialogRef;

  /* temporary hold for the single drawn feature */
  userDrawnShape!: Feature;

  constructor(
    private uploadService: UploadService,
    private store: Store,
    private mapService: MapService,
    private dataService: DataService,
    private baseApiService: BaseApiService,
    private userService: UserService,
    private httpClient: HttpClient,
    private nameAreaDialog: MatDialog
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {

    // if loading map, make sure the menu state in store reflects it so conditionals kick in
    this.store.dispatch(new ToggleMapMenuAction('open'));
      
      /* notif of new layers or layer deletes */
      this.subscriptions.add(
        this.mapService.getMapLayerChangeObservable()
          .subscribe((data) => {
            if (data.layerFileData) {
              this.uploadedFileData = data.layerFileData;
            }

            if (!data.layerFileData) { return }
            switch(data.type) {
              case LayerTypeEnum.GeoJSON:
                this.addImportedGeoJsonLayer(data.layerFileData || '');
                break;
              case LayerTypeEnum.KML:
                this.addImportedKmlLayer(data.layerFileData || '');
                break;
              default:
                return;
            }
          
          })
      );

      /* notif of state change for drawing tools */
      this.subscriptions.add(
        this.polygonToolStateO$
          .pipe(skip(1)) // skip the subscription emit
          .subscribe((state) => {
            if (state === 'unselected') {
              this.removeDrawInteractions();
              this.inDrawMode = false;
            } else {
              this.addDrawInteractions(state);
              this.inDrawMode = true;
            }
          })
      );

      /* changes to selected areas from either map or area list */
      this.subscriptions.add(
        this.mapService.getSelectedAreasObservable()
          .pipe(skip(1))
          .subscribe((selectedAreasVO) => {
            // now set the hilights
            const featureId = selectedAreasVO[0].id;
            const featureState = selectedAreasVO[0].isSelected;
            const selectedFeature = this.areaVectorLayer?.getSource()?.getFeatures().find((feature) => feature.getProperties()['id'] === featureId);
            if (selectedFeature) {
              selectedFeature.setProperties({'isSelected': featureState});
              this.setHighlightOnArea(selectedFeature);
            }
            
          })
      );

      /*  notif of changes to layer visibility or opacity */
      this.subscriptions.add(
        this.mapService.getLayerStateChangeObservable()
          .subscribe((stateChange: LayerStateChangeModel) => {
            switch(stateChange.property) {
              case 'visibleState': {
                this.layerMap.get(stateChange.layerName)?.olLayer.setVisible(stateChange.value as boolean);
                break;
              };
              case 'opacity': {
                this.layerMap.get(stateChange.layerName)?.olLayer.setOpacity(stateChange.value as number);
              }
            }
            
            

            // now index into layer array and update
          })
      )


      // create a vector source for the draw layher
      this.drawSource = new VectorSource({
        wrapX: false
      });

      // create a draw layer to hold the drawing source vectors
      this.drawLayer = new VectorLayer({
        source: this.drawSource
      })

      const select = new Select();

      const translate = new Translate({
        features: select.getFeatures(),
      });

      // init the map with a background tile layer and draw layer
      let mapCentre: number[] =  fromLonLat(this.SAULT);
      this.olMap = new OlMap({
        //interactions: defaultInteractions().extend([select, translate]),
        layers: [

          this.drawLayer,

        ],
        target: 'map',
        view: new View({
          center: mapCentre,
          zoom: 8, maxZoom: 22,
        }),
        controls: [],
    
      });

      /* api call to get saved areas */
      this.getSavedAreas();
      
  }

  ngAfterViewInit(): void {
    

    //let draw: Draw

    

    //addDrawInteractions();

    const features = this.drawSource.getFeaturesCollection();



    // --------------- layer for OneGeo  ---------------------------
    const vectorOneGeoTestData = new VectorLayer({
      background: 'rgba(255,255,255,0.3)',
      source: new VectorSource({
        url: this.oneGeoUrl,
        //format: new GeoJSON({dataProjection: 'EPSG:3857'})
        format: new GeoJSON()
      }),
    });

    //this.olMap.addLayer(vectorOneGeoTestData);

    // -------------- test layer for FRMG fuel published by geoserver -----
    const fuelSource = new TileWMS({
      url: 'https://skyforest.ca/geoserver/frmg/ows',
      params: {
        //layers: 'fuel_type_mosaic_COG',
        layers: 'exposure_canada_mosaic_v1',
        format: 'image/png',
        
      },
      serverType: 'geoserver',
      crossOrigin: 'anonymous',
      projection: 'EPSG:3857',
      tileLoadFunction: function(imageTile, src) {
        //((imageTile as ImageTile).getImage() as HTMLImageElement).src = src;
        //((imageTile as ImageTile).getImage() as HTMLImageElement).crossOrigin = 'anonymous';
   
        let http = new XMLHttpRequest();
        http.open('GET', src);
        http.setRequestHeader('Authorization', 'Basic YWRtaW46bjZrZlU5UzJuSFRRSlYzTg==');
        http.responseType = 'blob'
        http.addEventListener('loadend', function(e) {
          const tileData = this.response;
          if (tileData !== undefined) {
            //((imageTile as ImageTile).getImage() as HTMLImageElement).src = URL.createObjectURL(tileData);
            ((imageTile as ImageTile).getImage() as HTMLImageElement).src = src;
            ((imageTile as ImageTile).getImage() as HTMLImageElement).crossOrigin = 'anonymous';
          } else {
            imageTile.setState(TileState.ERROR)
          }
        });
        http.addEventListener('error', function() {
          imageTile.setState(TileState.ERROR);
        });

        http.send(); 
      }
    });

    const fuelLayer = new TileLayer({
      source: fuelSource,
      opacity: 0.5
    });

    // --------------- layer for Area Highlights ---------------------------
    this.areaHiLiteOverlay = new VectorLayer({
      source: new VectorSource(),
      map: this.olMap,
      style: {
        'fill-color': 'rgba(0, 0, 255, 0.2)',
        'stroke-width': 2,
      },
    });

    // ----------- navigate interactions ----------------------------------
    this.olMap.on('pointermove',  (e) => {
      if (e.dragging) {
        return;
      }
      const pixel = this.olMap.getEventPixel(e.originalEvent);

    });

    this.olMap.on('click', (e) => {
      if (this.inDrawMode === true) {return};
      const pixel = this.olMap.getEventPixel(e.originalEvent);
      highlightArea(pixel);
    })

    const highlightArea = (pixel: Pixel) => {

      // see if area under click
      const area: Feature | undefined = this.olMap.forEachFeatureAtPixel(pixel, (area) => {

        //(area as Feature).setProperties({ 'isSelected': !area.get('isSelected')});
        const currentState = area.getProperties()['isSelected'];
        (area as Feature).setProperties({ 'isSelected': !currentState});

        // have map service notify about selection
        //alert(area.getProperties()['id']);
        this.mapService.updateSelectedFeatures([{'id': area.getProperties()['id'], 'isSelected': !currentState, selectionOrigin: 'map'}]);

        // the change event stream will invoke the highlight change
        return area as Feature;
        
      });
    }

    /* add basemap layers but only enable one */
    this.addBasemapLayers();
   
    /*  add the frmg layers */
    this.addFrmgLayers();
    
    
  }

  setHighlightOnArea(area: Feature) {
    if (area && this.highlightArray.includes(area)) {
      this.areaHiLiteOverlay?.getSource()?.removeFeature(area);
      const areaIndex = this.highlightArray.indexOf(area);
      this.highlightArray.splice(areaIndex, 1);
    }
    else if (area && !this.highlightArray.includes(area)){
      this.areaHiLiteOverlay?.getSource()?.addFeature(area);
      this.highlightArray.push(area);
    }
  }

  // logic for hover-highlight
  /*
      if (area !== highlight) {
        //if (highlight) {
        if (area) {
          // areaHiLiteOverlay.getSource()?.removeFeature(highlight);
          areaHiLiteOverlay.getSource()?.addFeature(area);
        }
        if (area) {
          areaHiLiteOverlay.getSource()?.addFeature(area);
        }
        highlight = area;
      }
  */


  addDrawInteractions = (toolType: string) => {
    // remove previous tool interactions
    this.removeDrawInteractions();

    const drawType = toolType;
    switch(toolType) {
      case 'circle':
        this.draw = new Draw({
          //source: this.importAreaVectorSource,
          source: this.drawSource,
          type: 'Circle'
        });
      break;

      case 'polygon':
        this.draw = new Draw({
          //source: this.importAreaVectorSource,
          source: this.drawSource,
          type: 'Polygon'
        });
      break;

      case 'rectangle': 
        this.draw = new Draw({
          //source: this.importAreaVectorSource,
          source: this.drawSource,
          type: 'Circle',
          geometryFunction: createBox(),
        });
      break;

      case 'point':
        this.draw = new Draw({
          //source: this.importAreaVectorSource,
          source: this.drawSource,
          type: 'Point'
        });
      break;

      case 'line':
        this.draw = new Draw({
          //source: this.importAreaVectorSource,
          source: this.drawSource,
          type: 'LineString'
        });
      break;
    }


    this.olMap.addInteraction(this.draw);
    this.draw.on('drawstart', (e: any) => {
      if (this.userDrawnShape) {
        this.importAreaVectorSource.removeFeature(this.userDrawnShape);
      }
    });
    this.draw.on('drawend', (e: any) => {
      if (e.feature.getGeometry().getType() === 'Circle') {
      }
      
      this.userDrawnShape = e.feature;
      this.importAreaVectorSource.addFeature(this.userDrawnShape);
      // this.openNameAreaDialog();
    })

    this.snap = new Snap({
      source: this.importAreaVectorSource
    })
    this.olMap.addInteraction(this.snap);

    this.modify = new Modify({
      source: this.importAreaVectorSource
    })
    this.olMap.addInteraction(this.modify);
  }

  removeDrawInteractions = () => {
    this.olMap.removeInteraction(this.draw);
    this.olMap.removeInteraction(this.snap);
    this.olMap.removeInteraction(this.modify);
  }

  /* call the back end to get user's saved areas */
  getSavedAreas(): void {
    this.dataService.getAreas()
      .subscribe((result) => {
        //this.uploadService.uploadNotice(result, "baseAreas.json");
        this.mapService.notifyNewLayer({fileData: result, fileName: "baseAreas.json"});
      });
  }


  addImportedGeoJsonLayer(geojsonFile: any): void {
    
    this.importAreaVectorSource = new VectorSource({
      format: new GeoJSON(),
      features: new GeoJSON().readFeatures(geojsonFile, {
        featureProjection: this.olMap.getView().getProjection(),
        dataProjection: 'EPSG:4326'
      })

      
    });
    (geojsonFile.features as any[]).forEach((feature) => {
      feature.properties.isSelected = false;
      feature.properties.areaArea = 1314;
    })

    /* vectorSource.forEachFeature((feature) => { */
    this.importAreaVectorSource.forEachFeature((feature) => {
      let count = 0;
      //feature.setProperties({'isSelected': false});
      //feature.setProperties({'areaArea': 1.8});
      /* if (count === 0) {
        const geo = new GeoJSON();
        const geoM: string = geo.writeFeature(feature);
      }
      count++; */
    })

    let iconUrl = '../../assets/icons/map-pin-inactive.svg';
    let iconStyle = new Style({
      image: new Icon({
        src: iconUrl
      })
    });

    this.areaVectorLayer = new VectorLayer({
      source: this.importAreaVectorSource,
      background: 'rgba(255,255,255,0.3)',
      style: iconStyle
    });

    this.olMap.addLayer(this.areaVectorLayer);

    //this.olMap.addLayer(this.drawLayer);

    // now pass the feature collection to the service
    // danny todo - rather than passing the map features, pass the geojson parse features
    // jsonFeatures = JSON.parse(geojson)

    // danny todo
    //this.mapService.addNewAreasToAreaList(vectorSource.getFeatures());
    this.mapService.addNewAreasToAreaList(geojsonFile.features);
  }

  addImportedKmlLayer(kml: any): void {
    const vectorSource = new VectorSource({
      format: new KML(),
      features: new KML().readFeatures(kml, {
        featureProjection: this.olMap.getView().getProjection(),
        dataProjection: 'EPSG:4326',
      })
    });

    
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      background: 'rgba(255,255,255,0.3)',
    });

    this.olMap.addLayer(vectorLayer);
  }

  /* this picks up the static layer descriptors and adds layers for each */
  addFrmgLayers(): void {
    const authHeader = this.frmgAuthHeader;
    const frmgLayerList = this.frmgLayers.filter(item => item.type === 'frmg');
    //const baseUrl = 'http://localhost:5001/singleband';
    const baseUrl = environment.mapServerBaseUrl;
    const sourceType = '{z}/{x}/{y}';
    
    frmgLayerList.forEach((layerVO) => {
      
      const tileLayer: TileLayer<TileSource> = new TileLayer({
        source: new XYZ({
          url: `${baseUrl}/${layerVO.endpointUrl}/${sourceType}.png?colormap=${layerVO.colorMap}`,
          projection: layerVO.projection,
          tileLoadFunction: (tile, src) => {

            let client = new XMLHttpRequest();
            client.open('GET', src);
            client.responseType = 'arraybuffer';
            client.setRequestHeader('Authorization', `Bearer ${this.userService.currentAccessToken}`);
            client.onload = function () {
              const arrayBufferView = new Uint8Array(this.response);
              const blob = new Blob([arrayBufferView], { type: 'image/png' });
              const urlCreator = window.URL || (window as any).webkitURL;
              const imageUrl = urlCreator.createObjectURL(blob);
              ((tile as ImageTile).getImage() as HTMLImageElement).src = imageUrl;
            }
            
            /* client.onload = (function() {
              let data = 'data:image/png:base64,' + btoa(unescape(encodeURIComponent(this.responseText)));
              ((tile as ImageTile).getImage() as HTMLImageElement).src = data;
            }) */

            //client.open('GET', src);
            //client.responseType = 'blob';
            client.send();
          }
        }), 
        opacity: layerVO.opacity,
        visible: layerVO.visibleState,

      })
      this.olMap.addLayer(tileLayer);
      let mapC: LayerMapContent = { layerVO: layerVO, olLayer: tileLayer}
      this.layerMap.set(layerVO.layerName, mapC);
    })

  }

  addBasemapLayers(): void {
    const basemapLayerList = this.basemapLayers.filter(item => item.type === 'base');
    const baseUrl = 'https://server.arcgisonline.com/ArcGIS/rest/services';
    const esriAttributionBaseStart = 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/';
    const esriAttributionBaseEnd = 'MapServer">ArcGIS</a>'
    const esriAttributionLayer = 'https://server.arcgisonline.com/ArcGIS/rest/services/';
    const sourceType = 'MapServer/tile/{z}/{y}/{x}';

    basemapLayerList.forEach((layerVO) => {
      const basemapLayer = new TileLayer({
        source: new XYZ({
          attributions: layerVO.attribution ? layerVO.attribution: '',
          url: layerVO.endpointUrl,
        }),
        opacity: layerVO.opacity,
        visible: layerVO.visibleState
      });
      this.olMap.addLayer(basemapLayer);
      let mapC: LayerMapContent = { layerVO: layerVO, olLayer: basemapLayer}
      this.layerMap.set(layerVO.layerName, mapC);
    })
    
  }

  openNameAreaDialog(): void {
    const nanoid = customAlphabet('1234567890', 8);
    const nameAreaDialogRef = this.nameAreaDialog.open(DrawCompleteDialogComponent, {
      width: '350px',
      hasBackdrop: true,
      disableClose: true,
      data: {
        myAreaId: nanoid()
      }
    })

    
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

  }

}

