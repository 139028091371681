import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtDecodedToken } from '../models/auth.model';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import { TokenResponse } from "./interceptors/auth-interceptor.service";

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  public baseUrl: string = environment.baseUrl;
  public baseUrlLogin: string = environment.baseUrlLogin;
  public currentAccessToken: string | null = '';
  public currentRefreshToken: string | null = '';

  constructor(
    protected http: HttpClient,
    protected router: Router
  ) {
    this.currentAccessToken = localStorage.getItem('access_token');
    this.currentRefreshToken = localStorage.getItem('refresh_token');
  }


  public setTokenPair(tokenResponse: TokenResponse) {
    this.currentAccessToken = tokenResponse.access;
    this.currentRefreshToken = tokenResponse.refresh;
    localStorage.setItem('access_token', tokenResponse.access);
    localStorage.setItem('refresh_token', tokenResponse.refresh);
  }


  public isTokenExpired(): boolean {
    if (this.currentAccessToken === '') {
      return true
    }
    const myToken: JwtDecodedToken = jwtDecode(this.currentAccessToken || '');
    const nowTime: number = Math.floor(Date.now() / 1000);
    return myToken.exp < nowTime;
  }
}
