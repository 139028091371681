import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Download, PagedData } from "../models/general.models";
import { AreaTaskGroup, DownloadTaskStatus, Task, TaskGroup, TaskStatistic } from "../models/tasks.model";
import { UserService } from "./user.service";
import { DownloadService } from "./download.service";


@Injectable({
  providedIn: 'root'
})
export class TaskService {

  private baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private downloadService: DownloadService
  ) {
  }

  public getTasks(pageNumber: number, pageSize: number): Observable<PagedData<Task>> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/tasks/?page=${pageNumber}&limit=${pageSize}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<PagedData<Task>>(endpoint, {headers})
  }

  public getTasksGroup(pageNumber: number, pageSize: number): Observable<PagedData<TaskGroup>> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/task-groups/?page=${pageNumber}&limit=${pageSize}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<PagedData<TaskGroup>>(endpoint, {headers})
  }

  public getTasksGroupById(id: string): Observable<TaskGroup> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/task-groups/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<TaskGroup>(endpoint, {headers})
  }

  public deleteTasksGroupById(id: string): Observable<TaskGroup> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/task-groups/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.delete<TaskGroup>(endpoint, {headers})
  }
  public prepareDownloadTasksGroupById(id: string): Observable<{task_id: string}> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/task-groups/${id}/prepare-download/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<{task_id: string}>(endpoint, {headers})
  }

  public downloadTasksGroupById(id: string, task_id: string, name: string): Observable<Download> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/task-groups/${id}/download/${task_id}/`;
    // const headers = new HttpHeaders({
    //   'Authorization': `Bearer ${this.userService.currentAccessToken}`
    // })
    return this.downloadService.downloadZip(endpoint, name+'.zip');
    // return this.http.get<DownloadTaskStatus>(endpoint, {headers})
  }

  public prepareDownloadAreaTasksGroupById(areaId: string, id: string): Observable<{task_id: string}> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/task-groups/${id}/area-task-groups/${areaId}/prepare-download/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<{task_id: string}>(endpoint, {headers})
  }

  public downloadAreaTasksGroupById(areaId: string, id: string, task_id: string, name: string): Observable<Download> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/task-groups/${id}/area-task-groups/${areaId}/download/${task_id}/`;
    // const headers = new HttpHeaders({
    //   'Authorization': `Bearer ${this.userService.currentAccessToken}`
    // })
    return this.downloadService.downloadZip(endpoint, name+'.zip');
    // return this.http.get<DownloadTaskStatus>(endpoint, {headers})
  }
  public deleteAreaTasksGroupById(id: string, task_id: string): Observable<DownloadTaskStatus> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/task-groups/${id}/area-task-groups/${task_id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.delete<DownloadTaskStatus>(endpoint, {headers})
  }

  public getTasksGroupByIdTasks(pageNumber: number, pageSize: number, id: string): Observable<PagedData<AreaTaskGroup>> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/task-groups/${id}/area-task-groups/?page=${pageNumber}&limit=${pageSize}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<PagedData<AreaTaskGroup>>(endpoint, {headers})
  }

  public getTasksStatus(): Observable<TaskStatistic> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/tasks/status-counts/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<TaskStatistic>(endpoint, {headers})
  }

  public getTaskById(id: string): Observable<Task> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/tasks/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<Task>(endpoint, {headers})
  }

  public getTaskStatus(id: string): Observable<Task> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/tasks/status/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<Task>(endpoint, {headers})
  }
}
