import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from "@angular/material/dialog";
import { InviteModalComponent } from "../invite-modal/invite-modal.component";
import { UserService } from "../../../services/user.service";
import { RoleModalComponent } from "../role-modal/role-modal.component";
import { Role, TeamMember } from "../../../models/team.model";
import { DeleteModalComponent } from "../delete-modal/delete-modal.component";
import { ToastNotificationService } from "../../../services/toast-notification.service";
import { findFirstNonEmptyString } from "../../../utils";
import { User } from "../../../models/user.model";


@Component({
  selector: 'frmg-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  public teamData: TeamMember[] = [];
  public availableRoles: Role[] = [];
  public availableMembership: string[] = ['member', 'owner'];
  public displayedColumns = ["user", "fullName", "role", "action"];
  public currentUser: User;
  public currentUserRole: string = 'member';

  constructor(private dialog: MatDialog,
              private userService: UserService,
              private toastNotificationService: ToastNotificationService) {
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('user')) as User;
    this.currentUserRole = this.currentUser.teams[0].membership.role;
    this.getTeamMembers();
    this.userService.getTeamRoles().subscribe(rolesPage => {
      this.availableRoles = rolesPage.results
    })
  }

  getTeamMembers(): void {
    this.userService.getTeamUsers()
      .subscribe(
        (response) => {
          this.teamData = response.results.map(member => {
            if (!member.role_team) member.role_team = {};
            return member
          }).filter(member=>member.role !== 'admin' );
        },
        (error) => {
          const errorMsg = 'Error fetching team members:';
          this.toastNotificationService.showNotification({type: "error", message: errorMsg})
        }
      );
  }

  // searchMembers(): void {
  //   this.filteredMembers = this.teamMembers.filter(member =>
  //     member.email.toLowerCase().includes(this.searchTerm.toLowerCase())
  //   );
  // }

  public setNewRole(role: Role, user: TeamMember) {
    const body = {
      role_team_id: role.id
    }
    this.userService.updateTeamUser(body, user.id).subscribe(newMember => {
      const index = this.teamData.findIndex(member => member.id === newMember.id);
      this.teamData[index] = newMember;
    }, error => {
      const errorMsg = 'Error changing member role';
      this.toastNotificationService.showNotification({type: "error", message: errorMsg})
    })
  }

  removeMember(memberId: number): void {
    this.dialog.open(DeleteModalComponent).afterClosed().subscribe(resp => {
      if (resp) {
        this.userService.removeTeamUser(memberId)
          .subscribe(
            () => {
              this.toastNotificationService.showNotification({type: "success", message: 'User was deleted successfully.'})
              this.getTeamMembers();
            },
            (error) => {
              const errorMsg = 'Error removing team member';
              this.toastNotificationService.showNotification({type: "error", message: findFirstNonEmptyString(error.error) || errorMsg})
            }
          );
      }
    })

  }

  onChangeRole(role: Role, user: TeamMember): void {

  }

  openInviteModal(): void {
    this.dialog.open(InviteModalComponent);
  }

  openRoleModal(role?: any) {
    this.dialog.open(RoleModalComponent).afterClosed().subscribe(resp => {
      if (resp) this.availableRoles.push(resp)
    })
  }

  setMembership(member, user): void {
    const body = {
      role: member
    }
    this.userService.updateTeamUser(body, user.id).subscribe(newMember => {
      const index = this.teamData.findIndex(member => member.id === newMember.id);
      this.teamData[index] = newMember;
    }, error => {
      const errorMsg = 'Error changing membership';
      this.toastNotificationService.showNotification({type: "error", message: findFirstNonEmptyString(error.error) || errorMsg})
    })
  }

}