import { Injectable } from '@angular/core';
import { forkJoin, map, mergeMap, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from "./user.service";
import { ChildProject, Project } from "../models/project.module";
import { PagedData } from "../models/general.models";


@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  public getProjectsByPath(path: string = 'root', archived?: boolean): Observable<Project> {
    let endpoint = `${this.baseUrl}/${this.userService.mySlug}/projects/path/${path}/`;
    if (archived) {
      endpoint += '?is_archived=' + archived
    }
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.get<Project>(endpoint, {headers})
  }

  public searchProjects(search: string): Observable<Array<Project>> {
    let endpoint1 = `${this.baseUrl}/${this.userService.mySlug}/projects/search/own`;
    let endpoint2 = `${this.baseUrl}/${this.userService.mySlug}/projects/search/shared`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    if (search && search.length) {
      endpoint1 += '?q=' + search;
      endpoint2 += '?q=' + search;
    }
    return forkJoin([this.http.get<Array<Project>>(endpoint1, {headers}), this.http.get<Array<Project>>(endpoint2, {headers})]).pipe(
      map(result => [...result[0], ...result[1]]
      )
    )
  }

  public getProjects(): Observable<PagedData<Project>> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/projects/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.get<PagedData<Project>>(endpoint, {headers});
  }

  public getProjectById(id: number | string): Observable<Project> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/projects/${id}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.get<Project>(endpoint, {headers});
  }

  public changeProject(id: string, body: Partial<Project>): Observable<Project> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/projects/${id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.patch<Project>(endpoint, {...body}, {headers});
  }

  public deleteProject(id: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/projects/${id}/`;
    return this.http.delete(endpoint, {headers})
  }

  public archiveProject(project: ChildProject, parentId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    const type = !project.is_archived ? 'archive' : 'unarchive'
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/projects/${project.id}/${type}/`
    const body = {
      name: project.name,
      parent: parentId
    };
    return this.http.post(endpoint, body, {headers})
  }

  public createProject(body: { name: string, parent: number | string, description?: string }): Observable<Project> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/projects/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.post<Project>(endpoint, body, {headers});
  }

  public shareProject(body: { user_id: string | number }, id: string): Observable<Project> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/projects/${id}/share/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.post<Project>(endpoint, body, {headers});
  }

  public removeShareProject(body: { user_id: string | number }, id: string): Observable<Project> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/projects/${id}/remove_share/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.post<Project>(endpoint, body, {headers});
  }

}
