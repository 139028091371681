<div class="settings-profile">
    <div class="settings-header">
        <div class="header-wrapper">
            <h2 class="settings-title">Settings</h2>
        </div>
    </div>
    <div class="settings-container">
        <div class="settings-sidebar">
            <div class="sidebar-menu">
                <div class="menu-item" [class.selected]="selectedMenuItem === 'account'"
                     (click)="onMenuItemClick('account')">
                    <div class="menu-icon icon-Account"></div>
                    <span class="menu-label">Account</span>
                </div>
                <div class="menu-item" [class.selected]="selectedMenuItem === 'changePassword'"
                     (click)="onMenuItemClick('changePassword')">
                    <div class="menu-icon icon-Password"></div>
                    <span class="menu-label">Change Password</span>
                </div>
                <div class="menu-item" [class.selected]="selectedMenuItem === 'team'" (click)="onMenuItemClick('team')">
                    <div class="menu-icon icon-Account-1"></div>
                    <span class="menu-label">Team</span>
                </div>
                <div class="menu-item" [class.selected]="selectedMenuItem === 'usage'"
                     (click)="onMenuItemClick('usage')">
                    <div class="menu-icon icon-plan"></div>
                    <span class="menu-label">Plan & Usage</span>
                </div>
                <div class="menu-item" [class.selected]="selectedMenuItem === 'apiKeys'"
                     (click)="onMenuItemClick('apiKeys')">
                    <div class="menu-icon icon-key"></div>
                    <span class="menu-label">API Keys</span>
                </div>

            </div>
        </div>
        <div class="settings-main">
            <div class="settings-wrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div class="settings-sidebar"></div>
    </div>
</div>