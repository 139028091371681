<div class="download-header">
    <p (click)="goBack()">{{ "downloads.my-downloads" | translate }}</p>
    <img src="assets/icons/page_back.svg" alt="" width="8px" height="13px">
    <p>
        {{ taskGroup?.name }}
    </p>
</div>

<!--<div class="download-summary">-->
<!--    <div class="summary-header">-->
<!--        {{ "downloads.request-stats" | translate }}-->
<!--    </div>-->
<!--    <div class="summary-container">-->
<!--        <ng-template [ngTemplateOutlet]="summaryCard"-->
<!--                     [ngTemplateOutletContext]="{-->
<!--        value: statistic.completed,-->
<!--        title: 'downloads.processed',-->
<!--        icon: 'new_releases'-->
<!--        }"></ng-template>-->
<!--        <ng-template [ngTemplateOutlet]="summaryCard"-->
<!--                     [ngTemplateOutletContext]="{-->
<!--        value: statistic.started,-->
<!--        title: 'downloads.processing',-->
<!--        icon: 'progress_activity'-->
<!--        }">-->
<!--        </ng-template>-->
<!--&lt;!&ndash;        <ng-template [ngTemplateOutlet]="summaryCard"&ndash;&gt;-->
<!--&lt;!&ndash;                     [ngTemplateOutletContext]="{&ndash;&gt;-->
<!--&lt;!&ndash;        value: statistic.cancelled,&ndash;&gt;-->
<!--&lt;!&ndash;        title: 'downloads.canceled',&ndash;&gt;-->
<!--&lt;!&ndash;        icon: 'hide_source'&ndash;&gt;-->
<!--&lt;!&ndash;        }">&ndash;&gt;-->
<!--&lt;!&ndash;        </ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;        <ng-template [ngTemplateOutlet]="summaryCard"&ndash;&gt;-->
<!--&lt;!&ndash;                     [ngTemplateOutletContext]="{&ndash;&gt;-->
<!--&lt;!&ndash;        value: statistic.failed,&ndash;&gt;-->
<!--&lt;!&ndash;        title: 'downloads.expired',&ndash;&gt;-->
<!--&lt;!&ndash;        icon: 'timer_off'&ndash;&gt;-->
<!--&lt;!&ndash;        }"></ng-template>&ndash;&gt;-->

<!--    </div>-->
<!--</div>-->

<div class="download-table">
    <div class="table-header">
        Areas
    </div>
    <div class="table-container">
        <table mat-table
               [dataSource]="taskData" multiTemplateDataRows
               class="mat-elevation-z8">
            <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef> {{ column === 'action' ? '' : column }}</th>
                <ng-container *ngIf="column !== 'status' && column !== 'action' ">
                    <td mat-cell
                        *matCellDef="let element"> {{ column === 'area' ? element.name : column === 'action' ? '' : element[column] }}
                    </td>
                </ng-container>
                <ng-container *ngIf="column === 'action'">
                    <td mat-cell *matCellDef="let element">
                        <button mat-raised-button class="frmg-primary-button"
                                (click)="download(element, $event)">
                            <span class="material-symbols-outlined action-icon">download</span> Download
                        </button>
                        <button mat-raised-button class="frmg-secondary-button"
                                (click)="deleteDownload(element, $event)">
                            <span class="icon-remove-icon"></span></button>
                    </td>
                </ng-container>
                <ng-container *ngIf="column === 'status'">
                    <td mat-cell *matCellDef="let element">
                        <div class="status-block">
                            <span [ngClass]="statusClass(element)"></span>
                            <span>{{ element.status === 'pending' ? (element.completed_tasks + "/" + element.total_tasks) : '' }} {{ taskStatusTranslations.get(element.status) | translate }}</span>
                        </div>

                    </td>
                </ng-container>

            </ng-container>
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="expand row"
                            (click)="(expandedElement = expandedElement?.id === element.id ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement?.id !== element.id">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement?.id === element.id">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                    <div class="download_table-element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div class="download_table-element-description" *ngFor="let task of element.tasks">
                            {{ task.layer.display_name }}
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                class="download_table-element-row"
                [class.download_table-expanded-row]="expandedElement?.id === element.id"
                (click)="expandedElement = expandedElement?.id === element.id ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="download_table-detail-row"></tr>
        </table>
    </div>
    <mat-divider></mat-divider>
    <mat-paginator
            [length]="totalElements"
            [pageIndex]="currentPage-1"
            [pageSize]="pageSize"
            [pageSizeOptions]="[50, 100, 15]"
            [showFirstLastButtons]="true"
            (page)="handlePageEvent($event)"
    ></mat-paginator>

</div>

<ng-template #summaryCard let-value="value" let-title="title" let-icon="icon">
    <div class="summary-card">
        <div class="card-info">
            <p class="card-reading">{{ value }}</p>
            <p class="card-title">{{ title | translate }}</p>
        </div>
        <div class="card-icon">
            <span class="material-symbols-outlined">
                {{ icon }}
            </span>
        </div>
    </div>
</ng-template>
