import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import { DownloadMsg, NotificationCategoryEnum, NotificationMsg, PagedData } from "../models/general.models";
import {UserService} from "./user.service";


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private baseUrl: string = environment.baseUrl;
  private _NotificationsArray: Array<NotificationMsg> = [];
  private _DownloadsArray: Array<DownloadMsg> = [];

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  public get DownloadsArray(): Array<DownloadMsg> {
    return this._DownloadsArray;
  }

  public set DownloadsArray(value: Array<DownloadMsg>) {
    this._DownloadsArray = value;
  }

  public updateDownload(updatedBody: DownloadMsg) {
    const indexToUpdate = this._DownloadsArray.findIndex(el=>el.fileName === updatedBody.fileName);
    if (indexToUpdate == -1) {
      updatedBody.percentage !== 100 && this._DownloadsArray.push(updatedBody);
      // this._DownloadsArray.push(updatedBody)
    } else {
      if (updatedBody.percentage >= 100) {
        this._DownloadsArray.splice(indexToUpdate, 1);
      } else this._DownloadsArray[indexToUpdate] = updatedBody;
    }

  }


  public getNotifications(): Observable<PagedData<NotificationMsg>> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/notifications/?page=1`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<PagedData<NotificationMsg>>(endpoint, {headers});
  }

  public readNotification(notification: NotificationMsg): Observable<NotificationMsg> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/notifications/${notification.id}/`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    notification.read = true;
    return this.http.put<NotificationMsg>(endpoint, notification,{headers});
  }

  public get NotificationsArray(): Array<NotificationMsg> {
    return this._NotificationsArray;
  }

  public set NotificationsArray(value: Array<NotificationMsg>) {
    this._NotificationsArray = value;
  }

  public updateNotificationById(updatedBody: NotificationMsg) {
    const indexToUpdate = this._NotificationsArray.findIndex(el=>el.id === updatedBody.id)
    this._NotificationsArray[indexToUpdate] = updatedBody;
  }

  public getSubCategoryType(notification: NotificationMsg) {
    switch (notification.category) {
      case NotificationCategoryEnum.PROJECT_ARCHIVED:
        return 'icon-archived'
      case NotificationCategoryEnum.USER_ADDED_TO_TEAM:
      case NotificationCategoryEnum.USER_ROLE_UPDATED:
      case NotificationCategoryEnum.USER_REMOVED_FROM_TEAM:
        return 'icon-Settings'
      case NotificationCategoryEnum.USER_REMOVED_FROM_PROJECT:
        return 'icon-unshared'
      case NotificationCategoryEnum.PROJECT_DELETED:
        return "icon-remove-icon"
      case NotificationCategoryEnum.USER_ADDED_TO_PROJECT:
      default:
        return 'icon-shared'
    }
  }
  public getCategoryType(notification: NotificationMsg) {
    switch (notification.category) {
      case NotificationCategoryEnum.USER_ADDED_TO_TEAM:
      case NotificationCategoryEnum.USER_ROLE_UPDATED:
      case NotificationCategoryEnum.USER_REMOVED_FROM_TEAM:
        return "<span class='icon-warning'></span>"
      case NotificationCategoryEnum.PROJECT_ARCHIVED:
      case NotificationCategoryEnum.PROJECT_DELETED:
      case NotificationCategoryEnum.USER_ADDED_TO_PROJECT:
      case NotificationCategoryEnum.USER_REMOVED_FROM_PROJECT:
      default:
        return "<span class='icon-chat'></span>"
    }
  }

}