import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  Type,
  ViewContainerRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, skip, Subscription } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { ChangeSidebarLeftAction, ToggleAreaManagerAction, ToggleLayerManagerAction } from 'src/app/store/sf.actions';
import { SfState } from '../../store/sf.state';


import { TranslateService } from '@ngx-translate/core';
import { NotificationMessage, ToastPanelData } from "../../models/notification.model";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from "@angular/material/snack-bar";
import { ToastNotificationService } from "../../services/toast-notification.service";
import { FrmgSnackBarComponent } from "./components/snack-bar/snack-bar.component";
import { environment } from "../../../environments/environment";
import { UserService } from "../../services/user.service";
import { NotificationService } from "../../services/notification.service";
import { NotificationMsg } from "../../models/general.models";

@Component({
  selector: 'frmg-portal-base',
  templateUrl: './portal-base.component.html',
  styleUrls: ['./portal-base.component.scss']
})
export class PortalBaseComponent implements OnInit, AfterViewInit, OnDestroy {

  // define the action types of any children expecting to emit state actions

  // left sidebar state - maybe deprecated
  leftSidebarActionType: Type<ChangeSidebarLeftAction> = ChangeSidebarLeftAction;
  @Select(SfState.getSidebarLeftState) leftMenuBarStateO$!: Observable<string>;

  /*  -------- dashboard page events  --------------*/
  // subscribe to dashboard menu button
  @Select(SfState.getDashboardMenuState) dashboardMenuStateO$!: Observable<string>;

  // sub to charts menu button
  @Select(SfState.getChartsPageState) chartsMenuStateO$!: Observable<string>;

  // subscribe to the risk map button
  @Select(SfState.getMapMenuState) mapMenuStateO$!: Observable<string>;

  // subscribe to the risk map button
  @Select(SfState.getDownloadsPageMenuState) downloadsMenuStateO$!: Observable<string>;

  @Select(SfState.getReportsPageMenuState) reportsMenuStateO$!: Observable<string>;

  /* subscribe to the upload dialog button */
  @Select(SfState.getUploadDialogState) uploadDialogStateO$!: Observable<string>;

  // layer manager overlay panel
  // define type for action callback to overlay panel
  layerManagerActionType: Type<ToggleLayerManagerAction> = ToggleLayerManagerAction;
  @Select(SfState.getLayerManagerState) layerManagerPanelStateO$!: Observable<string>;

  // same for area manager panel - pass input in layout to element with button
  areaManagerActionType: Type<ToggleAreaManagerAction> = ToggleAreaManagerAction;
  @Select(SfState.getAreaManagerState) areaManagerPanelStateO$!: Observable<string>;

  // subscribe to the user management page button
  @Select(SfState.setUserManagementPageState) userManagerPanelStateO$!: Observable<string>;

  // subscribe to the settings page button
  @Select(SfState.getSettingsMenuState) settingsPageStateO$!: Observable<string>;

  // subscribe to the language button
  @Select(SfState.getLanguageState) languageStateO$!: Observable<string>;

  uploadDialogState: boolean = false;


  toastTarget = 'root';

  private notificationSubscription: Subscription = null;
  private hideNotificationSubscription: Subscription = null;
  private snackBarRef: MatSnackBarRef<any> = null;
  private currentMessage: NotificationMessage = null;

  private dismissTimeout: any;
  private infoSubscription: WebSocketSubject<any>;
  public notificationsPopups: Array<NotificationMsg> = [];
  public notificationsArray: Array<NotificationMsg> = [];
  public showNotification: boolean = false;
  public totalUnreadNotifications: number = 0;

  constructor(
    public notificationService: NotificationService,
    private router: Router,
    private translateService: TranslateService,
    private userService: UserService,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef,
    private toastNotificationService: ToastNotificationService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone) {
    this.infoSubscription = webSocket(`${environment.wsBaseUrl}notifications/?api_token=${this.userService.currentAccessToken}`)

  }

  ngOnInit(): void {
    this.infoSubscription.subscribe({
      next: msg => {
        this.notificationsPopups.push(msg);
        const newArray = this.notificationService.NotificationsArray;
        newArray.push(msg);
        this.notificationService.NotificationsArray = newArray;
        this.notificationsArray = newArray;
        this.totalUnreadNotifications++;
        this.cd.detectChanges();
      }
    });

    this.fetchNotifications();


    this.dashboardMenuStateO$
      .pipe(skip(1)) // ignore the initial subscription emission
      .subscribe((state) => {

        this.router.navigate(['dashboard/charts']);
      });

    this.mapMenuStateO$
      .pipe(skip(1)) // ignore the initial subscription emission
      .subscribe((state) => {
        this.router.navigate(['risk-map']);
      });

    this.downloadsMenuStateO$
      .pipe(skip(1)) // ignore the initial subscription emission
      .subscribe((state) => {
        this.router.navigate(['downloads']);
      });
    this.reportsMenuStateO$
      .pipe(skip(1)) // ignore the initial subscription emission
      .subscribe((state) => {
        this.router.navigate(['report-table']);
      });

    this.userManagerPanelStateO$
      .pipe(skip(1)) // ignore the initial subscription emission
      .subscribe((state) => {
        if (state === 'open') {
          this.router.navigate(['dashboard/users']);
        }

      });

    this.chartsMenuStateO$
      .pipe(skip(1)) // ignore the initial subscription emission
      .subscribe((state) => {
        if (state === 'open') {
          this.router.navigate(['dashboard/charts']);
        }

      });

    this.settingsPageStateO$
      .pipe(skip(1))
      .subscribe((state) => {
        if (state === 'open') {
          this.router.navigate(['settings']);
        }
      });

    this.languageStateO$
      .pipe(skip(1))
      .subscribe((state) => {

        if (state === 'en') {
          this.translateService.use('en');
        } else if (state === 'fr') {
          this.translateService.use('fr');
        }
      });

    this.uploadDialogStateO$
      .pipe(skip(1))
      .subscribe((state) => {

        if (state === 'idle') {
          this.uploadDialogState = false;
        } else if (state === 'active') {
          this.uploadDialogState = true;
        }
      });
  }

  private fetchNotifications() {
    this.notificationService.getNotifications().subscribe(resp => {
      this.notificationService.NotificationsArray = resp.results;
      this.totalUnreadNotifications = this.notificationService.NotificationsArray.filter(notification => !notification.read).length;
      this.notificationsArray = this.notificationService.NotificationsArray;
    })
  }

  public newNotifications() {
    return this.notificationsPopups.filter(notification => !notification.read)
  }

  public dismissAll() {
    this.newNotifications().forEach(notification=>{
      this.dismissNotification(notification);
    })
  }

  public dismissNotification(notification: NotificationMsg) {
    notification.read = true;
    this.notificationService.readNotification(notification).subscribe(_ => {
      this.totalUnreadNotifications--;
    })
  }

  public notificationMsgTransform(str: string) {
    const splitBy = str.split(' by ');
    if (splitBy.length > 1) {
      str = str.replace(splitBy[1], `<span class="bold-name">${splitBy[1]}</span>`)
    }
    const regex = /'([^']+)'/g;
    return str.replace(regex, (match, p1) => `<span class="bold-name">${p1}</span>`);
  }

  ngAfterViewInit(): void {
    this.notificationSubscription = this.toastNotificationService.getNotification().subscribe(notificationMessage => {
      if (this.shouldDisplayMessage(notificationMessage)) {
        this.currentMessage = notificationMessage;
        this.showSnackBar(notificationMessage)
      }
    })


    this.hideNotificationSubscription = this.toastNotificationService.getHideNotification().subscribe(
      (hideNotification) => {
        if (hideNotification) {
          this.ngZone.run(() => {
            if (this.snackBarRef) {
              this.snackBarRef.dismiss();
            }
          });
        }
      })
  }

  private shouldDisplayMessage(notificationMessage: NotificationMessage): boolean {
    if (notificationMessage && notificationMessage.message) {
      if (!this.currentMessage || this.currentMessage.message !== notificationMessage.message
        || this.currentMessage.type !== notificationMessage.type) {
        return true;
      }
    }
    return false;
  }

  private showSnackBar(notificationMessage: NotificationMessage) {
    this.ngZone.run(() => {
      if (this.snackBarRef) {
        this.snackBarRef.dismiss();
      }

      const data: ToastPanelData = {
        notification: notificationMessage,
        parent: this.elementRef,
        panelClass: [],
        destroyToastComponent: () => {
        }
      };

      const config: MatSnackBarConfig = {
        horizontalPosition: notificationMessage.horizontalPosition || 'center',
        verticalPosition: notificationMessage.verticalPosition || 'top',
        viewContainerRef: this.viewContainerRef,
        duration: notificationMessage.duration || 3000,
        panelClass: notificationMessage.panelClass,
        data
      };
      this.snackBarRef = this.snackBar.openFromComponent(FrmgSnackBarComponent, config);
      if (notificationMessage.duration && notificationMessage.duration > 0 && notificationMessage.forceDismiss) {
        if (this.dismissTimeout !== null) {
          clearTimeout(this.dismissTimeout);
          this.dismissTimeout = null;
        }
        this.dismissTimeout = setTimeout(() => {
          if (this.snackBarRef) {
            this.snackBarRef.instance.actionButton._elementRef.nativeElement.click();
          }
          this.dismissTimeout = null;
        }, notificationMessage.duration);
      }
      this.snackBarRef.afterDismissed().subscribe(() => {
        if (this.dismissTimeout !== null) {
          clearTimeout(this.dismissTimeout);
          this.dismissTimeout = null;
        }
        this.snackBarRef = null;
        this.currentMessage = null;
      });
    })
  }

  ngOnDestroy(): void {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    if (this.hideNotificationSubscription) {
      this.hideNotificationSubscription.unsubscribe();
    }
  }

  public showNotificationChange(overlay: boolean) {
    this.showNotification = overlay;
    this.cd.detectChanges();
  }

  public hideNotification() {
    this.showNotification = false;
    this.cd.detectChanges();
  }

  public getSubCategoryType = this.notificationService.getSubCategoryType;
  public getCategoryType = this.notificationService.getCategoryType;
}
