import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AreaService } from "../../services/area.service";
import { TaskService } from "../../services/task.service";
import { delay, map, mergeMap, of, retry, throwError } from "rxjs";
import { MapCommunicatorService } from "../../pages/risk-map/map-communicator.service";

@Component({
  selector: 'frmg-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {
  @ViewChild("fileDropRef", {static: false}) fileDropEl!: ElementRef;
  files: any[] = [];

  @Output() closeMenu = new EventEmitter<any>();
  @Input() projectId: string;

  constructor(
    private areaService: AreaService,
    private taskService: TaskService,
    private mapCommunicatorService: MapCommunicatorService,
    private cd: ChangeDetectorRef
  ) {

  }

  /**
   * on file drop handler
   */
  onFileDropped(files: any) {
    this.prepareFilesList(files);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    this.prepareFilesList(event.files);

    // danny directly add layer to map
    const myFile: File = this.files[0] as File;

    if (myFile) {
      // get file type from extension
      const fileName = myFile.name;
      //const fileType = fileName.split('.').pop();

      // get a reader to read the file
      const fileReader: FileReader = new FileReader();
      // add a load listener
      fileReader.onload = (e: any) => {
      };

      // invoke the reader
      fileReader.readAsText(myFile);

    }
  }

  deleteFile(index: number) {

    this.files.splice(index, 1);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
  }

  public formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  public clearAll() {
    this.files = [];
  }

  public upload() {
    this.files.forEach(file => {
      if (file.progress === 0) {
        file.status = 'Send for the processing';
        file.progress = 1;
        this.areaService.uploadAreaFile({file: file}, this.projectId).subscribe(resp => {
          file.status = 'Processing';
          this.taskService.getTaskStatus(resp.task_id).pipe(
            delay(3000),
            map(task => task),
            mergeMap(task => {
              return task.status === 'pending' ? throwError(() => 'retry ') : of(task)
            }),
            retry({
              count: 10,
              delay: 3000
            })
          ).subscribe(result => {
            file.status = 'Completed';
            this.cd.detectChanges();
            this.mapCommunicatorService.mapCommunicator.next('refresh_list')
          } ,error => {
            file.status = 'Error';
            this.cd.detectChanges();
          })
          file.uploaded = true;
        }, err => {
          file.status = 'Error';
          this.cd.detectChanges();
        })
      }

    })
  }

  filesToSend(files: any[]) {
    return files.filter(f=>f.progress === 0 ).length;
  }
}
