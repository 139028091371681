<div class="mat-typography" [formGroup]="newUserGroup">
    <div class="dialog-header">
        Invite New User
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        <p class="input-label">
            Email
        </p>
        <mat-form-field appearance="outline">
            <input matInput formControlName="email" placeholder="Email" required>
            <mat-error *ngIf="newUserGroup.get('email')?.hasError('required')">
                Email is required
            </mat-error>
            <mat-error *ngIf="newUserGroup.get('email')?.hasError('regex')">
                Email is required
            </mat-error>
        </mat-form-field>
        <p class="input-label">
            Role
        </p>
            <mat-select formControlName="role" style="text-transform: capitalize" class="role-select" panelClass="role-panel">
                <mat-option [value]="role" *ngFor="let role of roles" style="text-transform: capitalize">{{ role }}</mat-option>
            </mat-select>

    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button cdkFocusInitial class="frmg-primary-button" [disabled]="newUserGroup.invalid && isLoading"
            (click)="sendInvite()">Send Invitation
    </button>
</div>
