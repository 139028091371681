import { Injectable } from '@angular/core';
import { LayerModel, LayerStateChangeModel, LayerTypeEnum } from '../models/map-layer.model';
import { BehaviorSubject, Subject, Observable, Subscription } from 'rxjs';
import { testLayers } from '../models/map-layer.model';
import { Feature } from 'ol';
import { FileVO, UploadService } from './upload.service';
import { AreaItemModel, GroupVO } from '../models/area-item.model';
import { basemapLayersConfig } from '../map/config/layerConfig';
import { BaseApiService } from './base-api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class MapService extends BaseApiService{

  

  private mapLayerList: LayerModel[] = testLayers;
  
  private layerBehaviorSubject$ = new BehaviorSubject<LayerModel[]>(this.mapLayerList);

  private selectedFeatures: AreaItemModel[] = []; // service copy of

  public areaListFeatures: Feature[] = []; // add features from all layers and draw layer

  public groupList: GroupVO[] = [];

  private selectedAreasBehaviorSubject$ = new BehaviorSubject<AreaItemModel[]>([]);

  // this is for one-of adds or deletes of a layer example, after intial area load from DB
  private mapLayerChangeSubject$ = new Subject<LayerModel>(); // to notify of layer adds or deletes

  // this is for property change on a layer so visibility or opacity
  private layerStateChangeSubject$ = new Subject<LayerStateChangeModel>();

  // new areas loaded from import
  // danny todo
  //private newAreasAddedSubject$ = new Subject<Feature[]>();
  private newAreasAddedSubject$ = new Subject<any[]>();

  // for first load of areas on opening app but NOT imports
  private areasLoadedFromDbSubject$ = new Subject<Feature[]>();

  
  

  // use one subscriptions object to hold all subscriptions
  private subscriptions: Subscription = new Subscription();

  private geoFile!: FileVO;

  constructor(
    private uploadService: UploadService,
    http: HttpClient,
    router: Router,
    private userService: UserService
  ) {
    super(http, router);
    this.subscriptions.add(
      this.uploadService.getUploadObservable()
        .subscribe((file) => {
          this.geoFile = file;
          //alert(this.geoFile.fileName.split('.')[0]);
          //this.homeList = features;
          //this.cdr.detectChanges();
          //this.homeList = features;
          //this.appRef.tick();

          // notify of new layer
          this.notifyNewLayer(this.geoFile);
        })
    )
  }

  public addMapLayerItem(item: LayerModel) {
    this.mapLayerList.push(item);
  }

  public getMapLayerObservable(): Observable<LayerModel[]> {
    return this.layerBehaviorSubject$.asObservable();
  }

  /*
      Use this to subscribe to first load of areas from DB, not import
  */
  public getAreasFirstLoadFromDbObservable(): Observable<Feature[]> {
    return this.areasLoadedFromDbSubject$.asObservable();
  }

  


  /*
      subscribe to this to see any area selection events
  */
  public getSelectedAreasObservable(): Observable<AreaItemModel[]> {
    return this.selectedAreasBehaviorSubject$.asObservable();
  }

  // to get notifications of add or delete layers
  public getMapLayerChangeObservable(): Observable<LayerModel> {
    return this.mapLayerChangeSubject$.asObservable();
  }

  // to get notifications of layer property changes from layer manager (typically)
  public getLayerStateChangeObservable(): Observable<LayerStateChangeModel> {
    return this.layerStateChangeSubject$.asObservable();
  }

  // one or more area selections or updates
  public updateSelectedFeatures(selectedAreas: AreaItemModel[]): void {
    this.selectedFeatures = selectedAreas;
    this.selectedAreasBehaviorSubject$.next(selectedAreas);
  }

  public getSelectedFeatures(): AreaItemModel[] {
    return this.selectedFeatures;
  }


  // for subscribing to new area list added
  public getAddAreaListObservable(): Observable<any[]> {
    return this.newAreasAddedSubject$.asObservable();
  }

  // danny todo
  //public addNewAreasToAreaList(features: Feature[]): void {
  public addNewAreasToAreaList(features: any[]): void {
    // now emit an event
    this.newAreasAddedSubject$.next(features);
  }

  public notifyNewLayer(file: FileVO) {

    const filetype = file.fileName.split('.').pop() === 'json' ? LayerTypeEnum.GeoJSON : LayerTypeEnum.KML;
    const newLayer:LayerModel = {
      layerName: file.fileName.split('.')[0],
      visibleState: true,
      deletable: true,
      editable: true,
      downloadable: false,
      type: filetype,
      thumbUrl: '',
      projection: 'EPSG: 3857',
      opacity: 100,
      layerFileData: file.fileData
    }
    // layer object created, so emit new layer
    this.mapLayerChangeSubject$.next(newLayer);
  }

  setLayerStateChange(state: LayerStateChangeModel): void {

    // if base layer it is mutually exclusive but ignore opacity state changes
    if (state.layerType === 'base' && state.property === 'visibleState') {

      basemapLayersConfig.forEach((layer) => {
        if (layer.visibleState === true) {
          const offState: LayerStateChangeModel = {
            layerName: layer.layerName,
            layerType: layer.type,
            property: 'visibleState',
            value: false
          }

          this.layerStateChangeSubject$.next(offState);
        }
      })
    }

    // now set the selected on
    this.layerStateChangeSubject$.next(state);
  }

  getLocation(locationString: string = '1690 Old Carp Road, Kanata'): void {

    const resource = 'location/search';
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/${resource}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    const params = new HttpParams()
      .set('place', encodeURIComponent(locationString));
    this.http.get<any>(endpoint, { params: params, headers })
      .subscribe((location) => {
      })
  }
}
