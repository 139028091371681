import { SidebarStateEnum } from "../models/sfAppState.model";

export class ChangeSidebarLeftAction {
    static readonly type = '[SkyForest] Change Sidebar Left';

    constructor(public newSidebarLeftState: string) {

    }
}

/*  ------------  Dashboard Menu Actions -------------------  */
export class ToggleDashboardMenuAction {
    static readonly type = '[SkyForest] Toggle Dashboard Menu';

    constructor(public newDashboardMenuState: string) {

    }
}

/*  ------------  Downloads Menu Actions -------------------  */
export class ToggleDownloadsPageAction {
    static readonly type = '[SkyForest] Toggle Downloads Page';

    constructor(public newDownloadsPageState: string) {

    }
}

export class ToggleReportsPageAction {
    static readonly type = '[SkyForest] Toggle Reports Page';

    constructor(public newReportsPageState: string) {

    }
}



export class ToggleLayerManagerAction {
    static readonly type = '[SkyForest] Toggle Layer Manager Panel';

    constructor(public newLayerManagementState: string) {

    }
}

export class ToggleAreaManagerAction {
    static readonly type = '[SkyForest] Toggle Area Manager Panel';

    constructor(public newAreaManagementState: string) {

    }
}

export class SetChartsPageMenuAction {
    static readonly type = '[SkyForest] Select Charts Page';

    constructor(public newChartsPageMenuState: string) {

    }
}

export class SetReportPageMenuAction {
    static readonly type = '[SkyForest] Select Reports Page';

    constructor(public newReportsPageMenuState: string) {

    }
}


export class SetUserPageMenuAction {
    static readonly type = '[SkyForest] Select User Page';

    constructor(public newUserPageMenuState: string) {

    }
}


/*  ------------  Risk Map Actions -------------------  */
export class ToggleMapMenuAction {
    static readonly type = '[SkyForest] Toggle Map Menu';

    constructor(public newMapMenuState: string) {

    }
}

/*  ------------  Lower MenuBar Actions -------------------  */
export class SetSettingsMenuAction {
    static readonly type = '[SkyForest] Select Settings Page';

    constructor(public newSettingsPageMenuState: string) {

    }
}

export class ToggleLanguageAction {
    static readonly type = '[SkyForest] Toggle Language';

    constructor(public newLanguageState: string) {

    }
}

export class TogglePolygonToolAction {
    static readonly type = '[SkyForest] Toggle Polygon Tool';

    constructor(public newPolygonToolState: string) {

    }
}

export class ToggleUploadDialogAction {
    static readonly type = '[SkyForest] Toggle Upload Dialog';

    constructor(public newUploadDialogState: string) {

    }
}






export class ChangeSidebarRightAction {
    static readonly type = '[SkyForest] Change Sidebar Right';

    constructor(public newSidebarRightState: string) {

    }
}

export class ChangeBottomDrawerAction {
    static readonly type = '[SkyForest] Change Bottom Drawer';

    constructor(public newBottomDrawerState: string) {

    }
}