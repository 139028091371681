import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Saver, SAVER } from "./providers/saver.provider";
import { Download } from "../models/general.models";
import { download } from '../utils';
import { UserService } from "./user.service";

@Injectable({providedIn: 'root'})
export class DownloadService {


  constructor(
    private http: HttpClient,
    @Inject(SAVER) private save: Saver,
    private userService: UserService
  ) {
  }



  download(url: string, filename?: string): Observable<Download> {
    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.userService.currentAccessToken}`
      })
    }).pipe(download(blob => this.save(blob, filename)))
  }


  blob(url: string, filename?: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.userService.currentAccessToken}`
      })
    })
  }
}