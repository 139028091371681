<div class="no-margin-wrapper">
    <div class="download-dialog-container">
        <div class="header-row">
            Select layers to request download
        </div>
        <div class="horizontal-separator">

        </div>
        <div class="content-area-row">

            <div class="areas-list-col">
                <mat-expansion-panel *ngIf="areaList.length">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="area-title-container">
                                <span>{{"layers.selected-area" | translate}} ({{areaList.length}})</span>
                                <span class="area-title-total">Total area: <span>{{totalSize(areaList) | m2km2}} km<sup>2</sup></span></span>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-divider></mat-divider>
                    <div *ngFor="let area of areaList; let $index = index;" class="area-row">
                        <div class="area-part">
                            <div class="area-item">
                                {{area.properties.name}}
                            </div>
                            <div class="area-size">
                                {{area.properties.area_in_sqm | m2km2}} km<sup>2</sup>
                            </div>
                        </div>
                        <button mat-raised-button class="frmg-secondary-button"
                                (click)="removeArea(areaList,$index)">{{"actions.remove" | translate}}</button>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="lineList.length">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="area-title-container">
                                <span>{{"layers.selected-lines" | translate}} ({{lineList.length}})</span>
                                <span class="area-title-total">Total area: <span>{{totalSize(lineList) | m2km2}} km<sup>2</sup></span></span>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-divider></mat-divider>
                    <div *ngFor="let area of lineList; let $index = index;" class="area-row">
                        <div class="area-part">
                            <div class="area-item">
                                {{area.properties.name}}
                            </div>
                            <div class="area-size">
                                {{area.properties.area_in_sqm | m2km2}} km<sup>2</sup>
                            </div>
                        </div>
                        <button mat-raised-button class="frmg-secondary-button"
                                (click)="removeArea(lineList,$index)">{{"actions.remove" | translate}}</button>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="pointList.length">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="area-title-container">
                                <span>{{"layers.selected-points" | translate}} ({{pointList.length}})</span>
                                <span class="area-title-total">Total area: <span>{{totalSize(pointList) | m2km2}} km<sup>2</sup></span></span>
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-divider></mat-divider>
                    <div *ngFor="let area of pointList; let $index = index;" class="area-row">
                        <div class="area-part">
                            <div class="area-item">
                                {{area.properties.name}}
                            </div>
                            <div class="area-size">
                                {{area.properties.area_in_sqm | m2km2}} km<sup>2</sup>
                            </div>
                        </div>
                        <button mat-raised-button class="frmg-secondary-button"
                                (click)="removeArea(pointList,$index)">{{"actions.remove" | translate}}</button>
                    </div>
                </mat-expansion-panel>
            </div>

            <div class="layers-list-col">
                <div class="layer-label">
                    <div class="title">Select layers to request</div>
                </div>
                <div class="horizontal-separator"></div>

                <div class="layer-list-container">
                    <div class="layer-element" *ngFor="let layer of data.layerList">
                        <img [src]="defaultTile" alt="layer-tile">
                        <span>
                            {{ layer.display_name }}
                        </span>
                        <mat-checkbox (change)="layerSelected($event, layer)">
                        </mat-checkbox>
                    </div>
                </div>

            </div>
        </div>
        
    </div>
    <div class="button-row">
            <button mat-raised-button (click)="closeDialog()">{{"actions.cancel" | translate}}</button>
            <button mat-raised-button color="primary" class="frmg-primary-button" (click)="goToDownloads()"
                    [disabled]="!selectedLayers.length && isLoading">{{"actions.request" | translate}}</button>
    </div>
</div>