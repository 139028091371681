import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../../environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastNotificationService } from "../../../services/toast-notification.service";

@Component({
  selector: 'frmg-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  public passwordFg: FormGroup
  public hidePassword: Array<boolean> = [true, true, true]

  constructor(private http: HttpClient, private fb: FormBuilder,
              private toastNotificationService: ToastNotificationService) {
    this.passwordFg = this.fb.group({
        current_password: ["", [Validators.required, Validators.minLength(8)]],
        new_password: ["", [Validators.required, Validators.minLength(8)]],
        confirm_new_password: ["", [Validators.required, Validators.minLength(8)]]
      }
    )
  }

  togglePasswordVisibility(index: number): void {
    this.hidePassword[index] = !this.hidePassword[index];
  }

  changePassword(): void {

    const passwordData = this.passwordFg.value;
    if (passwordData.new_password !== passwordData.confirm_new_password) {
      this.toastNotificationService.showNotification({
        type: "error",
        message: "Passwords does not match."
      });
      return;
    }

    this.http.post(environment.baseUrl + '/users/update-password/', passwordData)
      .subscribe(
        () => {
          this.toastNotificationService.showNotification({type: "success", message: "Password changed successfully."});
          this.passwordFg.setValue({
            current_password: '',
            new_password: '',
            confirm_new_password: ''
          })
        },
        (error) => {
          this.toastNotificationService.showNotification({
            type: "error",
            message: "An error occurred while changing the password."
          });
        }
      );
  }

}