import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router) {}

    // TODO update the interface to post 15.x interface
  canActivate(): boolean {
    const accessToken = this.userService.currentAccessToken;

    if (accessToken !== '' || environment.production === false) {
      // Access token exists, allow navigation  
      return true;
    }
    else if (localStorage.getItem('access_token')) {
        return true;
      }
    else {
      // Access token does not exist, redirect to login page
      
      this.router.navigate(['login']);
      return false;
    }
  }
}