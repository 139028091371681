<div class="mat-typography" [formGroup]="newProjectGroup">
    <div class="dialog-header">
        {{!data.project ? "Create new " : "Edit "}} {{data.parent.path == 'root'? 'project' : 'folder'}}
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        <p class="input-label">
            {{data.parent.is_root ? "Project" : "Folder" }} Name
        </p>
        <mat-form-field appearance="outline">
            <input matInput formControlName="name" placeholder="Name" required>
            <mat-error *ngIf="newProjectGroup.get('name')?.hasError('required')">
                Name is required
            </mat-error>
        </mat-form-field>
        <p class="input-label desc-label">
            <span>Short Description</span> <span class="opt-label">Optional</span>
        </p>
        <mat-form-field appearance="outline" class="textarea">
              <textarea matInput formControlName="description"
                        placeholder="Short Description"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"></textarea>
        </mat-form-field>
    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button cdkFocusInitial class="frmg-primary-button" [disabled]="newProjectGroup.invalid"
            (click)="addProject()">{{!data.project ? "Create" : "Save"}}
    </button>
</div>
