import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ReportService } from "../../../services/report.service";
import { AreaCollections, RiskReport } from "../../../models/report.model";
import { saveBlob } from "../../../utils";
import { LayerService } from "../../../services/layer.service";
import { debounceTime, take, tap } from "rxjs";
import { NotificationService } from "../../../services/notification.service";

@Component({
  selector: 'frmg-report-area-details',
  templateUrl: './report-area-details-modal.component.html',
  styleUrls: ['./report-area-details-modal.component.scss']
})
export class ReportAreaDetailsComponent implements OnInit {
  @Input() detailsId!: string;
  @Input() id!: string;
  @Input() collectionId!: string;

  public data: any = {};
  public exposureLayerGradient: string;
  public nearestPointIcon: string = 'assets/icons/map-pin-grey.svg';
  public exposureIcon: string = 'assets/icons/map-pin-inactive.svg';
  public highRiskIcon: string = 'assets/icons/circle-up-grey.svg';
  public lowRiskIcon: string = 'assets/icons/circle-down-green.svg';
  public distanceIcon: string = 'assets/icons/distance-icon.svg';
  public lineLength: string;

  constructor(
    private reportService: ReportService,
    private layerService: LayerService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {

    this.reportService.getReportDetails(this.id, this.collectionId,this.detailsId).subscribe(resp=>{
      this.data = {...this.data, ...resp};
      this.lineLength = Math.abs((this.data.from_distance - this.data.to_distance)/1000).toFixed(0)
      this.reportService.breadCrumbs.next([...this.reportService.breadCrumbs.getValue(),resp.address.name])
    })
    this.reportService.getReport(this.id).subscribe(resp=>{
      this.data = {...this.data, ...resp};
    })
    this.layerService.getLayers().pipe(debounceTime(0), take(1)).subscribe(reesp=>{
      const exposureLayer = reesp.find(layer=>layer.display_name.toLowerCase() === 'exposure')
      const colors = Object.entries(exposureLayer.explicit_color_map).map(([key, value]) => `${value} ${+key/2}%`).join(', ');
      this.exposureLayerGradient =  `linear-gradient(to right, ${colors})`;
    })
  }

  public downloadArea(event: Event): void {
    event.stopPropagation();
    const id = this.detailsId;
    const filename = this.data.analysis.name;
    this.reportService.getAreaReportPdf(this.data.report.id, id, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename+'.xlsx',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
    })

  }
  public downloadAreaXLSX(event: Event): void {
    event.stopPropagation();
    const id = this.data.analysis.id;
    const filename = this.data.analysis.name;
    this.reportService.getAreaReportXLSX(this.data.report.id, id, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename+'.xlsx',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
    })
  }

  //
  // public closeDialog(result?: any): void {
  //   this.dialogRef.close(result);
  // }

}
