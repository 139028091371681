import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import { forkJoin, map, Observable, ReplaySubject, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from "./user.service";
import { Area, AreaProperties, FeatureCollection, FilterBody } from "../models/general.models";
import { Project } from "../models/project.module";


@Injectable({
  providedIn: 'root'
})
export class AreaService {

  private baseUrl: string = environment.baseUrl;

  public areasList: ReplaySubject<Array<Area>> = new ReplaySubject<Array<Area>>(1);
  public filterBody: FilterBody = {sort: 'created_at', tags: []};

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {
  }

  private applyFilter( areas: Array<Area>):  Array<Area> {
    let newList: Array<Area>;
    if (this.filterBody.tags && this.filterBody.tags.length) {
      newList = areas.filter(area => (area.properties.groups || []).filter(group=>{
          return this.filterBody.tags.some(tag=>tag.id===group.id)
        }).length
      )
    } else {
      newList = areas
    }
    return newList;
  }

  public getAreas(search?: string, fetch?: boolean, projectId?: number | string): ReplaySubject<Array<Area>> | Observable<Array<Area>> {
    let endpoint = `${this.baseUrl}/${this.userService.mySlug}/areas/search/?order_by=properties.${this.filterBody.sort}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })


    if (search && search.length) {
      endpoint += '&q=' + search;
    }
    if (projectId) {
      endpoint += '&project_id=' + projectId;
    }
    if (fetch || !this.areasList) {
      if (!this.areasList) this.areasList = new ReplaySubject();
      return this.http.get<FeatureCollection<Area>>(endpoint, {headers}).pipe(
        map(response => response.features),
        tap((result) => {
          this.areasList.next(this.applyFilter(result));
        }),
        map(response => this.applyFilter(response))
      )
    }
    return this.areasList;
  }

  public clearAreaList() {
     this.areasList = new ReplaySubject<Array<Area>>(1);
  }

  public searchAreas(search?: string, projectId?: number | string): Observable<Array<Area>> {
    let endpoint = `${this.baseUrl}/${this.userService.mySlug}/areas/search/?order_by=properties.${this.filterBody.sort}`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })


    if (search && search.length) {
      endpoint += '&q=' + search;
    }
    if (projectId) {
      endpoint += '&project_id=' + projectId;
    }
    endpoint += '&limit=100000';
    return this.http.get<FeatureCollection<Area>>(endpoint, {headers}).pipe(
      map(response => response.features));
  }

  public updateAreas(newList: Array<Area>) {
    this.areasList.next(this.applyFilter(newList));
  }

  public createArea(area: any): Observable<Area> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/areas/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.post<Area>(endpoint, area, {headers})
  }

  public uploadAreaFile(file: { file: File }, projectId: string): Observable<{ message: string, task_id: string }> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/areas/upload/project/${projectId}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    const formData: FormData = new FormData();
    formData.append('file', file.file);
    return this.http.post<{ message: string, task_id: string }>(endpoint, formData, {headers})
  }

  public deleteArea(ids: number[]): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return forkJoin(ids.map(id => {
      const endpoint = `${this.baseUrl}/${this.userService.mySlug}/areas/${id}/`;
      return this.http.delete(endpoint, {headers})
    }))
  }

  public changeArea(area: Area, body: Partial<AreaProperties>): Observable<Area>{
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/areas/${area.properties.id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });
    return this.http.patch<Area>(endpoint, {properties :body},{headers});
  }

  public getShared(area: any): Observable<Area> {
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/areas/share/${area.properties.id}/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<Area>(endpoint, {headers})
  }
}
