import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { COMMA, ENTER, SEMICOLON } from "@angular/cdk/keycodes";
import { MatChipGrid } from "@angular/material/chips";
import { debounceTime, defaultIfEmpty, forkJoin, map, mergeMap, Observable, of, share, take } from "rxjs";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { GroupService } from "../../../../services/group.service";
import { Group } from "../../../../models/general.models";
import { AreaService } from "../../../../services/area.service";
import { ToastNotificationService } from "../../../../services/toast-notification.service";
import { ChildProject, Project } from "../../../../models/project.module";
import { ProjectService } from "../../../../services/project.service";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { MatSelect } from "@angular/material/select";
import { MatOption } from "@angular/material/core";
import { filter } from "rxjs/operators";
import { MapCommunicatorService } from "../../map-communicator.service";

interface FlatProject extends Omit<Project, 'children'> {
  level: number,
  expandable: boolean
  children: Array<FlatProject>
}

@Component({
  selector: 'frmg-save-area-dialog',
  templateUrl: './save-area-dialog.component.html',
  styleUrls: ['./save-area-dialog.component.scss']
})
export class SaveAreaDialogComponent implements OnInit {
  public saveAreaGroup: FormGroup;
  public separatorKeysCodes = [ENTER, COMMA, SEMICOLON];
  public tagsList: Observable<Array<Group>>;
  public folders: Array<Project> = [];
  public isLoading = false;
  public duplicated = false;
  private _transformer = (node: FlatProject, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      level: level,
      ...node
    };
  };
  public treeControl = new FlatTreeControl<FlatProject>(
    node => node.level,
    node => node.expandable,
  );

  public treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagsChipList') tagsChipList: MatAutocomplete;
  @ViewChild('tagsChipList', {static: true}) chipList: MatChipGrid;
  @ViewChild('selectPanel') selectPanel: MatSelect;
  @ViewChild('folderOption') folderOption: MatOption;
  public isEdit: boolean = false;
  public selectedFolder;

  constructor(
    private dialogRef: MatDialogRef<SaveAreaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private groupService: GroupService,
    private projectService: ProjectService,
    private toastNotificationService: ToastNotificationService,
    private mapCommunicatorService: MapCommunicatorService,
    private areaService: AreaService,
    private cd: ChangeDetectorRef) {
    this.isEdit = !!this.data.layer.geometry;
    if (this.data.project && this.data.project.path !== 'root') this.selectedFolder = this.data.project;
    this.saveAreaGroup = this.fb.group({
      name: [this.isEdit ? this.data.layer.properties.name : '', [Validators.required]],
      folder: [this.data.project, [Validators.required]],
      tags: [this.isEdit ? this.data.layer.properties.groups : [], [Validators.maxLength(10)]],
      tagInput: ['']
    })
  }


  ngOnInit(): void {
    this.tagsList = this.saveAreaGroup.get('tagInput')!.valueChanges
      .pipe(
        debounceTime(500),
        mergeMap(tag => {
          if (typeof tag !== "string") {
            tag = '';
          }
          return this.groupService.searchGroup(tag)
        }),
        share()
      )

    this.tagsList.subscribe(groups=> {
      this.duplicated = groups.some(group=>group.name === this.saveAreaGroup.get('tagInput').value)
    })
    this.projectService.getProjectsByPath('root').pipe(
      map(resp => resp),
      mergeMap(projects => {
        return this.createRecursiveTreeRequests(projects.children);
      })
    ).subscribe(resp => {
      // @ts-ignore
      this.dataSource.data = resp;
      // @ts-ignore
      if(this.selectedFolder) this.expandParents(this.findNode(this.treeControl.dataNodes))
    })
  };

  private findNode(nodes) {
    for (const node of nodes) {
      if (node.id === this.selectedFolder.id) return node

      if (node.rows) {
        let match = this.findNode(node.children)
        if (match) return match
      }
    }
  }

  expandParents(node) {
    const parent = this.getParent(node);
    this.treeControl.expand(parent);

    if (parent && parent.level > 0) {
      this.expandParents(parent);
    }
  }

  private getParent(node) {
    const {treeControl} = this;
    const currentLevel = treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i];

      if (treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null
  }


  public onTreeNodeSelect(node: FlatProject) {
    this.selectedFolder = node;
    this.cd.detectChanges();
    this.saveAreaGroup.get('folder').setValue(node);
    this.selectPanel.toggle();
    // this.selectPanel.options.changes.subscribe(update=>{
    //   this.selectPanel.options.get(0).value.id === this.folderOption.value.id && this.folderOption.select(true);
    //   this.selectPanel.toggle();
    //   this.cd.detectChanges();
    // })


  }

  public compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }


  private createRecursiveTreeRequests(projects: ChildProject[]): Observable<Project[]> {
    projects = projects.filter(project => !project.is_archived);
    return forkJoin(projects.map(project => this.projectService.getProjectById(project.id))).pipe(
      mergeMap(projects => forkJoin(projects.map(project => {
        if (!!project.children && project.children.length) {
          return this.createRecursiveTreeRequests(project.children)
            .pipe(map(projects => {
              // @ts-ignore
              project.children = projects;
              return project;
            }))
        } else {
          return of(project)
        }
      })))
    )
  }

  hasChild = (_: number, node: FlatProject) => node.expandable;

  public closeDialog(result: any): void {
    this.dialogRef.close(result);
  }

  public save(): void {
    this.isLoading = true;
    const formValues = this.saveAreaGroup.value;
    const tags: Group[] = formValues.tags;
    const nonExistingTags = tags.filter(group => group.id === undefined);
    const createTagsRequests: Array<Observable<Group>> = [];
    if (nonExistingTags.length) {
      nonExistingTags.forEach(group => createTagsRequests.push(this.groupService.createGroup(group)));
    }

    forkJoin(createTagsRequests).pipe(
      defaultIfEmpty([]),
      map(groupResponse => {
        return tags.map(tag => {
          if (tag.id !== undefined) {
            return tag;
          } else {
            return groupResponse.find(group => group.name === tag.name) || groupResponse[0];
          }
        });
      }),
      mergeMap(completeTags => {
        const tags = completeTags.map(tag => tag.id);
        if (this.isEdit) {
          return this.areaService.changeArea(this.data.layer, {
            group_ids: tags,
            name: formValues.name
          })
        } else {
          const area = {
            geometry: this.data.layer,
            properties: {
              name: formValues.name,
              group_ids: tags,
              project_id: formValues.folder.id
            },
          }
          return this.areaService.createArea(area);
        }
      })
    )
      .subscribe(resp => {
        this.areaService.areasList.pipe(debounceTime(0), take(1)).subscribe(
          currentAreas => {
            if (this.data.project.id == formValues.folder.id) {
              if (this.isEdit) {
                const index = currentAreas.findIndex(area => area.properties.id === resp.properties.id);
                currentAreas[index] = resp;
                this.areaService.updateAreas([...currentAreas]);
              } else {
                this.areaService.updateAreas([...currentAreas, resp]);
                this.mapCommunicatorService.mapCommunicator.next('added_new_area')
              }
            }
          }
        )
        this.isLoading = false;
        this.toastNotificationService.showNotification({message: 'Saved successfully', type: "success"})
        this.closeDialog(true)
      }, error => {
        this.isLoading = false;
        this.toastNotificationService.showNotification({message: Object.values(error.error)[0][0], type: "error"})
      })
  }

  public removeTag(tag: Group): void {
    const tags: Group[] = this.saveAreaGroup.get('tags')?.value;
    const index = tags.findIndex(group => group.name === tag.name);
    if (index >= 0) {
      tags.splice(index, 1);
      this.saveAreaGroup.get('tags')?.setValue(tags, {emitEvent: true});
    }
  }

  public addTag(value): void {
    let tags: Group[] = this.saveAreaGroup.get('tags')?.value;
    if (!tags || tags.findIndex(tag => value.name === tag.name) === -1) {
      if (!tags) {
        tags = [];
      }
      tags.push(value);
      this.saveAreaGroup.get('tags')?.setValue(tags, {emitEvent: true});
    }
    this.tagInput.nativeElement.value = '';
    this.saveAreaGroup.get('tagInput')?.setValue('', {emitEvent: true});
  }

  public displayName(project: Project) {
    return project.name || '';
  }
}
