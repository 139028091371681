<div class="team-settings">
    <div class="team-header">
        <div class="team-header-content">
            <h3 class="team-title">Team</h3>
            <p class="team-description">View your team members.</p>
        </div>
        <div class="team-actions">
            <!--      <div class="search-field">-->
            <!--        <input type="text" class="search-input" placeholder="Search" [(ngModel)]="searchTerm" (input)="searchMembers()">-->
            <!--      </div>-->
            <div class="main-button" (click)="openInviteModal()">
                <div class="invite-team-member-button">Invite Team Member</div>
            </div>
        </div>
    </div>
    <div class="team-content">
        <table mat-table class="team-table" [dataSource]="teamData" matSort>
            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header> User</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.email }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="fullName">
                <mat-header-cell *matHeaderCellDef> Full Name</mat-header-cell>
                <mat-cell *matCellDef="let user"> {{ user.display_name }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Role</mat-header-cell>
                <mat-cell *matCellDef="let user" >
                    <mat-select [(ngModel)]="user.role"  class="role-select" panelClass="role-panel"
                                *ngIf="user.role !== 'admin' && user.email !== currentUser.email && currentUser && currentUserRole !== 'member'">
<!--                        <mat-option [value]="123" (click)="setNewRole({id: 123, name: '123', permission: []}, user)">-&#45;&#45;</mat-option>-->
<!--                        <mat-option [value]="null" >-&#45;&#45;</mat-option>-->
                        <mat-option [value]="role" *ngFor="let role of availableMembership" (click)="setMembership(role, user)"
                        style="text-transform: capitalize">{{role === 'owner' ? 'admin' : 'member'}}</mat-option>
<!--                        <button mat-raised-button class="frmg-primary-button" (click)="openRoleModal()"-->
<!--                                style="width: calc(100% - 20px); margin: 10px">-->
<!--                            <span class="icon-Plus"></span> Add Role-->
<!--                        </button>-->
                    </mat-select>
                    <ng-container *ngIf="user.email == currentUser.email || currentUserRole == 'member'">{{user.role === 'owner' ? 'admin' : 'member'}}</ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef class="actions-cell"></mat-header-cell>
                <mat-cell *matCellDef="let user" class="actions-cell">
                    <span class="icon-remove-icon" (click)="removeMember(user.id)"
                          *ngIf="user.email !== currentUser.email &&  currentUserRole !== 'member'"></span>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>
    </div>
</div>

