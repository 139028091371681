import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'frmg-draw-complete-dialog',
  templateUrl: './draw-complete-dialog.component.html',
  styleUrls: ['./draw-complete-dialog.component.scss']
})
export class DrawCompleteDialogComponent {

  areaName: string = '';
  description: string = '';
  
  constructor(
    public dialogRef: MatDialogRef<DrawCompleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any // model for new area -name, id
  ) {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
