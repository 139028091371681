import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: 'frmg-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  public selectedMenuItem = ''
  constructor(private route: ActivatedRoute,
              private router: Router) {
    this.selectedMenuItem = this.route.firstChild.routeConfig.path;
    this.router.events.pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe((event) => {
        this.selectedMenuItem = this.route.firstChild.routeConfig.path;
      })
  }

  onMenuItemClick(menuItem: string) {
    this.router.navigate([`./${menuItem}`], {relativeTo: this.route});
  }
}
