import { Component } from "@angular/core";


@Component({
  selector: 'frmg-charts-page',
  templateUrl: './charts-page.component.html',
  styleUrls: ['./charts-page.component.scss']
})
export class ChartsPageComponent {

}

