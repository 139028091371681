<div class="mat-typography">
    <div class="dialog-header">
        Are you sure you want to delete selected area{{data.areas.length !== 1? "s" : ""}}?
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        If you delete these areas, they will be removed from the list permanently.
    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close class="frmg-secondary-button">No</button>
    <button mat-button class="frmg-primary-button"
            [disabled]="isLoading"
    (click)="delete()">Yes, please delete.</button>
</div>
