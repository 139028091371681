<div class="mat-typography">
    <div class="dialog-header">
<!--        Download {{data.areas.length}} file{{data.areas.length !== 1? "s" : ""}}.-->
        Download Report.
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        <div class="download-block">
            <span class="block-title">
                <span>Select Preferred Format</span>
            </span>
            <mat-radio-group class="radio-group" [(ngModel)]="format">
                <mat-radio-button value="pdf">PDF</mat-radio-button>
                <mat-radio-button value="xlsx">XLSX</mat-radio-button>
<!--                <mat-radio-button value="area_in_sqm">CSV</mat-radio-button>-->
            </mat-radio-group>
        </div>
        <div class="download-block">
            <span class="block-title">
                <span>Select metadata columns</span>
                <span>Optional</span>
            </span>
            <mat-form-field appearance="outline" >
                <mat-select [formControl]="metadataCtrl" [multiple]="true" placeholder="Select by">
                    <mat-option *ngFor="let meta of data.metadata" [value]="meta">{{meta}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close class="frmg-secondary-button">Cancel</button>
    <button mat-button class="frmg-primary-button"
            [disabled]="isLoading"
    (click)="delete()">Download</button>
</div>
