import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'frmg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger(
      'testTrigger', [
        state('one', style({width: '100px'}) ),
        state('two', style({width: '500px'})),
        transition('one => two', animate('250ms ease-in'), {delay: 1000}),
        transition('two => one', animate('250ms ease-in'), {delay: 1000})
      ]
    )
    ]
})
export class AppComponent {
  title = 'SKYFOREST';
  constructor(
    private translateService: TranslateService
  ) {
    translateService.setDefaultLang('en');
    translateService.use('en');

  }
}
