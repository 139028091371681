import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DownloadDialogComponent } from "../download-dialog/download-dialog.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastNotificationService } from "../../../../services/toast-notification.service";
import { Area } from "../../../../models/general.models";
import { ReportCreate } from "../../../../models/report.model";
import { ReportService } from "../../../../services/report.service";
import { debounceTime } from "rxjs";

@Component({
  selector: 'frmg-risk-report-dialog',
  templateUrl: './risk-report-modal.component.html',
  styleUrls: ['./risk-report-modal.component.scss']
})
export class RiskReportModalComponent implements OnInit {
  public saveRiskReportGroup: FormGroup;
  public warningTitle: string = 'Risk calculation is available for using \'points\' only.';
  public warningBody: string = 'Please note that risk calculation functionality is currently unavailable for polygons, rectangles, and lines. If any of these options are selected, they will not be employed in report generation.';
  public errorTitle: string = 'Risk calculation is available for using \'points\' only.';
  public riskTooltip: string = 'This feature sets the range of Exposure values of interest for risk calculation mapping. Values below or above the threshold range are not included in the generated risk report map (they are greyed out). The threshold range helps focus on critical areas of concern.';
  public boundaryTooltip: string = 'This features allows you to add and adjust a circular radius around your point of interest. This boundary is used to focus your area of interest and analysis.';
  public nearestTooltip: string = 'This feature identifies and marks the nearest high-risk point. High-risk areas are determined by the Nearest Risk Threshold. ';
  public extremePointsTooltip: string = 'This feature sets the range of Exposure values to be considered for nearest high-risk calculation.';
  public zoneTooltip: string = 'This features creates bands around your point or area of interest. The average fire risk is calculated within each band.';
  public hasPoints: boolean = false;
  public isLoading: boolean = false;

  public isAdvancedOpened: boolean;

  constructor(
    private dialogRef: MatDialogRef<DownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<Area>,
    private fb: FormBuilder,
    private toastNotificationService: ToastNotificationService,
    private reportService: ReportService) {
    this.saveRiskReportGroup = this.fb.group({
      name: ['', [Validators.required]],
      boundary: [2000, [Validators.required, Validators.min(10), Validators.max(2000)]],
      threshold: [0, [Validators.required]],
      enableExtremePoints: [true, [Validators.required]],
      zone_config: this.fb.group({
        enableZone: [false, [Validators.required]],
        zone_type: ["behind", [Validators.required]],
        point_num_circles: [2, [Validators.required, Validators.min(1)]],
        zone_width: [200, [Validators.required, Validators.min(1)]],
        target_box_count: [12, [Validators.required, Validators.min(1)]],
        zone_color: ['#74eb34', [Validators.required]],
        zone_alpha: [0.3, [Validators.required, Validators.min(0), Validators.max(1)]],
      }),
      risk_threshold: this.fb.group({
        enableNearestRisk: [true, [Validators.required]],
        nearest_risk_threshold_min: [0, [Validators.required]],
        nearest_risk_threshold_max: [200, [Validators.required]]
      })
    })

    this.saveRiskReportGroup.get('threshold').valueChanges.pipe(debounceTime(250)).subscribe(value=>{
      if (value !== this.saveRiskReportGroup.value.risk_threshold.nearest_risk_threshold_min) {
        this.saveRiskReportGroup.get('risk_threshold').get('nearest_risk_threshold_min').setValue(this.saveRiskReportGroup.value.threshold, {emitEvent: false})
      }
    })
  }

  ngOnInit(): void {
    this.hasPoints = this.data.some(area => area.geometry.type === "Point" || area.geometry.type === "MultiPoint");
  }

  public closeDialog(result?: any): void {
    this.dialogRef.close(result);
  }

  public save(): void {
    this.isLoading = true;
    const config = this.saveRiskReportGroup.value;
    const report: ReportCreate = {
      highest_threshold: 200,
      lowest_threshold: config.threshold,
      zone_config: config.zone_config,
      risk_threshold: config.risk_threshold,
      layer_id: "49b2ce9b-61f6-4638-b7e5-c30bc41bac5b",
      name: config.name,
      boundary: config.boundary,
      area_ids: this.data.map(area => area.properties.id),
      enabledLayers: [config.risk_threshold.enableNearestRisk, config.zone_config.enableZone, config.enableExtremePoints]
    }
    this.reportService.createReport(report).subscribe(resp => {
        this.isLoading = false;
        this.toastNotificationService.showNotification({type: "success", message: "Request submitted"});
        this.closeDialog();
      },
      (error) => {
        this.isLoading = false;
        const errorMsg = 'Error creating report: ';
        this.toastNotificationService.showNotification({type: "error", message: errorMsg + Object.values(Object.values(error.error)[0])[0]})
      })

  }

  validateNearestStart(value: number) {
    if (value < this.saveRiskReportGroup.value.threshold) {
      this.saveRiskReportGroup.get('risk_threshold').get('nearest_risk_threshold_min').setValue(this.saveRiskReportGroup.value.threshold, {emitEvent: false})
    }
  }
}
