<div class="mat-typography" [formGroup]="newRoleGroup">
    <div class="dialog-header">
        Add Role
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        <p class="input-label">
            Name of the role
        </p>
        <mat-form-field appearance="outline">
            <input matInput formControlName="name" placeholder="Name of the role" required>
            <mat-error *ngIf="newRoleGroup.get('email')?.hasError('required')">
                Name is required
            </mat-error>
        </mat-form-field>
        <p class="input-label desc-label">
            <span>Description</span> <span class="opt-label">Optional</span>
        </p>
        <mat-form-field appearance="outline" class="textarea">
              <textarea matInput formControlName="description"
                        placeholder="Description"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"></textarea>
        </mat-form-field>
        <p class="input-label">
            Permisions
        </p>
        <div class="perm-container">
            <span class="perm-label">USER </span>
            <mat-form-field appearance="outline">
                <mat-select formControlName="permissions_ids_user"  [multiple]="true">
                    <mat-option *ngFor="let permission of userPermissions" [value]="permission">{{permission.description}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="perm-container">
            <span class="perm-label">AREA </span>
            <mat-form-field appearance="outline">
                <mat-select formControlName="permissions_ids_area"  [multiple]="true">
                    <mat-option *ngFor="let permission of areaPermissions" [value]="permission">{{permission.description}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="perm-container">
            <span class="perm-label">TAG </span>
            <mat-form-field appearance="outline">
                <mat-select formControlName="permissions_ids_tag"  [multiple]="true">
                    <mat-option *ngFor="let permission of tagPermissions" [value]="permission">{{permission.description}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="perm-container">
            <span class="perm-label">DASHBOARD </span>
            <mat-form-field appearance="outline">
                <mat-select formControlName="permissions_ids_dashboard"  [multiple]="true">
                    <mat-option *ngFor="let permission of dashboardPermissions" [value]="permission">{{permission.description}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="perm-container">
            <span class="perm-label">DOWNLOADS </span>
            <mat-form-field appearance="outline">
                <mat-select formControlName="permissions_ids_downloads"  [multiple]="true">
                    <mat-option *ngFor="let permission of downloadsPermissions" [value]="permission">{{permission.description}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button cdkFocusInitial class="frmg-primary-button" [disabled]="newRoleGroup.invalid"
            (click)="addRole()">Add Role
    </button>
</div>
