import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { BehaviorSubject, from, Observable, tap, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastNotificationService } from "../toast-notification.service";
import { environment } from "../../../environments/environment";
import { BaseApiService } from "../base-api.service";
import { UserService } from "../user.service";
import { BillingInfo } from "../../models/team.model";

export interface TokenResponse {
  access: string;
  refresh: string;
  user: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private baseUrl = environment.baseUrlLogin;

  constructor(private router: Router, private http: HttpClient,
              private toastNotificationService: ToastNotificationService,
              private baseApiService: BaseApiService,
              private userService: UserService) {
  }

  /**
   * Intercepts an HTTP request and performs necessary modifications and error handling.
   *
   * @param {HttpRequest<any>} request - The original HTTP request to be intercepted.
   * @param {HttpHandler} next - The next handler in the HTTP request chain.
   * @return {Observable<HttpEvent<any>>} - An observable that emits an HTTP event.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('/assets/') || request.url.startsWith(`${this.baseUrl}/token/`) || request.url.includes('teams/invitations')) return next.handle(request);
    const accessToken = this.userService.currentAccessToken;
    if (accessToken) {
      request = this.addToken(request, accessToken);
    }

    return from(this.ensureUserData(request)).pipe(
      switchMap((user: any) => {
        let modifiedUrl = request.url;
        try {
          if (user && user.teams && user.teams.length > 0) {
            const firstTeamSlug = user.teams[user.teams.length - 1].slug;
            modifiedUrl = modifiedUrl.replace('{team_slug}', firstTeamSlug);
            modifiedUrl = modifiedUrl.replace('undefined', firstTeamSlug);
          }
        } catch (error) {
        }

        // if (!modifiedUrl.startsWith(this.baseUrl)) {
        //   modifiedUrl = `${this.baseUrl}${modifiedUrl}`;
        // }

        request = request.clone({url: modifiedUrl});
        return next.handle(request).pipe(
          catchError((error) => {
            if (error instanceof HttpErrorResponse) {
              switch (error.status) {
                case HttpStatusCode.Unauthorized:
                  if (request.url.includes(`${this.baseUrl}/token/refresh/`)) {
                    this.userService.clearAuthData();
                    this.toastNotificationService.showNotification({message: 'Unauthorised', type: 'warn'})
                    this.router.navigate(['login']);
                    return throwError(() => new Error('Session expired'));
                  } else {
                    return this.handle401Error(request, next);
                  }
                case HttpStatusCode.Forbidden:
                case HttpStatusCode.InternalServerError:
                  return throwError(() => error);
                default:
                  return throwError(() => error);
              }
            }
            return throwError(() => error);
          })
        );
      })
    );
  }

  /**
   * Ensures that user data is available either from local storage or by making a API request
   *
   * @param {HttpRequest<any>} request - The HTTP request object
   *
   * @return {Promise<any>} - A promise that resolves with the user data
   */
  private ensureUserData(request: HttpRequest<any>): Promise<any> {
    return new Promise<any>((resolve) => {
      const storedUser = this.userService.currentUser;
      if (storedUser) {
        resolve(storedUser);
      } else {
        if (!request.url.includes(`${this.baseUrl}/users/current/`)) {
          this.fetchCurrentUser().subscribe({
            next: (currentUser) => {
              localStorage.setItem('user', JSON.stringify(currentUser));
              resolve(currentUser);
            },
            error: (error) => {
              resolve(null);
            }
          });
        } else {
          resolve(null);
        }
      }
    });
  }

  /**
   * Adds the specified token to the authorization header of the request.
   *
   * @param {HttpRequest<any>} request - The request object to add the token to.
   * @param {string} token - The token to be added to the request.
   * @returns {HttpRequest<any>} - The new request object with the token added to the authorization header.
   */
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }


  private fetchCurrentUser(): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<any>(`${this.baseUrl}/users/current/`, {headers});
  }

  public updateCurrentUser(body: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.patch<any>(`${environment.baseUrl}/users/profile/update/`, body, {headers});
  }

  public logout() {
    this.userService.clearAuthData();
    this.router.navigate(['/login']);
  }

  public getBillingInfo(): Observable<BillingInfo> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    })
    return this.http.get<BillingInfo>(`${environment.baseUrl}/{team_slug}/billing/billing_info/`, {headers});
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => next.handle(this.addToken(request, token)))
      );
    }

    this.isRefreshing = true;
    this.refreshTokenSubject.next(null);

    const refreshToken = localStorage.getItem('refresh_token');

    if (!refreshToken) {
      this.isRefreshing = false;
      this.logout();
      this.toastNotificationService.showNotification({message: 'Unauthorised', type: 'warn'})
      return throwError(() => 'Refresh token not found');
    }
    return this.refreshToken(refreshToken).pipe(
      switchMap((tokenResponse: TokenResponse) => {
        this.isRefreshing = false;
        this.refreshTokenSubject.next(tokenResponse.access);
        localStorage.setItem('access_token', tokenResponse.access);
        return next.handle(this.addToken(request, tokenResponse.access));
      }),
      catchError((error: any) => {
        this.isRefreshing = false;
        this.logout();
        this.toastNotificationService.showNotification({message: 'Unauthorised', type: 'warn'})
        return throwError(() => error);
      })
    );
  }

  /**
   * Retrieves tokens for the given username and password.
   *
   * @param {string} username - The username to authenticate with.
   * @param {string} password - The password to authenticate with.
   * @returns {Observable<TokenResponse>} - An Observable that emits the TokenResponse object.
   */
  public getTokens(username: string, password: string): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${this.baseUrl}/token/`, {username, password}).pipe(
      tap((tokenResponse: TokenResponse) => {
        this.userService.setUserData(tokenResponse)
      })
    );
  }

  /**
   * Refreshes the access token using the provided refresh token.
   *
   * @param {string} refreshToken - The refresh token.
   * @returns {Observable<TokenResponse>} - The response containing the new access token.
   */
  private refreshToken(refreshToken: string): Observable<TokenResponse> {
    return this.http.post<TokenResponse>(`${this.baseUrl}/token/refresh/`, {refresh: refreshToken}).pipe(
      tap((tokenResponse: TokenResponse) => {
        localStorage.setItem('access_token', tokenResponse.access);
      }),
      catchError((error: any) => {
        this.logout();
        return throwError(() => error);
      })
    );
  }

  public resetPassword(email: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/password_reset/`, {email: email})
  }

  public resetPasswordConfirm(password: string, token: string): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/password_reset/confirm/`, {password: password, token: token})
  }
}