import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HideNotification, NotificationMessage } from "../models/notification.model";


@Injectable(
  {
    providedIn: 'root'
  }
)
export class ToastNotificationService {

  private notificationSubject: Subject<NotificationMessage> = new Subject();

  private hideNotificationSubject: Subject<HideNotification> = new Subject();

  constructor(
  ) {
  }

  showNotification(notification: NotificationMessage) {
    this.notificationSubject.next(notification);
  }

  hideNotification(hideNotification: HideNotification) {
    this.hideNotificationSubject.next(hideNotification);
  }

  getNotification(): Observable<NotificationMessage> {
    return this.notificationSubject.asObservable();
  }

  getHideNotification(): Observable<HideNotification> {
    return this.hideNotificationSubject.asObservable();
  }
}
