import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseApiService } from './base-api.service';
import { UserService } from './user.service';

export interface FileVO {
  fileData: File;
  fileName: string;
}
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private currentUploadData: string = '';
  private uploadSubject$ = new Subject<FileVO>;
  private baseUrl: string = environment.baseUrl;


  constructor(
    private http: HttpClient,
    private baseApiService: BaseApiService,
    private userService: UserService
  ) { }

  //cookie = this.cookieService.getMyCookie('');
  //headers: HttpHeaders = new HttpHeaders({
  //  'X-CSRFToken': this.cookie
  //})

  /* file imported locally - call upload api and notify */
  uploadNotice(file: File, fileName: string) {
    const fileObj = {
      fileData: file,
      fileName: fileName,
    }

    
    /* hold off for now... keep this notification flow for after upload confirm */
    //this.uploadSubject$.next(fileObj);
  }

  getUploadObservable(): Observable<FileVO> {
    return this.uploadSubject$.asObservable();
  }

  uploadGeoJson(geoFile: File): Observable<any> {
    const resource = 'areas/upload/';
    const endpoint = `${this.baseUrl}/${this.userService.mySlug}/${resource}`;
    const formData: FormData = new FormData();
    formData.append('file', geoFile);

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userService.currentAccessToken}`
    });

    //return this.http.post<any>(endpoint, formData, {headers: this.headers});
    return this.http.post<any>(endpoint, formData, {headers: headers});
  }
}
