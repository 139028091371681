<div class="change-password-settings" [formGroup]="passwordFg">
  <div class="change-password-header">
    <h3 class="change-password-title">Change Password</h3>
    <p class="change-password-description">Update your account password.</p>
  </div>
  <div class="change-password-content">
    <div class="form-section">
      <div class="form-group">
          <span class="input-label">Current password</span>
          <mat-form-field appearance="outline">
              <input matInput formControlName="current_password" placeholder="Current password"
                     [type]="hidePassword[0] ? 'password' : 'text'">
              <span [ngClass]="!hidePassword[0] ? 'icon-eye' : 'icon-eye-closed'"
                    matSuffix
                    (click)="togglePasswordVisibility(0)"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hidePassword"></span>
              <mat-error *ngIf="passwordFg.get('current_password')?.hasError('minlength')">
                  This password is too short. It must contain at least 8 characters.
              </mat-error>
          </mat-form-field>
      </div>
      <div class="form-group">
          <span class="input-label">New password</span>
          <mat-form-field appearance="outline">
              <input matInput formControlName="new_password" placeholder="New password"
                     [type]="hidePassword[1] ? 'password' : 'text'">
              <span [ngClass]="!hidePassword[1] ? 'icon-eye' : 'icon-eye-closed'"
                    matSuffix
                    (click)="togglePasswordVisibility(1)"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hidePassword"></span>
              <mat-error *ngIf="passwordFg.get('new_password')?.hasError('minlength')">
                  This password is too short. It must contain at least 8 characters.
              </mat-error>
          </mat-form-field>
      </div>
      <div class="form-group">
          <span class="input-label">Confirm New password</span>
          <mat-form-field appearance="outline">
              <input matInput formControlName="confirm_new_password" placeholder="Confirm New password"
                     [type]="hidePassword[2] ? 'password' : 'text'">
              <span [ngClass]="!hidePassword[2] ? 'icon-eye' : 'icon-eye-closed'"
                    matSuffix
                    (click)="togglePasswordVisibility(2)"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hidePassword"></span>
              <mat-error *ngIf="passwordFg.get('confirm_new_password')?.hasError('minlength')">
                  This password is too short. It must contain at least 8 characters.
              </mat-error>
          </mat-form-field>
      </div>
    </div>
  </div>
  <div class="change-password-actions">
    <button mat-button class="frmg-primary-button" (click)="changePassword()" [disabled]="passwordFg.invalid">Change Password</button>
  </div>
</div>